<template>
    <div class="w-full dF fC f1 px-4 pb-4 scrollbar-style" style="overflow-y: scroll; background-color: #F7F5F9;">
        <BHLoading :show="loading" />
        <div class="overview-page mt-3">
            <div class="f1">
                <div class="grid-display">
					<a-tooltip style="min-width:225px" overlayClassName="change-tooltip-color">
						<template slot="title">
							Total number of leads.
						</template>
						<div @click="$router.push('/leads')" class="display-card" style="padding:12px; cursor:pointer">
							<div class="dF aC jSB">
								<div>
									<div style="color:#66788A; font-size:17px; font-weight:bold">TOTAL LEADS</div>
									<div style="font-size:16px; color:#000">{{totalContacts}}</div>
								</div>
								<img class="ml-3" style="height:60px; height:60px; object-fit:cover" :src="require('@/assets/totalleads.svg')" />
							</div>
						</div>
					</a-tooltip>

					<a-tooltip style="min-width:225px" overlayClassName="change-tooltip-color">
						<template slot="title">
							Total number of leads with a lead score of 4 or higher.
						</template>
						<div @click="$router.push('/leads')" class="display-card" style="padding:12px; cursor:pointer">
							<div class="dF aC jSB">
								<div>
									<div style="color:#66788A; font-size:17px; font-weight:bold">TOTAL ACTIVE LEADS</div>
									<div style="font-size:16px; color:#000">{{leadCount}}</div>
								</div>
								<img class="ml-3" style="height:60px; height:60px; object-fit:cover" :src="require('@/assets/activeleads.svg')" />
							</div>
						</div>
					</a-tooltip>

					<a-tooltip style="min-width:225px" overlayClassName="change-tooltip-color">
						<template slot="title">
							Total number of leads that have been converted to sales qualified and are in the sales pipeline.
						</template>
						<div @click="$router.push('/opportunities/sales')" class="display-card" style="padding:12px; cursor:pointer">
							<div class="dF aC jSB">
								<div>
									<div style="color:#66788A; font-size:17px; font-weight:bold">TOTAL ACTIVE OPPORTUNITIES</div>
									<div style="font-size:16px; color:#000">{{opportunities.filter(x => !x.archived && x.stage != 'won' && x.stage != 'lost' && stages.includes(x.stage)).length}}</div>
								</div>
								<img class="ml-3" style="height:60px; height:60px; object-fit:cover" :src="require('@/assets/opportunity.svg')" />
							</div>
						</div>
					</a-tooltip>

                    <a-tooltip style="min-width:225px" overlayClassName="change-tooltip-color">
						<template slot="title">
							Total number of leads that have been unsubscribed.
						</template>
						<div  class="display-card" style="padding:12px; cursor:pointer">
							<div class="dF aC jSB">
								<div>
									<div style="color:#66788A; font-size:17px; font-weight:bold">TOTAL UNSUBSCRIBED</div>
									<div style="font-size:16px; color:#000">{{unsubscribedContactCount}}</div>
								</div>
								<img class="ml-3" style="height:60px; height:60px; object-fit:cover" :src="require('@/assets/unsubscribedleads.svg')" />
							</div>
						</div>
					</a-tooltip>

                </div>
                <div class="mt-4">
                    <a-card class="task-detail-card">
                        <div slot="title">
                            <div style="padding-bottom:16px; font-size:17px">Task Details</div>
                            <hr style="margin: 0 -24px;" />
                            <div class="dF aC jSB" style="padding-top:16px">
                                <div v-if="tasks['week'].allTasks.length > 0" class="dF aC">
                                    <div>{{tasks['week'].completed.length > 1 ? `${tasks['week'].completed.length} tasks`:tasks['week'].completed.length == 1 ? '1 task': tasks['week'].completed.length == 0 ? '0 task' : ''}}</div>&nbsp;
                                    <div style="color:#00C48C">completed</div>&nbsp;
                                    <div>{{`out of ${tasks['week'].allTasks.length}`}}</div>
                                </div>
                                <div v-else>You don't have any task this week</div>
                                <!-- <div class="dF aC">
                                    <div style="color:#6A707E">Show:</div> &nbsp;
                                    <div>This Week</div>
                                </div> -->
                            </div>
                            <div>
                                <a-progress :show-info="false" class="progress-line mt-2" :percent="parseFloat(((tasks['week'].completed.length / tasks['week'].allTasks.length)*100).toFixed(2))" />
                            </div>
                            <div class="dF aC mt-3" style="font-size:17px;">
                                <div>{{`${today.getDate()} ${months[today.getMonth()]}`,}}</div>,&nbsp;
                                <div style="color:#90A0B7">{{days[today.getDay()]}}</div>
                            </div>
                            <div class="mt-5 dF aC jSB">
                                <div style="display:inline-block; width:50px" v-for="(day, dayI) in shortDays" :key="day+dayI">
                                    <div class="text-center" style="color:#98A5B6;">{{day}}</div>
                                    <div @click="selectDate(taskWeek[dayI].getDate())" :class="taskWeek[dayI].getDate() != today.getDate() && chosenDate == taskWeek[dayI].getDate() ? 'selected-date': taskWeek[dayI].getDate() != today.getDate() && chosenDate != taskWeek[dayI].getDate() ? 'select-date': ''" :style="taskWeek[dayI].getDate() == today.getDate() ? 'background-color:#9693E7; color:#FFF':''" class="mt-2 dF aC jC" style="height:50px; border-radius:50%; cursor:pointer">{{taskWeek[dayI].getDate()}}</div>
                                </div>
                            </div>
                        </div>
                        <template v-if="tasks && selectedTasks.length">
                            <div :style="taskI != 0 ? 'margin-top:20px':''" v-for="(task, taskI) in selectedTasks" :key="task.id + taskI" class="display-card" style="padding:20px">
                                <div class="dF aC jSB">
                                    <div style="color:#000; font-size:15px">{{task.title}}</div>
                                    <div style="color:#90A0B7" class="ml-3" v-if="task.type && task.type != ''">{{task.type && taskTypes[task.type] && taskTypes[task.type].name ? taskTypes[task.type].name:''}}</div>
                                </div>
                                <div class="dF aC">
                                    <div style="color:#4C5862">Due date:</div>
                                    <div class="ml-3" style="color:#000">{{getDueDate(task.dueDate)}}</div>
                                </div>
                                <div class="dF aC mt-4 jSB">
                                    <div class="dF">
                                        <template v-for="(user,userI) in task.assignTo">
                                            <div v-if="userI < 3" :key="userI" class="owner-icon" :style="userI !== 0? 'margin-left: -5px' : ''">
                                                <a-avatar v-if="user.avatar" :src="user.avatar" shape="circle" :size="30"/>
                                                <a-avatar v-else>{{user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()}}</a-avatar>
                                            </div>
                                        </template>
                                    </div>
                                    <div class="px-2 py-1 text-center" style="color:#FFF; border-radius:4px; width:130px" :style="task.priority == 'high' ? 'background-color:var(--danger)':task.priority == 'medium' ? 'background-color:var(--orange)':'background-color:var(--teal)'">{{task.priority == 'high' ? 'HIGH PRIORITY' : task.priority == 'medium' ? 'MED PRIORITY' : 'LOW PRIORITY'}}</div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div>You don't have any active tasks on this day</div>
                        </template>
                        <hr style="margin-left:-24px; margin-right:-24px; margin-bottom:24px">
                        <div class="dF jE" >
                            <div @click="$router.push('/tasks')" style="display:inline-block; cursor:pointer; color:#000">
                                <div class="dF aC">
                                    <div>View all</div>
                                    <i class="fa fa-caret-right ml-2" style="font-size:15px" />
                                </div>
                            </div>
                        </div>
                    </a-card>
                </div>
            </div>
            <div class="right-side-cards" >
                <!-- appointment card -->
                <a-card>
                    <div slot="title" class="dF aC jSB">
                        <div style="font-size:17px;">Appointments</div>

                        <a-popover placement="bottom" overlayClassName="popoverStyle">
                            <template slot="content">
                                <div @click="changeAppointmentDisplay('month')" class="popoverContent">This Month</div>
                                <div @click="changeAppointmentDisplay('week')" class="popoverContent">This Week</div>
                                <div @click="changeAppointmentDisplay('day')" class="popoverContent">Today</div>
                            </template>
                            <div class="dF aC" style="cursor: pointer;">
                                <div style="color:#6A707E">Show:</div> &nbsp;
                                <div>{{showAppointment == 'month' ? 'This Month': showAppointment == 'week' ? 'This Week' : 'Today'}}</div>
                                <i class="fe fe-chevron-down ml-2" />
                            </div>
                        </a-popover>
                    </div>
                    <template v-if="appointments[showAppointment].length != 0">
                        <div v-for="(app, appI) in appointments[showAppointment]" :key="app.id + appI" style="padding-bottom: 24px;" :style="{'padding-top':appI!= 0 ? '24px' : '', 'border-bottom':appI!= appointments[showAppointment].length-1 ? '1px solid #EBEFF2':''}">
                            <div style="color:#000">{{app.title}}</div>
                            <div style="color:#9EA0A5">{{getTime(app.date, app.startTime, app.endTime)}}</div>
                            <div class="dF aC">
                                <div v-for="(mem, memI) in app.assignTo" :key="mem.id + memI">
                                    <div class="dF aC">
                                        <div>{{mem.id == user.id ? 'You' : `${mem.firstName} ${mem.lastName}`}}</div>
                                        <div v-if="memI != app.assignTo.length - 1">, &nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div v-else>
                        {{showAppointment == 'month' ? `You don't have any appointment this month` : showAppointment == 'week' ? `You don't have any appointment this week` : `You don't have any appointment today`}}
                    </div>
                    <hr style="margin-left:-24px; margin-right:-24px; margin-bottom:24px">
                    <div class="dF jE" >
                        <div @click="$router.push('/appointments')" style="display:inline-block; cursor:pointer; color:#000">
                            <div class="dF aC">
                                <div>View all</div>
                                <i class="fa fa-caret-right ml-2" style="font-size:15px" />
                            </div>
                        </div>
                    </div>
                </a-card>


                <!-- task card -->
                <a-card class="mt-4">
                    <div slot="title" class="dF aC jSB">
                        <div style="font-size:17px;">Task Status</div>
                        <a-popover placement="bottom" overlayClassName="popoverStyle">
                            <template slot="content">
                                <div @click="changeTaskDisplay('month')" class="popoverContent">This Month</div>
                                <div @click="changeTaskDisplay('week')" class="popoverContent">This Week</div>
                                <div @click="changeTaskDisplay('day')" class="popoverContent">Today</div>
                            </template>
                            <div class="dF aC" style="cursor: pointer;">
                                <div style="color:#6A707E">Show:</div> &nbsp;
                                <div>{{showTask == 'month' ? 'This Month': showTask == 'week' ? 'This Week' : 'Today'}}</div>
                                <i class="fe fe-chevron-down ml-2" />
                            </div>
                        </a-popover>
                    </div>
                    <div class="dF aC">
                        <a-progress class="progress-circle" :percent="parseFloat(((tasks[showTask].completed.length / tasks[showTask].allTasks.length)*100).toFixed(2))" type="circle">
                            <template #format="percent">
                                <span style="color:#2ED47A; font-size:20px">{{ percent }}%</span>
                            </template>
                        </a-progress>
                        <div v-if="tasks[showTask].allTasks.length > 0" class="ml-5">
                            <div class="dF aC">
                                <div class="mr-3" style="height:10px; width:10px; background-color:#FECF5C"></div>
                                <div>Active</div>
                            </div>
                            <div class="dF aC">
                                <div class="mr-3" style="height:10px; width:10px; background-color:#1EC48C"></div>
                                <div>Completed</div>
                            </div>
                        </div>
                        <div v-else class="ml-5">{{`You have no task ${showTask == 'week' ? 'this week':showTask == 'month' ? 'this month': showTask == 'day' ? 'today' :''}`}}</div>
                    </div>
                    <hr style="margin-left:-24px; margin-right:-24px; margin-bottom:24px">
                    <div class="dF jE" >
                        <div @click="$router.push('/tasks')" style="display:inline-block; cursor:pointer; color:#000">
                            <div class="dF aC">
                                <div>View all</div>
                                <i class="fa fa-caret-right ml-2" style="font-size:15px" />
                            </div>
                        </div>
                    </div>
                </a-card>


                <!-- opportunities card -->
                <a-card class="mt-4">
                    <div slot="title" class="dF aC jSB">
                        <div style="font-size:17px;">Closed Deals</div>
                        <a-popover placement="bottom" overlayClassName="popoverStyle">
                            <template slot="content">
                                <div @click="changeOpportunityDisplay('month')" class="popoverContent">This Month</div>
                                <div @click="changeOpportunityDisplay('lastMonth')" class="popoverContent">Last Month</div>
                                <div @click="changeOpportunityDisplay('lastQuarter')" class="popoverContent">Last Quarter</div>
                                <div @click="changeOpportunityDisplay('week')" class="popoverContent">This Week</div>
                                <div @click="changeOpportunityDisplay('day')" class="popoverContent">Today</div>
                            </template>
                            <div class="dF aC" style="cursor: pointer;">
                                <div style="color:#6A707E">Show:</div> &nbsp;
                                <div>{{showOpportunity == 'month' ? 'This Month': showOpportunity == 'week' ? 'This Week' : showOpportunity == 'lastMonth' ? 'Last Month' : showOpportunity == 'lastQuarter' ? 'Last Quarter':'Today'}}</div>
                                <i class="fe fe-chevron-down ml-2" />
                            </div>
                        </a-popover>
                    </div>
                    <div v-if="showOpportunity == 'month'" style="width:100%">
                        <template v-if="series[0].data && series[0].data.filter(x => x>0).length">
                            <apexchart type="area" height="350" :options="chartOptions" :series="series"></apexchart>
                        </template>
                        <template v-else>
                            <div>You don't have any closed opportunities this month...</div>
                        </template>
                    </div>
                    <div v-else-if="showOpportunity == 'week'">
                        <template v-if="weekSeries[0].data && weekSeries[0].data.filter(x => x>0).length">
                            <apexchart type="bar" height="350" :options="barChartOptions" :series="weekSeries"></apexchart>
                        </template>
                        <template v-else>
                            <div>You don't have any closed opportunities this week...</div>
                        </template>
                    </div>
                    <div v-else-if="showOpportunity == 'day'">
                        <template v-if="daySeries[0].data && daySeries[0].data.filter(x => x>0).length">
                            <apexchart type="bar" height="350" :options="barChartOptions" :series="daySeries"></apexchart>
                        </template>
                        <template v-else>
                            <div>You don't have any closed opportunities today...</div>
                        </template>
                    </div>
                    <div v-else-if="showOpportunity == 'lastMonth'">
                        <template v-if="lastMonthSeries[0].data && lastMonthSeries[0].data.filter(x => x>0).length">
                            <apexchart type="area" height="350" :options="chartOptions" :series="lastMonthSeries"></apexchart>
                        </template>
                        <template v-else>
                            <div>You don't have any closed opportunities last month...</div>
                        </template>
                    </div>
                    <div v-else-if="showOpportunity == 'lastQuarter'">
                        <template v-if="lastQuarterSeries[0].data && lastQuarterSeries[0].data.filter(x => x>0).length">
                            <apexchart type="area" height="350" :options="quarterChartOptions" :series="lastQuarterSeries"></apexchart>
                        </template>
                        <template v-else>
                            <div>You don't have any closed opportunities last quarter...</div>
                        </template>
                    </div>
                    <hr style="margin-left:-24px; margin-right:-24px; margin-bottom:24px">
                    <div class="dF jE" >
                        <div @click="$router.push('/opportunities/sales')" style="display:inline-block; cursor:pointer; color:#000">
                            <div class="dF aC">
                                <div>View pipeline</div>
                                <i class="fa fa-caret-right ml-2" style="font-size:15px" />
                            </div>
                        </div>
                    </div>
                </a-card>
            </div>
        </div>
    </div>
</template>

<script>
import apexchart from 'vue-apexcharts'
import moment from 'moment'
import index from '../../components/layout/SubBar/index.vue'
import BHLoading from 'bh-mod/components/common/Loading'
export default {
  components: { index, apexchart, BHLoading},
    data() {
        return{
            broadCastCount:0,
            leadCount:0,
            selectedTasks:[],
            chosenDate:-1,
            report:{
                contacts:{lastMonth:[], thisMonth:this.totalContacts},
                opportunities:{lastMonth:[], thisMonth:[]},
                emails:{lastMonth:[], thisMonth:[]},
            },
            loading:false,
            taskWeek:[],
            today:new Date(),
            shortDays:['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'],
            days:['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            shortMonths:['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
            showTask:'month',
            showAppointment:'month',
            showOpportunity:'month'
        }
    },
    computed:{
        unsubscribedContactCount(){
            return this.$store.state.contacts.unsubscribedContactCount
        },
        stages() {
            let stages = []
            if (this.$store.state.contacts.allSettings && this.$store.state.contacts.allSettings.app && this.$store.state.contacts.allSettings.app.options && this.$store.state.contacts.allSettings.app.options.stages && this.$store.state.contacts.allSettings.app.options.stages.list) {
                stages = this.$store.state.contacts.allSettings.app.options.stages.list.map(x => x = x.id)
            }
            console.log('sate', stages)
            return stages
        },
        totalContacts() {
            console.log('totalll =>> ',this.$store.state.contacts.totalContacts)
            return this.$store.state.contacts.totalContacts
        },
        activeContacts() {
            let active = Object.values(this.$store.state.contacts.totalContacts).filter(x => x.subscribed && x.subscribed.length)
            return active
        },
        taskTypes() {
            let types = {}
            if (this.$store.state.contacts.allSettings && this.$store.state.contacts.allSettings.app && this.$store.state.contacts.allSettings.app.taskTypes) {
                let storeTypes = this.$store.state.contacts.allSettings.app.taskTypes
                storeTypes.forEach(type => {
                    let value = ''
                    if (type.id) value = type.id
                    else value = type.value
                    types[value] = type
                })
                return types
            } else {
                return types = {
                    'todo':{name:'To-do', value:'todo'},
                    'call':{name:'Call', value:'call'},
                    'followup':{name:'Follow up', value:'followup'}
                }
            }
        },
        series(){
            let today = new Date()
            let month = today.getMonth()
            let year = today.getFullYear()
            let series = [{
                name:'Closed deals',
                data:[]
            }]
            let endMonth = moment().month(month).endOf("month").toDate().getDate()
            for (var i = 1; i <= endMonth; i ++) {
                let array = this.opportunities.filter(x => new Date(x.closeDate).getDate() == i && new Date(x.closeDate).getMonth() == month && new Date(x.closeDate).getFullYear() == year && x.stage == 'won' && !x.archived)
                series[0].data.push(array.length)
            }
            console.log('data', series)
            return series
        },
        lastQuarterSeries() {
            let series = [{
                name:'Closed deals',
                data:[]
            }]
            let today = new Date()
            let quarter = moment(today).quarter()
            let startQuarter = parseInt(moment(moment().quarter(quarter).subtract(1, 'Q').startOf("quarter").toDate()).format('X'))*1000
            let year = new Date(startQuarter).getFullYear()
            let month = new Date(startQuarter).getMonth()

            let endQuarter = 0
            let increment = [0,1,2]
            let quarterMonths = []
            let monthEnds = []
            increment.forEach(i => {
                let endMonth = moment().month(month+i).endOf("month").toDate().getDate()
                endQuarter = endQuarter + endMonth
                quarterMonths[i] = month+i
                monthEnds[i] = endMonth
            })

            for (var i=1; i<=endQuarter; i++) {
                if (i >= 1 && i <= monthEnds[0]) {
                    let array = this.opportunities.filter(x => new Date(x.closeDate).getDate() == i && new Date(x.closeDate).getMonth() == quarterMonths[0] && new Date(x.closeDate).getFullYear() == year && x.stage == 'won' && !x.archived)
                    series[0].data.push(array.length)
                }
                else if (i >= monthEnds[0]+1 && i <= monthEnds[0] + monthEnds[1]) {
                    let array = this.opportunities.filter(x => new Date(x.closeDate).getDate() == i-monthEnds[0] && new Date(x.closeDate).getMonth() == quarterMonths[1] && new Date(x.closeDate).getFullYear() == year && x.stage == 'won' && !x.archived)
                    series[0].data.push(array.length)
                } else {
                    let array = this.opportunities.filter(x => new Date(x.closeDate).getDate() == i-monthEnds[0]-monthEnds[1] && new Date(x.closeDate).getMonth() == quarterMonths[2] && new Date(x.closeDate).getFullYear() == year && x.stage == 'won' && !x.archived)
                    series[0].data.push(array.length)
                }
            }
            return series
        },
        lastMonthSeries() {
            let today = new Date()
            let lastMonth
            if (today.getMonth() > 0) lastMonth = today.getMonth() - 1
            else lastMonth = 11
            let year
            if (today.getMonth() > 0) year = today.getFullYear()
            else year = today.getFullYear() - 1
            let series = [{
                name:'Closed deals',
                data:[]
            }]

            let endMonth = moment().month(lastMonth).endOf("month").toDate().getDate()
            for (var i=1; i<=endMonth; i++) {
                let array = this.opportunities.filter(x => new Date(x.closeDate).getDate() == i && new Date(x.closeDate).getMonth() == lastMonth && new Date(x.closeDate).getFullYear() == year && x.stage == 'won' && !x.archived)
                series[0].data.push(array.length)
            }
            return series
        },
        daySeries() {
            let currentDate = new Date()
            let month = currentDate.getMonth()
            let year = currentDate.getFullYear()
            let series = [{
                name:'Closed deals',
                data:[]
            }]
            let firstday = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay())).getDate();
            for (let i = 0; i < 7; i++) {
                if (firstday+i == new Date().getDate()) {
                    let array = this.opportunities.filter(x => new Date(x.closeDate).getDate() == new Date().getDate() && new Date(x.closeDate).getMonth() == month && new Date(x.closeDate).getFullYear() == year && x.stage == 'won' && !x.archived)
                    series[0].data[i] = array.length
                } else {
                    series[0].data[i] = 0
                }
            }
            console.log('day data', series)
            return series
        },
        weekSeries() {
            let currentDate = new Date()
            let month = currentDate.getMonth()
            let year = currentDate.getFullYear()
            let series = [{
                name:'Closed deals',
                data:[]
            }]
            let firstday = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay())).getDate();
            for (let i = 0; i < 7; i++) {
                let array = this.opportunities.filter(x => new Date(x.closeDate).getDate() == firstday+i && new Date(x.closeDate).getMonth() == month && new Date(x.closeDate).getFullYear() == year && x.stage == 'won' && !x.archived)
                series[0].data.push(array.length)
            }
            console.log('week data', series)
            return series
        },
        quarterChartOptions () {
            let chartOptions= {
                chart: {
                    height: 350,
                    type: 'area',
                    toolbar:{
                        show:true,
                        tools: {
                            download: false,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false | '<img src="/static/icons/reset.png" width="20">',
                        customIcons: []
                        },
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                title:{
                    text:'Closed deals',
                    align:'left'
                },
                xaxis: {
                    type: 'datetime',
                    categories: []
                },
                yaxis:{
                    labels: {
                        formatter: function(val) {
                            return val.toFixed(0);
                        }
                    }
                },
                tooltip: {
                    x: {
                        format: 'dd/MM'
                    },
                },
            }

            let today = new Date()
            let quarter = moment(today).quarter()
            let startQuarter = parseInt(moment(moment().quarter(quarter).subtract(1, 'Q').startOf("quarter").toDate()).format('X'))*1000
            let year = new Date(startQuarter).getFullYear()
            let month = new Date(startQuarter).getMonth()

            let endQuarter = 0
            let endMonths = []
            let quarterMonths = []
            let increment = [0,1,2]
            increment.forEach(i => {
                let endMonth = moment().month(month+i).endOf("month").toDate().getDate()
                endQuarter = endQuarter + endMonth
                endMonths[i] = endMonth
                quarterMonths[i] = new Date(startQuarter).getMonth()+i
            })

            for (var i = 1; i <= endQuarter; i ++) {
                if (i>=1 && i<=endMonths[0]) {
                    let day = `${i} ${this.shortMonths[quarterMonths[0]]}`
                    chartOptions.xaxis.categories.push(day)
                }
                else if (i >= endMonths[0]+1 && i <= endMonths[0]+endMonths[1]) {
                    let day = `${i-endMonths[0]} ${this.shortMonths[quarterMonths[1]]}`
                    chartOptions.xaxis.categories.push(day)
                } else {
                    let day = `${i-endMonths[0]-endMonths[1]} ${this.shortMonths[quarterMonths[2]]}`
                    chartOptions.xaxis.categories.push(day)
                }
            }
            return chartOptions
        },
        barChartOptions() {
            let chartOptions = {
                chart:{
                    height:350,
                    type:'bar',
                    toolbar:{
                        show:true,
                        tools: {
                            download: false,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false | '<img src="/static/icons/reset.png" width="20">',
                        customIcons: []
                        },
                    }
                },
                plotOptions:{
                    bar:{
                        borderRadius:10,
                        dataLabels:{
                            position:'top'
                        }
                    }
                },
                title:{
                    text:'Closed deals',
                    align:'left'
                },
                xaxis: {
                    type: 'datetime',
                    categories: []
                },
                yaxis:{
                    labels: {
                        formatter: function(val) {
                            return val.toFixed(0);
                        }
                    }
                },
                tooltip: {
                    x: {
                        format: 'dd/MM'
                    },
                },
            }
            let currentDate = new Date()
            let firstday = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay())).getDate();
            let month = currentDate.getMonth()
            for (var i = 0; i < 7; i ++) {
                let day = `${firstday+i} ${this.shortMonths[month]}`
                chartOptions.xaxis.categories.push(day)
            }
            return chartOptions
        },
        chartOptions() {
            let chartOptions= {
                chart: {
                    height: 350,
                    type: 'area',
                    toolbar:{
                        show:true,
                        tools: {
                            download: false,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false | '<img src="/static/icons/reset.png" width="20">',
                        customIcons: []
                        },
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                title:{
                    text:'Closed deals',
                    align:'left'
                },
                xaxis: {
                    type: 'datetime',
                    categories: []
                },
                yaxis:{
                    labels: {
                        formatter: function(val) {
                            return val.toFixed(0);
                        }
                    }
                },
                tooltip: {
                    x: {
                        format: 'dd/MM'
                    },
                },
            }
            let today = new Date()
            let month = 0
            if (this.showOpportunity == 'month') month = today.getMonth()
            if (this.showOpportunity == 'lastMonth') {
                if (today.getMonth() > 0) month = today.getMonth()-1
                else month = 11
            }

            let endMonth = moment().month(month).endOf("month").toDate().getDate()

            for (var i = 1; i <= endMonth; i ++) {
                let day = `${i} ${this.shortMonths[month]}`
                chartOptions.xaxis.categories.push(day)
            }
            return chartOptions
        },
        opportunities() {
            return this.$store.state.contacts.opportunities
        },
        user() {
            return this.$store.state.user.user
        },
        storeTasks() {
            return this.$store.state.contacts.tasks
        },
        tasks() {
            let tasks = {
                month:{allTasks:[], completed:[]},
                week:{allTasks:[], completed:[], active:[]},
                day:{allTasks:[], completed:[]}
            }
            let month = new Date().getMonth()
            let week = this.getWeek()
            let day = new Date().getDate()

            let startMonth = parseInt(moment(moment().month(month).startOf("month").toDate()).format('X'))*1000
            let endMonth = parseInt(moment(moment().month(month).endOf("month").toDate()).format('X'))*1000
            let startWeek = parseInt(moment(moment().week(week).startOf("week").toDate()).format('X'))*1000
            let endWeek = parseInt(moment(moment().week(week).endOf("week").toDate()).format('X'))*1000
            let startDay = parseInt(moment(moment().day(day).startOf("day").toDate()).format('X'))*1000
            let endDay = parseInt(moment(moment().day(day).endOf("day").toDate()).format('X'))*1000

            if (this.$store.state.contacts.tasks && this.$store.state.contacts.tasks.length) {
                let storeTasks = this.$store.state.contacts.tasks
                storeTasks.forEach(task => {
                    let assignTo = task.assignTo.map(x => x = x.id)
                    if (assignTo.includes(this.user.id)) {
                        if (task.dueDate >= startMonth && task.dueDate <= endMonth) tasks.month.allTasks.push(task)
                        if (task.dueDate >= startWeek && task.dueDate <= endWeek) tasks.week.allTasks.push(task)
                        if (task.dueDate >= startDay && task.dueDate <= endDay) tasks.day.allTasks.push(task)
                    }
                })
                tasks.month.completed = tasks.month.allTasks.filter(task => task.completed == true)
                tasks.week.completed = tasks.week.allTasks.filter(task => task.completed == true)
                tasks.day.completed = tasks.day.allTasks.filter(task => task.completed == true)
                tasks.week.active = tasks.week.allTasks.filter(task => task.completed == false || !task.completed)
            }
            return tasks
        },
        appointments() {
            let appointments = {
                month:[],
                week:[],
                day:[]
            }
            let month = new Date().getMonth()
            let week = this.getWeek()
            let day = new Date().getDate()

            let startMonth = parseInt(moment(moment().month(month).startOf("month").toDate()).format('X'))*1000
            let endMonth = parseInt(moment(moment().month(month).endOf("month").toDate()).format('X'))*1000
            let startWeek = parseInt(moment(moment().week(week).startOf("week").toDate()).format('X'))*1000
            let endWeek = parseInt(moment(moment().week(week).endOf("week").toDate()).format('X'))*1000
            let startDay = parseInt(moment(moment().day(day).startOf("day").toDate()).format('X'))*1000
            let endDay = parseInt(moment(moment().day(day).endOf("day").toDate()).format('X'))*1000

            if (this.$store.state.contacts.appointments && this.$store.state.contacts.appointments.length) {
                let storeAppointments = this.$store.state.contacts.appointments
                storeAppointments.forEach(app => {
                    let assignTo = app.assignTo.map(x => x = x.id)
                    if (assignTo.includes(this.user.id)) {
                        if (app.date >= startMonth && app.date <= endMonth) appointments.month.push(app)
                        if (app.date >= startWeek && app.date <= endWeek) appointments.week.push(app)
                        if (app.date >= startDay && app.date <= endDay) appointments.day.push(app)
                    }
                })
            }
            return appointments
        }
    },
    methods:{
        selectDate(date) {
            this.chosenDate = date
            this.selectedTasks = this.storeTasks.filter(x => new Date(x.dueDate).getDate() == date && new Date(x.dueDate).getMonth() == new Date().getMonth())
            console.log('taskss', this.selectedTasks)
            console.log('taskweek', date)
        },
        getDueDate(dueDate) {
            let date = new Date(dueDate)
            let month = date.getMonth()
            let day = date.getDate()
            let year = date.getFullYear()
            return `${this.months[month]} ${day}, ${year}`
        },
        getTime(date, start, end) {
            let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
            let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']
            let month = new Date(date).getMonth()
            let year = new Date(date).getFullYear()
            let weekDay = new Date(date).getDay()
            let day = new Date(date).getDate()
            let startHour = new Date(start).getHours()
            let startMin = new Date(start).getMinutes().toString()
            if (startMin.length == 1) startMin = '0'+startMin
            let endHour = new Date(end).getHours()
            let endMin = new Date(end).getMinutes().toString()
            if (endMin.length == 1) endMin = '0'+endMin
            return `${days[weekDay]}, ${months[month]}. ${day}, ${year} | ${startHour}:${startMin} to ${endHour}:${endMin}`
        },
        moment,
        getWeek() {
            let now = new Date();
            let onejan = new Date(now.getFullYear(), 0, 1);
            let week = Math.ceil((((now.getTime() - onejan.getTime()) / 86400000) + onejan.getDay() + 1) / 7);
            return week
        },
        changeOpportunityDisplay(display) {
            this.showOpportunity = display
        },
        changeTaskDisplay(display) {
            this.showTask = display
        },
        changeAppointmentDisplay(display) {
            this.showAppointment = display
        }
    },
    async created() {
        if (this.storeTasks.length) {
            this.selectedTasks = this.storeTasks.filter(x => new Date(x.dueDate).getDate() == new Date().getDate() && x.completed == false && new Date(x.dueDate).getMonth() == new Date().getMonth())
        }
        this.$store.commit('SIDEBAR_SELECTION', {value:'My Work Day'})
        let week = this.getWeek()
        let startWeek = moment().week(week).startOf("week").toDate()
        this.taskWeek.push(startWeek)
        for(let i = 1; i<7; i++) {
            let date = new Date(startWeek.getTime() + 24 * 60 * 60 * 1000*i)
            this.taskWeek.push(date)
        }
        let month = new Date().getMonth()
        let lastStart = moment().month(month-1).startOf("month").toDate()
        let lastEnd = moment().month(month-1).endOf("month").toDate()
        let lastStartNum = parseInt(moment(moment().month(month-1).startOf("month").toDate()).format('X'))*1000
        let lastEndNum = parseInt(moment(moment().month(month-1).endOf("month").toDate()).format('X'))*1000
        this.loading = true
        this.$api.get(`/contacts/:instance/search?_start=0&_limit=10&updatedAt_gte=${lastStart.toISOString()}&updatedAt_lte=${lastEnd.toISOString()}`).then(({data}) => {
            this.report.contacts.lastMonth = data.contacts
            this.report.opportunities.thisMonth = this.opportunities.filter(x => x.stage != 'lost')
            this.report.opportunities.lastMonth = this.opportunities.filter(x => x.closeDate >= lastStartNum && x.closeDate <= lastEndNum)
            this.report.contacts.thisMonth = this.totalContacts
            this.loading = false
        }).catch(err => {
			this.loading = false
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				this.$message.error(this.$err(err))
			}
		})
        console.log('report', this.report)

		try {
			let emailCount = await this.$api.get(`/broadcasts/:instance/count`)
			this.broadCastCount = emailCount.data.count
			let contactCount = await this.$api.get(`/contacts/:instance/count`)
			if (contactCount.data.success) this.leadCount = contactCount.data.count
			console.log('leadcount', contactCount)
		} catch (err) {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				this.$message.error(this.$err(err))
			}
		}
    }
}
</script>
<style lang="scss">
.progress-line .ant-progress-bg{
    background-color:#1EC48C !important;
}
.progress-circle .ant-progress-inner {
    width:170px !important;
    height:170px !important ;
}
.progress-circle .ant-progress-circle-path {
    stroke:#1EC48C !important;
}
.progress-circle .ant-progress-circle-trail{
    stroke:#FECF5C !important;
}
.task-detail-card .ant-card-head-title {
    overflow: initial;
}

.scrollbar-style::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
.scrollbar-style::-webkit-scrollbar-track {
    background: #F2F2F2;
}
.scrollbar-style::-webkit-scrollbar-thumb {
    background: var(--grey-purple);;
    border-radius: 10px;
}
.scrollbar-style::-webkit-scrollbar-thumb:hover {
    background: var(--orange);
}

.change-tooltip-color .ant-tooltip-content .ant-tooltip-inner{
	background:#3F3356;
}
</style>
<style scoped lang="scss">
.selected-date{
    background-color:var(--orange);
    color:#FFF;
    cursor:pointer;
}
.select-date:hover{
    background-color:var(--off-white-light);
    cursor:pointer;
}
.overview-page{
    @media screen and (min-width:1500px){
        display:flex;
    }
}
.right-side-cards{
    @media screen and (min-width:1500px){
        margin-left:1.5rem;
        width:450px
    }
    @media screen and (max-width:1500px){
        margin-top:1.5rem
    }
}
.grid-display{
    display:grid;
    gap:15px;
    @media screen and (max-width:1070px){
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media screen and (min-width:1070px){
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}
.popoverContent{
    cursor: pointer;
    height: 35px;
    width: 100px;
    line-height: 35px;
    padding-left: 10px;
    padding-right: 10px;
}
.popoverContent:hover{
    background-color: var(--off-white-light);
    color:#000;
}
</style>

<style scoped>
    .display-card{
        border-radius: 3px;
        border-color:rgba(63,63,63,.15);
        -webkit-box-shadow: 0 1px 3px rgb(63 63 68 / 15%);
        border:none;
        background-color:#FFF;
    }
</style>
