<template>
    <div>
        <a-tabs class="opportunitiesTab" v-model="activeTab">
            <a-tab-pane key="1" tab="Custom Fields">
                <CustomFieldsTab />
            </a-tab-pane>
            <a-tab-pane key="2" tab="Status">
                <LeadStatusTable @addNewStatus="addNewStatus" @deleteStatus="deleteStatus" />
                <a-modal :width="'600px'" centered :header="null" :footer="null" :visible="showStatusModal" @cancel="cancelStatus">
                    <BHLoading :show="loading" />
                    <div>
                        <h5>Add New Lead Status</h5>
                        <a-form-model ref="newStatus" :model="newStatus">
                            <a-row :gutter="16" class="mt-4">
                                <a-col :span="24">
                                    <a-form-model-item prop="name" label="Name" required :rules="req('Please enter the Name')">
                                        <a-input v-model="newStatus.name" size="large" placeholder="Enter the Name"></a-input>
                                    </a-form-model-item>
                                </a-col>
                            </a-row>
                        </a-form-model>
                        <div class="mt-4 dF" style="justify-content:flex-end">
                            <a-button @click="cancelStatus" size="large">CANCEL</a-button>
                            <a-button @click="submitStatus" type="primary" size="large" class="ml-3">{{newStatus.id? 'UPDATE':'CREATE'}}</a-button>

                        </div>
                    </div>
                </a-modal>
            </a-tab-pane>
            <a-tab-pane key="3" tab="Tags">
                <TagsTable @addNewTag="addNewTag" @deleteTag="deleteTag"/>
                <a-modal :width="'600px'" centered :header="null" :footer="null" :visible="showTagModal" @cancel="cancelTag">
                    <BHLoading :show="loading" />
                    <div>
                        <h5>Add New Tag</h5>
                        <a-row :gutter="16" class="mt-4">
                            <a-form-model ref="newTag" :model="newTag">
                                <a-col :span="24">
                                    <a-form-model-item prop="name" label="Name" required :rules="req('Please enter the Name')">
                                        <a-input v-model="newTag.name" size="large" placeholder="Enter the Name"></a-input>
                                    </a-form-model-item>
                                </a-col>
                            </a-form-model>
                        </a-row>
                        <div class="mt-4 dF" style="justify-content:flex-end">
                            <a-button @click="cancelTag" size="large" class="cancel-button">CANCEL</a-button>
                            <a-button @click="submitTag" type="primary" size="large" class="ml-3">{{newTag.id? 'UPDATE':'CREATE'}}</a-button>

                        </div>
                    </div>
                </a-modal>
            </a-tab-pane>
            <a-tab-pane key="4" tab="Source">
                <a-modal :visible="showSourceModal"></a-modal>
                <a-table :rowKey="(e) => e.id" class="white-table" :columns="columns" :data-source="sources" :pagination="false">
                    <div slot="name" class="dF aC" slot-scope="obj">
                        <div class="mr-3">{{obj.name}}</div>
                    </div>
                    <div slot="action" slot-scope="obj" class="dF jE" style="gap: 20px;">
                        <svg v-if="!defaultSources.includes(obj.id)" @click="$store.commit('OPEN_EDIT_MODAL', {type:'leadSource', item:obj})" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 editIcons"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                        <i v-if="!defaultSources.includes(obj.id)" @click="deleteSource(obj)" class="fe fe-trash-2 ml-3 actionIcons" style="font-size: 16px; "/>
                        <i v-if="defaultSources.includes(obj.id)" class="fe fe-lock" />
                    </div>
                </a-table>

				<div class="dF mt-4" style="gap: 50px; color:var(--primary);">
					<div @click="addSource" class="dF aC cursor-pointer">
						<a-icon style="font-size:25px" type="plus-circle" class="mr-2" />
						<span>Add Source</span>
					</div>

					<div @click="exportModal.visible = true" class="dF aC cursor-pointer">
						<a-icon style="font-size:25px" type="upload" class="mr-2" />
						<span>Export Sources</span>
					</div>
				</div>

				<a-modal title="Export to Existing Project" :visible="exportModal.visible" okText="EXPORT"
					@cancel="exportModal.visible = false" @ok="exportLeadSources" centered>
					<a-row :gutter="16">
						<a-form-model ref="exportModal" :model="exportModal">
							<a-col :span="24">
								<a-form-model-item prop="instance" label="Project" required
									:rules="req('Please select the instance')">
									<a-select show-search :filter-option="filterOption" v-model="exportModal.instance" placeholder="Select a Project" size="large">
										<a-select-option v-for="inst in instances" :value="inst.id" :key="inst.id">{{ inst.name
										}}</a-select-option>
									</a-select>
								</a-form-model-item>
							</a-col>
						</a-form-model>
					</a-row>
				</a-modal>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
import CustomFieldsTab from '@/components/contacts/CustomFieldsTab'
import TagsTable from '@/components/contacts/TagsTable'
import LeadStatusTable from '@/components/contacts/LeadStatusTable'
import BHLoading from 'bh-mod/components/common/Loading'
export default {
    props:{

    },
    components:{
        TagsTable, BHLoading, CustomFieldsTab, LeadStatusTable
    },
    computed:{
        settings() {
            return this.$store.state.contacts.allSettings.app
        },
        contacts(){
            return Object.values(this.$store.state.contacts.allContacts)
        },
        sources() {
            if (this.$store.state.contacts?.allSettings?.app?.options?.leadSource?.length) {
                return this.$store.state.contacts.allSettings.app.options.leadSource
            } else {
                return [
                    {name:'No Source', id:'nosource'},
                    {name:'Email', id:'email'},
                    {name:'Cold Call', id:'coldcall'},
                    {name:'Advertising', id:'advertising'}
                ]
            }
        },
		tags(){
          	return Object.values(this.$store.state.contacts.tags)
        },

		instance() {
			return this.$store.state.instance
		},
		instances() {
			return this.$store.state.instances.filter(x => x.id != this.instance.id && x.name).sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
		},
    },
    data(){
        return{
			activeTab: '1',
            showSourceModal:false,
			exportModal: {
				visible: false,
				instance: null,
			},
            defaultSources:['nosource'],
            columns:[
                {
                    title: 'Name',
                    key: 'name',
                    scopedSlots: { customRender: 'name' },
                },

                {
                    title: 'Action',
                    key: 'action',
                    align: 'right',
                    scopedSlots: { customRender: 'action' },
                },
            ],
            showTagModal:false,
            newTag:{
                name:'',
                description:'',
                type:'contacts'
            },
            newStatus:{
                name:'',
            },
            showStatusModal:false,
            loading:false,
        }
    },
    methods:{
        addSource() {
            this.$store.commit('OPEN_LEAD_STATUS', {type:'leadSource'})
        },
        req:msg=>({required:true,message:msg}),
        addNewStatus() {
            this.$store.commit('OPEN_LEAD_STATUS', {type:'leadStatus'})
        },
        cancelStatus(){
			if(this.$refs.newStatus){
				this.$refs.newStatus.resetFields()
			}
            this.showStatusModal = false
        },
        submitStatus() {
            console.log('submitstatus', this.newStatus)
            this.$refs.newStatus.validate(valid => {
                if (valid) {
                    console.log('valid')
                }
            })
        },
        deleteSource(item) {
            let self = this
            if (this.$p < 40){
                return this.$message.error('You do not have permission to delete this source')
            }
            this.$confirm({
                title: "Delete",
                content: h => <div>Do you want to delete this Lead Source?</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    let settings = JSON.parse(JSON.stringify(self.settings))
                    let index = settings.options.leadSource.findIndex(x => x.id == item.id)
                    if (index != -1) {
                        settings.options.leadSource.splice(index,1)
                    }
                    self.$api.post('/settings/:instance/contacts', settings).then( ({data}) => {
                        self.$store.commit('UPDATE_ALLSETTINGS', data)
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        deleteStatus(item) {
            if (this.$p < 40){
                return this.$message.error('You do not have permission to delete this status')
            }
            let self = this
            this.$confirm({
                title: "Delete",
                content: h => <div>Do you want to delete this Lead Status?</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    self.$api.delete(`/lead-statuses/:instance/${item.id}`).then(( {data}) => {
                        console.log('DELETE COMING BACK', data)
                        self.$store.commit('DELETE_STATUS', data)
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        addNewTag(){
            this.newTag = {}
            this.showTagModal = true
        },
        submitTag() {
            this.$refs.newTag.validate(valid => {
                if (valid) {
                    console.log('newTag', this.newTag)
                    this.loading = true
                    let sendObj = JSON.parse(JSON.stringify(this.newTag))
                    sendObj.type = 'contacts'

                    console.log('sendOjb', sendObj)

					console.log('THIS TAGS', this.tags)
					let newName = sendObj.name.toLowerCase().replace(/\s/g, '')
					let existingTags = JSON.parse(JSON.stringify(this.tags))
					existingTags = existingTags.map(x => {
						return x.name.toLowerCase().replace(/\s/g, '')
					})
					console.log('Existing Tags...', existingTags)
					if (existingTags.includes(newName)){
						this.loading = false
						return this.$message.error('Tag already exists')
					}

                    this.$api.post(`/tags/:instance`,sendObj).then( ({data}) => {
                        console.log('DATAA COMING BACK', data)
                        this.loading = false
                        this.$store.commit('ADD_NEW_TAG', data)
                        this.showTagModal = false
                    }).catch(err => {
						this.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
                } else {
                    console.log('error submit!!');
                return false;
                }
            })
        },
        cancelTag(){
			if(this.$refs.newTag){
				this.$refs.newTag.resetFields()
			}
            this.showTagModal = false
        },
        deleteTag(item) {
            if (this.$p < 40){
                return this.$message.error('You do not have permission to delete this tag')
            }
            console.log('DELETE TAG', item)
            let self = this
            this.$confirm({
                title: "Delete",
                content: h => <div>Do you want to delete this Tag?</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    self.$api.delete(`/tags/:instance/${item.id}`).then(( {data}) => {
                        console.log('DELETE COMING BACK', data)
                        self.$store.commit('DELETE_TAG', data)
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },

		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
		},

		exportLeadSources() {
			let self = this;
			this.$refs.exportModal && this.$refs.exportModal.validate(async (valid) => {
				if (valid) {
					self.$store.commit('LOAD', true)
					try {
						await self.$api.post(`settings/:instance/contacts/clone-settings`, {
							targetInstance: self.exportModal.instance,
							keysToClone: [
								"leadSource",
							]
						})
						self.$store.commit('LOAD', false)
						self.$message.success('Successfully exported all the lead sources into selected project.')
						self.exportModal = {
							visible: false,
							instance: null
						}
					} catch (err) {
						self.$store.commit('LOAD', false)
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err, `An error occurred while exporting lead sources to another project. Please try again.`))
						}
					}
				} else {
					return false;
				}
			})
		},
    },
	mounted() {
		if (this.$route.query["leads"] === 'addTag') {
			this.activeTab = '3';
		}
	}
}
</script>

<style scoped>
    .actionIcons {
        color: #9EA0A5;
        cursor: pointer;
        transition:color .1s ease-in;
    }
    .actionIcons:hover {
        color: var(--danger);
        transition:color .1s ease-in;
    }
    .editIcons {
        color: #9EA0A5;
        cursor: pointer;
        transition:color .1s ease-in;
    }
    .editIcons:hover {
        color: var(--orange);
        transition:color .1s ease-in;
    }

</style>
