<template>
    <a-tabs class="opportunitiesTab" default-active-key="1">
        <a-tab-pane key="1" tab="Sales Pipeline">
            <SalesTable :data="stageSettings" @addNewStage="addNewStage" @changecolor="updateStageColor" @editStage="editStage" @deleteStage="deleteStage"/>

            <a-modal :width="'510px'" centered :header="null" :footer="null" :visible="showStageModal.visible" @cancel="cancelStage">
                <BHLoading :show="loading" />
                <div>
                    <h5 v-if="showStageModal.type === 'add'">Add New Stage</h5>
                    <h5 v-if="showStageModal.type === 'edit'">Edit Stage</h5>
                    <a-row :gutter="16" class="mt-4">
                        <a-form-model ref="newStage" :model="newStage">
                            <a-col :span="24" class="test">
                                <a-form-model-item prop="name" label="Name" required :rules="req('Please enter the Name')">
                                    <a-input v-model="newStage.name" placeholder="Enter the Name" size="large"></a-input>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="24" class="mt-3">
                                <a-form-model-item label="Color Label">
                                    <div class="dF">
                                        <div @click="selectColor('var(--purple)')" style="margin: 0 5px 0 5px; width:40px; height:40px; background-color:var(--purple); text-align:center"><a-icon style="color:white" v-if="newStage.color === 'var(--purple)'" type="check" /></div>
                                        <div @click="selectColor('var(--orange)')" style="margin: 0 5px 0 5px; width:40px; height:40px; background-color:var(--orange); text-align:center"><a-icon style="color:white" v-if="newStage.color === 'var(--orange)'" type="check" /></div>
                                        <div @click="selectColor('var(--yellow)')" style="margin: 0 5px 0 5px; width:40px; height:40px; background-color:var(--yellow); text-align:center"><a-icon style="color:white" v-if="newStage.color === 'var(--yellow)'" type="check" /></div>
                                        <div @click="selectColor('var(--cyan)')" style="margin: 0 5px 0 5px; width:40px; height:40px; background-color:var(--cyan); text-align:center"><a-icon style="color:white" v-if="newStage.color === 'var(--cyan)'" type="check" /></div>
                                        <div @click="selectColor('var(--teal)')" style="margin: 0 5px 0 5px; width:40px; height:40px; background-color:var(--teal); text-align:center"><a-icon style="color:white" v-if="newStage.color === 'var(--teal)'" type="check" /></div>
                                        <div @click="selectColor('var(--danger)')" style="margin: 0 5px 0 5px; width:40px; height:40px; background-color:var(--danger); text-align:center"><a-icon style="color:white" v-if="newStage.color === 'var(--danger)'" type="check" /></div>
                                        <div @click="selectColor('#3F3356')" style="margin: 0 5px 0 5px; width:40px; height:40px; background-color:#3F3356; text-align:center"><a-icon style="color:white" v-if="newStage.color === '#3F3356'" type="check" /></div>
                                        <div @click="selectColor('var(--black)')" style="margin: 0 5px 0 5px; width:40px; height:40px; background-color:var(--black); text-align:center"><a-icon style="color:white" v-if="newStage.color === 'var(--black)'" type="check" /></div>
                                        <div @click="selectColor('var(--gray)')" style="margin: 0 5px 0 5px; width:40px; height:40px; background-color:var(--gray); text-align:center"><a-icon style="color:white" v-if="newStage.color === 'var(--gray)'" type="check" /></div>
                                    </div>
                                </a-form-model-item>
                            </a-col>
                        </a-form-model>
                    </a-row>
                    <div class="dF mt-3" style="justify-content:flex-end">
                        <a-button @click="cancelStage" size="large" class="cancel-button">CANCEL</a-button>
                        <a-button @click="submitStage" v-if="showStageModal.type === 'add'" type="primary" size="large" class="ml-3">CREATE</a-button>
                        <a-button @click="updateStage" v-else-if="showStageModal.type === 'edit'" type="primary" size="large" class="ml-3">UPDATE</a-button>
                    </div>
                </div>
            </a-modal>

        </a-tab-pane>
        <a-tab-pane key="2" tab="Deal Sources">
            <SourcesTable :data="settings" @addNewSource="addNewSource" @deleteSource="deleteSource"/>
            <a-modal :width="'510px'" centered :header="null" :footer="null" :visible="showSourceModal" @cancel="cancelSource">
                <div>
                    <h5>Add New Source</h5>
                    <a-row :gutter="16" class="mt-4">
                        <a-form-model ref="newSource" :model="newSource">
                            <a-col :span="24" class="test">
                                <a-form-model-item prop="name" label="Source Name" required :rules="req('Please enter the Name')">
                                    <a-input v-model="newSource.name" placeholder="Enter the Name"></a-input>
                                </a-form-model-item>
                            </a-col>
                        </a-form-model>
                    </a-row>
                    <div class="dF mt-3" style="justify-content:flex-end">
                        <a-button @click="cancelSource" size="large" class="cancel-button">CANCEL</a-button>
                        <a-button @click="submitSource" type="primary" size="large" class="ml-3">CREATE</a-button>
                    </div>
                </div>
            </a-modal>
        </a-tab-pane>
        <a-tab-pane key="3" tab="Loss Reasons">
            <ReasonsTable :data="settings" @addNewReason="addNewReason"  @deleteReason="deleteReason"/>
            <a-modal :width="'510px'" centered :header="null" :footer="null" :visible="showReasonModal" @cancel="cancelReason">
                <BHLoading :show="loading" />
                <div>
                    <h5>Add New Reason</h5>
                    <a-row :gutter="16" class="mt-4">
                        <a-form-model ref="newReason" :model="newReason">
                            <a-col :span="24" class="test">
                                <a-form-model-item prop="name" label="Reason" required :rules="req('Please enter the Reason')">
                                    <a-input size="large" v-model="newReason.name" placeholder="Enter the Reason"></a-input>
                                </a-form-model-item>
                            </a-col>
                        </a-form-model>
                    </a-row>
                    <div class="dF mt-3" style="justify-content:flex-end">
                        <a-button @click="cancelReason" size="large" class="cancel-button">CANCEL</a-button>
                        <a-button @click="submitReason" type="primary" size="large" class="ml-3">CREATE</a-button>
                    </div>
                </div>
            </a-modal>
        </a-tab-pane>
    </a-tabs>
</template>

<script>
import SalesTable from '@/components/contacts/SalesTable'
import SourcesTable from '@/components/contacts/SourcesTable'
import ReasonsTable from '@/components/contacts/ReasonsTable'
import BHLoading from 'bh-mod/components/common/Loading'

export default {
    components:{
        SalesTable, SourcesTable, ReasonsTable, BHLoading
    },
    props:['sources','reasons'],
    data(){
        return{
            loading:false,
            showStageModal:{
                visible:false,
                stage:{},
                type:'add'
            },
            showSourceModal:false,
            showReasonModal:false,
            newStage:{
                name:'',
                color:'',
                id:''
            },
            newSource:{
                name:'',
                id:0,
            },
            newReason:{
                name:'',
                id:0,
            },
        }
    },
    computed:{
        stageSettings() {
            if (this.$store.state.contacts.allSettings.app && this.$store.state.contacts.allSettings.app.options && this.$store.state.contacts.allSettings.app.options.stages && this.$store.state.contacts.allSettings.app.options.stages.list) {
                let list = this.$store.state.contacts.allSettings.app
                let stages = []
                list.options.stages.list.forEach(stage => {
                    let obj = JSON.parse(JSON.stringify(stage)) || {}
                    if (obj && !obj.probability && (!['paid_reservation', 'completed_reservation', 'transaction', 'won', 'lost'].includes(obj.id))) obj.probability = 20
                    stages.push(obj)
                })
                list.options.stages.list = stages
                return list
            } else return this.$store.state.contacts.allSettings.app
        },
        settings(){
            return this.$store.state.contacts.allSettings.app
        },
        opportunities(){
            return this.$store.state.contacts.opportunities
        },
    },
    watch:{

    },
    methods:{
        req:msg=>({required:true,message:msg}),
        addNewStage(){
            if (this.$p < 40) return this.$message.error('You do not have permission to add a new stage')
            console.log('Add new stage clicked')
            this.newStage.color = ''
            this.newStage.name = ''
            this.newStage.id = Date.now()
            this.showStageModal = {
                visible: true,
                stage:{},
                type:'add'
            }
        },
        selectColor(color){
            this.newStage.color = color
        },
        cancelStage(){
			if(this.$refs.newStage){
				this.$refs.newStage.resetFields()
			}
            this.showStageModal = false
        },
        editStage(item){
            if (this.$p < 40) return this.$message.error('You do not have permission to edit stages')
            console.log('Edit Stage clicked', item)
            this.newStage = JSON.parse(JSON.stringify(item))
            this.showStageModal = {
                visible:true,
                stage:this.newStage,
                type:'edit'
            }
        },
        submitStage() {
            this.$refs.newStage.validate(valid => {
                if (valid) {
                    let sendObj = JSON.parse(JSON.stringify(this.settings))
                    sendObj.options.stages.list.push(this.newStage)

                    const stageIds = ['transaction', 'won', 'lost', 'paid_reservation', 'completed_reservation'];
                    const stageIndices = stageIds.map(id => sendObj.options.stages.list.findIndex(stage => stage.id === id));

                    let filteredList = sendObj.options.stages.list.filter(({ id }) => !stageIds.includes(id));

                    filteredList.forEach((stage, index) => {
                        stage.id = `${index}`;
                    });

                    stageIndices.forEach(index => {
                        if (index !== -1) {
                            filteredList.push(sendObj.options.stages.list[index]);
                        }
                    });

                    sendObj.options.stages.list = filteredList
                    this.loading = true
                    this.$api.post('/settings/:instance/contacts', sendObj).then( ({data}) => {
                        this.$store.commit('UPDATE_ALLSETTINGS', data)
                        this.loading = false
                        this.showStageModal = {
                            visible:false,
                            stage:{},
                            type:'add'
                        }
                    }).catch(err => {
						this.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
                } else {
                    console.log('error submit!!');
                return false;
                }
            })
        },
        updateStageColor(obj, color){
            console.log('OBJ AND COLOR', obj, color)
            this.newStage = JSON.parse(JSON.stringify(obj))
            this.newStage.color = color
            let sendObj = JSON.parse(JSON.stringify(this.settings))
            let index = sendObj.options.stages.list.findIndex(x => x.id == this.newStage.id)
            sendObj.options.stages.list[index] = this.newStage
            console.log('SEND OBJ', sendObj)
            this.$api.post('/settings/:instance/contacts', sendObj).then( ({data}) => {
                this.$store.commit('UPDATE_ALLSETTINGS', data)
                this.showStageModal = {
                    visible:false,
                    stage:{},
                    type:'add'
                }
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        updateStage(){
            this.$refs.newStage.validate(valid => {
                if (valid) {
                    console.log('UPDATED STAGE', this.newStage)
                    let sendObj = JSON.parse(JSON.stringify(this.settings))
                    let index = sendObj.options.stages.list.findIndex(x => x.id == this.newStage.id)
                    sendObj.options.stages.list[index] = this.newStage
                    console.log('SEND OBJ', sendObj)
                    this.$api.post('/settings/:instance/contacts', sendObj).then( ({data}) => {
                        this.$store.commit('UPDATE_ALLSETTINGS', data)
                        this.showStageModal = {
                            visible:false,
                            stage:{},
                            type:'add'
                        }
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
                } else {
                    console.log('error submit!!');
                return false;
                }
            })
        },
        deleteStage(item) {
            if (this.$p < 40) return this.$message.error('You do not have permission to delete stages')
            let self = this
            this.$confirm({
                title: "Delete Stage",
                content: h => <div>Do you want to delete this Stage?</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    console.log('deleteStage', item)
                    let sendObj = JSON.parse(JSON.stringify(self.settings))
                    let index = sendObj.options.stages.list.findIndex(x => x.id == item.id)
                    console.log('INDEXXXX', index)
                    sendObj.options.stages.list.splice(index,1)
                    console.log('SEND OBJ AFTER SPLICE', sendObj)

                    let won = sendObj.options.stages.list.findIndex(x => x.id == 'won')
                    let lost = sendObj.options.stages.list.findIndex(x => x.id == 'lost')
                    let transaction = sendObj.options.stages.list.findIndex(x => x.id == 'transaction')
					let paidReservation = sendObj.options.stages.list.findIndex(x => x.id == 'paid_reservation')
                    let completedReservation = sendObj.options.stages.list.findIndex(x => x.id == 'completed_reservation')

                    let filteredList = sendObj.options.stages.list.filter(({id}) => {
                        return !['paid_reservation', 'completed_reservation', 'transaction', 'won', 'lost'].includes(id)
                    })
                    console.log('filteredList', filteredList)

                    for (var i = 0; i < filteredList.length; i++){
                        filteredList[i].id = `${i}`
                    }

					if (paidReservation != -1) filteredList.push(sendObj.options.stages.list[paidReservation])
                    if (completedReservation != -1) filteredList.push(sendObj.options.stages.list[completedReservation])
                    filteredList.push(sendObj.options.stages.list[transaction])
                    filteredList.push(sendObj.options.stages.list[won])
                    filteredList.push(sendObj.options.stages.list[lost])

                    sendObj.options.stages.list = filteredList

                    if (index === self.settings.options.stages.list.length-3){
                        let sIndex = index.toString()
                        self.opportunities.forEach( x => {
                            if (x.stage == sIndex){
                                let newOpportunity = JSON.parse(JSON.stringify(x))
                                newOpportunity.stage = `${parseInt(newOpportunity.stage)-1}`
                                self.$api.put(`/opportunities/:instance/${newOpportunity.id}`,newOpportunity).then( ({data}) => {
                                    console.log('DATAA COMING BACK FROM PUT', data)
                                })
                            }
                        })
                    }

                    self.$api.post('/settings/:instance/contacts', sendObj).then( ({data}) => {
                        self.$store.commit('UPDATE_ALLSETTINGS', data)
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})

                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        addNewSource(){
            this.$store.commit('OPEN_LEAD_STATUS', {type:'dealSource'})
        },
        submitSource() {
            this.$refs['newSource'].validate(valid => {
                if (valid) {
                    console.log('newSource', this.newSource)
                    let contains = false
                    this.newSource.id = Date.now()
                    this.settings.options.dealSources.forEach( x => {
                        console.log('X')
                        if (x.name.toLowerCase() == this.newSource.name.toLowerCase()){
                            contains = true
                            return this.$message.error('Source already exists!')
                        }
                    })
                    if (!contains){
                        let sendObj = JSON.parse(JSON.stringify(this.settings))
                        sendObj.options.dealSources.push(this.newSource)
                        console.log('SEND OBJ', sendObj)
                        this.$api.post('/settings/:instance/contacts', sendObj).then( ({data}) => {
                            this.$store.commit('UPDATE_ALLSETTINGS', data)
                            this.cancelSource()
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    }
                } else {
                    console.log('error submit!!');
                return false;
                }
            })
        },
        cancelSource(){
			if(this.$refs['newSource']){
				this.$refs['newSource'].resetFields()
			}
            this.newSource = {
                name:'',
                id:0
            },
            this.showSourceModal = false
        },
        deleteSource(item) {
            let self = this
            if (this.$p < 40){
                return this.$message.error('You do not have permission to delete Sources')
            }
            this.$confirm({
            title: "Delete Source",
            content: h => <div>Do you want to delete this Source?</div>,
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            centered: true,
            onOk() {
                console.log('deleteSource', item)
                let sendObj = JSON.parse(JSON.stringify(self.settings))
                let index = sendObj.options.dealSources ? sendObj.options.dealSources.findIndex(x => x.id == item.id) : -1;
                sendObj.options.dealSources.splice(index,1)
                self.$api.post('/settings/:instance/contacts', sendObj).then( ({data}) => {
                    self.$store.commit('UPDATE_ALLSETTINGS', data)
                })
            },
            onCancel() {
                console.log('Cancel')
            }
            })
        },
        addNewReason(){
            this.newReason = {}
            this.showReasonModal = true
        },
        submitReason() {
            this.$refs['newReason'].validate(valid => {
                if (valid) {
                    console.log('newReason', this.newReason)
                    this.newReason.id = Date.now().toString()
                    let sendObj = JSON.parse(JSON.stringify(this.settings))
					if (!sendObj.options.lostReasons){
						sendObj.options.lostReasons = []
					}
                    sendObj.options.lostReasons.push(this.newReason)
                    console.log('SEND OBJ', sendObj)
                    this.loading = true
                    this.$api.post('/settings/:instance/contacts', sendObj).then( ({data}) => {
                        this.$store.commit('UPDATE_ALLSETTINGS', data)
                        this.loading = false
                        this.cancelReason()
                    }).catch(err => {
						this.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
                } else {
                    console.log('error submit!!');
                return false;
                }
            })
        },
        cancelReason(){
			if(this.$refs['newReason']){
				this.$refs['newReason'].resetFields()
			}
            this.newReason = {
                name:'',
                id:0,
            },
            this.showReasonModal = false
        },
        deleteReason(item) {

            let self = this
            this.$confirm({
                title: "Delete Reason",
                content: h => <div>Do you want to delete this Reason?</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    console.log('deleteReason', item)
                    let sendObj = JSON.parse(JSON.stringify(self.settings))
                    let index = sendObj.options.lostReasons.findIndex( x => x.id == item.id)
                    sendObj.options.lostReasons.splice(index,1)
                    self.$api.post('/settings/:instance/contacts', sendObj).then( ({data}) => {
                        self.$store.commit('UPDATE_ALLSETTINGS', data)
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
    }
}
</script>

<style lang="scss">
.opportunitiesTab .ant-tabs-nav {
    font-size:16px;
    .ant-tabs-tab {
        padding-left:25px;
        padding-right:25px;
    }
}

.opportunitiesTab .ant-tabs-bar {
    margin:0 0 25px 0
}
</style>
