<template>
	<div>
		<a-table :rowKey="(e) => e.id" :pagination="false" class="white-table" :columns="columns"
			:data-source="sentimentOptions">
			<div slot="name" slot-scope="obj" class="dF aC">
				<div class="mr-3">{{ obj.name }}</div>
			</div>
			<div slot="action" slot-scope="obj" class="dF jE" style="gap: 20px;">
				<svg @click="$store.commit('OPEN_EDIT_MODAL', { type: 'sentimentOption', item: obj })"
					v-if="!defaultSentimentOptions.includes(obj.id)" xmlns="http://www.w3.org/2000/svg" width="16"
					height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
					stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 editIcon mr-2">
					<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
				</svg>
				<i v-if="!defaultSentimentOptions.includes(obj.id)" @click="deleteSentimentOption(obj)"
					class="fe fe-trash-2 deleteIcon" style="font-size: 16px;" />
				<i v-else class="fe fe-lock" />
			</div>
		</a-table>

		<div class="dF mt-4" style="gap: 50px; color:var(--primary);">
			<div @click="addNewSentimentOption" class="dF aC cursor-pointer">
				<a-icon style="font-size:25px" type="plus-circle" class="mr-2" />
				<span>Add Sentiments</span>
			</div>

			<div @click="exportModal.visible = true" class="dF aC cursor-pointer">
				<a-icon style="font-size:25px" type="upload" class="mr-2" />
				<span>Export Sentiments</span>
			</div>
		</div>

		<a-modal title="Export to Existing Project" :visible="exportModal.visible" okText="EXPORT"
			@cancel="exportModal.visible = false" @ok="exportStages" centered>
			<a-row :gutter="16">
				<a-form-model ref="exportModal" :model="exportModal">
					<a-col :span="24">
						<a-form-model-item prop="instance" label="Project" required
							:rules="req('Please select the instance')">
							<a-select show-search :filter-option="filterOption" v-model="exportModal.instance"
								placeholder="Select a Project" size="large">
								<a-select-option v-for="inst in instances" :value="inst.id" :key="inst.id">{{ inst.name
									}}</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
				</a-form-model>
			</a-row>
		</a-modal>
	</div>
</template>

<script>
export default {
	data() {
		return {
			exportModal: {
				visible: false,
				instance: null,
			},
			columns: [
				{
					title: 'Name',
					key: 'name',
					scopedSlots: { customRender: 'name' },
				},
				{
					title: 'Action',
					key: 'action',
					align: 'right',
					scopedSlots: { customRender: 'action' },
				},
			],
			defaultSentimentOptions: ['Next Step Booked', 'Bad Timing', 'Not Interested', 'No Fit']
		}
	},

	computed: {
		sentimentOptions() {
			if (this.appOptions.sentimentOptions) {
				return this.appOptions.sentimentOptions
			} else {
				return [
					{ name: 'Next Step Booked', id: 'Next Step Booked' },
					{ name: 'Bad Timing', id: 'Bad Timing' },
					{ name: 'Not Interested', id: 'Not Interested' },
					{ name: 'No Fit', id: 'No Fit' }
				]
			}
		},
		settings() {
			return this.$store.state.contacts.allSettings?.app;
		},
		appOptions() {
			return this.$store.state.contacts.allSettings?.app?.options || {};
		},
		instance() {
			return this.$store.state.instance
		},
		instances() {
			return this.$store.state.instances.filter(x => x.id != this.instance.id && x.name).sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
		},
	},

	methods: {
		req: msg => ({ required: true, message: msg }),

		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
		},

		addNewSentimentOption() {
			this.$store.commit('OPEN_LEAD_STATUS', { type: 'sentimentOption' })
		},
		deleteSentimentOption(item) {
			if (this.$p < 40) {
				this.$message.error('You do not have permission to delete sentiment options.')
				return;
			}
			let self = this
			this.$confirm({
				title: "Delete Sentiment",
				content: h => <div>Do you want to delete this Sentiment?</div>,
				okText: 'Delete',
				okType: 'danger',
				cancelText: 'Cancel',
				centered: true,
				onOk() {
					let sendObj = JSON.parse(JSON.stringify(self.settings))
					let index = sendObj.options.sentimentOptions.findIndex(x => x.id == item.id)
					sendObj.options.sentimentOptions.splice(index, 1)
					self.$api.post('/settings/:instance/contacts', sendObj).then(({ data }) => {
						self.$store.commit('UPDATE_ALLSETTINGS', data)
					}).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
				},
				onCancel() {
					console.log('Cancel')
				}
			})
		},

		exportStages() {
			let self = this;
			this.$refs.exportModal && this.$refs.exportModal.validate(async (valid) => {
				if (valid) {
					self.$store.commit('LOAD', true)
					try {
						await self.$api.post(`settings/:instance/contacts/clone-settings`, {
							targetInstance: self.exportModal.instance,
							keysToClone: ["sentimentOptions"]
						})
						self.$store.commit('LOAD', false)
						self.$message.success('Successfully exported all the sentiment options into selected project.')
						self.exportModal = {
							visible: false,
							instance: null
						}
					} catch (err) {
						self.$store.commit('LOAD', false)
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err, `An error occurred while exporting sentiment options to another project. Please try again.`))
						}
					}
				} else {
					return false;
				}
			})
		},
	}
}
</script>

<style lang="scss" scoped>
.editIcon {
	color: #9EA0A5;
	cursor: pointer;
	transition: color .1s ease-in;
}

.editIcon:hover {
	color: var(--orange);
	transition: color .1s ease-in;
}

.deleteIcon {
	color: #9EA0A5;
	cursor: pointer;
	transition: color .1s ease-in;
}

.deleteIcon:hover {
	color: var(--danger);
	transition: color .1s ease-in;
}
</style>
