<template>
	<a-modal v-if="selectedTask" :visible="taskModal" ok-text="UPDATE" cancel-text="CANCEL" @cancel="closeTaskModal"
		@ok="updateTask" :confirmLoading="loading" width="1000px" :dialog-style="{ top: '25px', 'z-index': 1111 }">
		<BHLoading :show="loading" />
		<a-form-model ref="editTask" :model="selectedTask">
			<a-row :gutter="16">
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="Task Title" prop="title" :rules="$req('Please enter the task title')">
						<a-input v-model="selectedTask.title" placeholder="Task Title" size="large">
						</a-input>
					</a-form-model-item>
				</a-col>
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="Task Type" prop="type" :rules="$req('Please select task Type')">
						<a-select v-model="selectedTask.type" size="large" show-search :filter-option="$filterOption">
							<div slot="dropdownRender" slot-scope="menu">
								<v-nodes :vnodes="menu" />
								<a-divider style="margin: 4px 0;" />
								<div style="padding: 8px; cursor: pointer; color:var(--orange)"
									@mousedown="e => e.preventDefault()" @click="addNewType">
									<i style="font-size:15px;" class="fe fe-edit-2 mr-2"></i> Add New Task Type
								</div>
							</div>
							<a-select-option v-for="task in taskTypes" :key="task.id" :value="task.id">
								{{ task.name }}
							</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="16">
				<a-col :span="12">
					<a-form-model-item label="Task Description" prop="description">
						<a-textarea v-model="selectedTask.description" placeholder="Task Description" size="large"
							:auto-size="{ minRows: 3, maxRows: 5 }" />
					</a-form-model-item>
				</a-col>
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="Due Date" prop="dueDate">
						<a-date-picker size="large" v-model="selectedTask.dueDate" style="width: 100%;"></a-date-picker>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="16">
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="Priority Level" prop="priority"
						:rules="$req('Please select priority level')">
						<div class="dF aC jSB">
							<div @click="selectedTask.priority = 'high'" class="priority"
								:class="selectedTask.priority == 'high' ? 'select-high' : 'high-priority'">
								HIGH PRIORITY</div>
							<div @click="selectedTask.priority = 'medium'" class="priority"
								:class="selectedTask.priority == 'medium' ? 'select-med' : 'med-priority'">
								MED PRIORITY</div>
							<div @click="selectedTask.priority = 'low'" class="priority"
								:class="selectedTask.priority == 'low' ? 'select-low' : 'low-priority'">
								LOW PRIORITY</div>
						</div>
					</a-form-model-item>
				</a-col>
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="Assign To" prop="assignTo"
						:rules="$req('Please select any user to assign a task')">
						<a-select v-model="selectedTask.assignTo" mode="multiple" placeholder="Select a users"
							size="large" :filter-option="$filterOption" show-search>
							<a-select-option v-for="user in users" :key="user.id" :value="user.id">
								{{ user.firstName + ' ' + user.lastName }} ({{ user.email }})
							</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="16">
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="What is this task related to?" prop="relatedType"
						:rules="$req('Please select one option below')">
						<a-select v-model="selectedTask.relatedType" size="large"
							@change="selectedTask.contact = null; selectedTask.opportunity = null">
							<a-select-option value="contact">Contact</a-select-option>
							<a-select-option value="opportunity">Opportunity</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<template v-if="selectedTask.relatedType === 'contact'">
						<a-form-model-item label="Primary Contact" prop="contact"
							:rules="selectedTask.relatedType === 'contact' ? $req('Please enter the contact name') : {}">
							<a-select v-model="selectedTask.contact" mode="single" show-search
								placeholder="Type to search..." size="large" @search="searchContacts"
								:filter-option="null" allowClear>
								<a-spin v-if="searching" slot="notFoundContent" class="dF aC jC" />
								<a-select-option v-for="contact in contactList" :key="contact.value"
									:value="contact.value">
									{{ contact.label }}
								</a-select-option>
							</a-select>
						</a-form-model-item>
					</template>
					<template v-else>
						<a-form-model-item label="Related To" prop="opportunity"
							:rules="selectedTask.relatedType === 'opportunity' ? $req('Please enter the opportunity name') : {}">
							<a-select v-model="selectedTask.opportunity" show-search placeholder="Type to search..."
								size="large" :filter-option="$filterOption" allowClear>
								<a-select-option v-for="opp in opportunities" :key="opp.id" :value="opp.id">
									{{ opp.name }}
								</a-select-option>
							</a-select>
						</a-form-model-item>
					</template>
				</a-col>
			</a-row>
			<a-row :gutter="16">
				<a-divider orientation="left">
					<div class="text-md">
						<a-icon class="mr-2" type="check-circle" /> Task Completion
					</div>
				</a-divider>
				<a-col :span="$mq == 'sm' ? 24 : 12">
					<a-form-model-item label="Is Task Completed" prop="completed">
						<a-switch checked-children="Yes" un-checked-children="No" v-model="selectedTask.completed"
							@change="onTaskCompletionChange" />
					</a-form-model-item>
				</a-col>
				<a-col :span="$mq == 'sm' ? 24 : 12" v-if="selectedTask.completed">
					<a-form-model-item label="Task Outcome Sentiment" prop="sentiment">
						<a-select v-model="selectedTask.sentiment" size="large" show-search
							:filter-option="$filterOption" style="width: 100%;" allow-clear>
							<a-select-option v-for="s in sentimentOptions" :key="s.value" :value="s.value">
								{{ s.label }}
							</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
			</a-row>
			<template v-if="selectedTask.marketing_workflow && selectedTask.completed && taskWorkflow">
				<a-divider orientation="left">
					<div class="text-md">
						<a-icon class="mr-2" type="api" /> Marketing Workflow:
						<a :href="`https://marketing-automation.bildhive.${$tld}/workflow/view/${taskWorkflow.id}`"
							target="_blank" class="text-xs text-gray-500 text-primary">
							{{ taskWorkflow.name }}
						</a>
					</div>
				</a-divider>
				<a-row :gutter="16">
					<a-col :span="$mq == 'sm' ? 24 : 12">
						<a-form-model-item label="Choose next possible step for the contact" prop="next_marketing_workflow_step">
							<a-select v-model="selectedTask.next_marketing_workflow_step" size="large" show-search
								:filter-option="$filterOption" style="width: 100%;" allow-clear :disabled="!!editTask.next_marketing_workflow_step">
								<a-select-option key='contact_exit' value='contact_exit'>
									<span style="color: red;">Remove Contact From Workflow</span>
								</a-select-option>
								<a-select-option v-for="step in possibleNextSteps" :key="step.stepId"
									:value="step.stepId">
									{{ step.name }}
								</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
				</a-row>
			</template>
			<a-row :gutter="16">
				<a-divider orientation="left">
					<div class="text-md">
						<a-icon class="mr-2" type="paper-clip" /> {{ selectedTask.files?.length || 0 }}
						{{ selectedTask.files?.length > 1 ? 'attachments' : 'attachment' }}
					</div>
				</a-divider>
				<div>
					<a-button @click="uploadFiles">
						<a-icon type="upload" /> Upload
					</a-button>
				</div>
				<div v-if="selectedTask.files?.length" class="mt-3"
					style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr)); gap:2em;">
					<div v-for="(file, fileI) in selectedTask.files" :key="fileI" class="dF aC jSB px-2 py-1"
						style="background-color: #F7F5F9; border: 1px solid #E6EAF0; border-radius: 5px;">
						<div style="overflow: hidden; white-space: nowrap;">
							<div>{{ file.name }}</div>
							<div style="color: #A3A7AC;">{{ formatFileSize(file.size) }}</div>
						</div>
						<div class="dF aC" style="gap: 10px;">
							<a-button type="primary" ghost @click="downloadFile(file)">
								<a-icon type="download" />
							</a-button>
							<a-button type="danger" ghost @click="selectedTask.files.splice(fileI, 1)">
								<a-icon type="delete" />
							</a-button>
						</div>
					</div>
				</div>
			</a-row>

			<a-row :gutter="16">
				<a-divider orientation="left">
					<div class="text-md">
						<a-icon class="mr-2" type="message" /> {{ comments?.length || 0 }}
						{{ comments?.length > 1 ? 'comments' : 'comment' }}
					</div>
				</a-divider>
				<a-col :span="24">
					<div v-for="comment in comments" :key="comment.content">
						<div class="dF aC" style="gap: 20px;">
							<div>
								<a-avatar v-if="comment?.user?.avatar" class="task-contact-avatar" :size="50"
									:src="comment.user.avatar" />
								<a-avatar v-else class="task-contact-avatar" :size="50">
									{{ comment.user.firstName && comment.user.lastName &&
										comment.user.firstName.charAt(0) + comment.user.lastName.charAt(0) }}
								</a-avatar>
							</div>
							<div style="width: 100%;">
								<div class="dF w-full">
									<strong>{{ comment.user == null ? '' : comment.user.firstName + ' ' +
										comment.user.lastName }}</strong>
									<p class="ml-5" style="color:#818181">
										Created {{ comment.createdAt && comment.createdAt.substring(0, 10) }}
									</p>

									<div v-if="comment.edited" class="ml-5" style="color:#818181">
										Edited {{ comment.updatedAt.substring(0, 10) }}
									</div>
								</div>
								<div v-if="user.id == comment.user.id" class="dF aC" style="gap:20px">
									<MenuBubble @save="(e) => updateComment(e, comment)" :text="comment.content"
										style="width: 100%;" />
									<a-button type="danger" ghost @click="deleteComment(comment)">
										<a-icon type="delete" />
									</a-button>
								</div>
								<div v-else v-html="comment.content" />
							</div>
						</div>
						<a-divider></a-divider>
					</div>
					<div v-if="!loading" class="dF aC" style="gap: 20px;">
						<div>
							<a-avatar v-if="user?.avatar" class="task-contact-avatar" :size="50" :src="user.avatar" />
							<a-avatar v-else class="task-contact-avatar" :size="50">
								{{ user.firstName && user.lastName && user.firstName.charAt(0) +
									user.lastName.charAt(0) }}
							</a-avatar>
						</div>

						<TaskComment :message="newComment" @save="saveComment" class="task__comment"
							style="width: 100%;" />
					</div>
				</a-col>
			</a-row>
		</a-form-model>
	</a-modal>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'
import { formatFileSize, debounceFunc } from 'bh-mod/index'
import MenuBubble from '@/components/contacts/MenuBubble'
import TaskComment from '@/components/contacts/TaskComment'
import moment from 'moment'
const FileSaver = require('file-saver')

export default {
	name: 'EditTaskModal',
	components: {
		BHLoading, MenuBubble, TaskComment,
		VNodes: {
			functional: true,
			render: (h, ctx) => ctx.props.vnodes,
		},
	},
	data() {
		return {
			loading: false,
			searching: false,
			selectedTask: null,
			newComment: '',
		}
	},
	watch: {
		taskModal: {
			handler: function (value) {
				if (value) {
					const task = this.editTask;
					this.selectedTask = {
						...task,
						assignTo: task.assignTo.map(user => user.id),
						dueDate: task.dueDate ? moment(task.dueDate) : null,
						contact: task.contact ? task.contact.id : null,
						opportunity: task.opportunity ? task.opportunity.id : null,
					};

					if (task.contact?.id) {
						this.$store.commit('SET_SEARCHED_CONTACT_LIST', [{
							label: task.contact.name ? `${task.contact.name} (${task.contact.email})` : task.contact.email,
							value: task.contact.id
						}])
					}
				}
			},
			deep: true
		}
	},
	computed: {
		taskTypes() {
			const taskTypes = this.$store.state.contacts?.allSettings?.app?.options?.taskType;
			const list = taskTypes?.length ? taskTypes : [
				{ name: 'To-do', id: 'todo' },
				{ name: 'Call', id: 'call' },
				{ name: 'Follow up', id: 'followup' }
			];

			return list;
		},
		taskModal() {
			return this.$store.state.contacts.editTask.visible
		},
		editTask() {
			return JSON.parse(JSON.stringify(this.$store.state.contacts.editTask.task))
		},
		contacts() {
			return Object.values(this.$store.state.contacts.allContacts)
		},

		externalContacts() {
			return this.$store.state.contacts.externalContacts
		},

		opportunities() {
			return this.$store.state.contacts.opportunities
		},

		users() {
			return this.$store.state.contacts.users
		},

		user() {
			return this.$store.state.user.user || {}
		},

		marketingWorkflowList() {
			return this.$store.state.contacts.marketingWorkflowList || {};
		},

		taskWorkflow() {
			if (this.selectedTask?.marketing_workflow) {
				return this.marketingWorkflowList[this.selectedTask.marketing_workflow] || null;
			}
			return null
		},

		possibleNextSteps() {
			if (this.taskWorkflow && this.selectedTask.marketing_workflow_step) {
				const workflow = this.taskWorkflow;
				let stepsObj = {};
				workflow.steps.forEach(step => stepsObj[step.stepId] = step)
				let taskStep = stepsObj[this.selectedTask.marketing_workflow_step]
				if (taskStep && taskStep.actionData && taskStep.actionData.possibleNextSteps) {
					let possibleSteps = [];
					taskStep.actionData.possibleNextSteps.forEach(stepId => {
						if (stepsObj[stepId]) {
							possibleSteps.push(stepsObj[stepId])
						}
					})
					return possibleSteps
				}
			}
			return null
		},

		comments() {
			return (this.selectedTask?.comments || []).map(comment => {
				if (typeof comment.user === 'string') {
					comment.user = this.users.find(user => user.id === comment.user);
				}
				comment.edited = moment(comment.createdAt).isBefore(comment.updatedAt);
				return comment;
			});
		},

		appOptions() {
			return this.$store.state.contacts?.allSettings?.userApp?.options || {};
		},

		sentimentOptions() {
			let list = [
				{ name: 'Next Step Booked', id: 'Next Step Booked' },
				{ name: 'Bad Timing', id: 'Bad Timing' },
				{ name: 'Not Interested', id: 'Not Interested' },
				{ name: 'No Fit', id: 'No Fit' }
			];
			if (this.appOptions.sentimentOptions) {
				list = this.appOptions.sentimentOptions
			}

			return list.map((item) => {
				return {
					label: item.name,
					value: item.id
				}
			})
		},

		contactList() {
			return this.$store.state.contacts.searchedContactList
		}
	},
	methods: {
		moment, formatFileSize,

		addNewType() {
			this.$store.commit('OPEN_LEAD_STATUS', { type: 'taskType' })
		},

		closeTaskModal() {
			this.$store.commit('CLOSE_EDIT_TASK')
		},

		async updateTask() {
			this.$refs.editTask.validate(async (valid) => {
				if (valid) {
					this.loading = true;
					try {
						const payload = {
							...this.selectedTask,
							dueDate: +moment(this.selectedTask.dueDate).format('x'),
						};

						const { data } = await this.$api.put(`/tasks/:instance/${this.selectedTask.id}`, payload);
						this.$store.commit('DELETE_TASK', data);
						this.$store.commit('ADD_NEW_TASK', data);
						this.$message.success('Task updated successfully');
						this.closeTaskModal();
					} catch (err) {
						this.$toastError(err, 'Error while updating task details. Please try again');
					} finally {
						this.loading = false;
					}
				}
			})
		},

		async saveComment(comment) {
			this.loading = true
			try {
				const { data } = await this.$api.put(`/tasks/:instance/${this.selectedTask.id}/comment`, { content: comment })
				this.$set(this.selectedTask, 'comments', [...data.comments]);
				this.$store.commit('DELETE_TASK', data);
				this.$store.commit('ADD_NEW_TASK', data);
				this.newComment = '';
			} catch (err) {
				this.$toastError(err, 'Error while updating task comments. Please try again');
			} finally {
				this.loading = false;
			}
		},

		async updateComment(content, comment) {
			this.loading = true
			try {
				let sendComment = JSON.parse(JSON.stringify(comment))
				sendComment.content = content

				const { data } = await this.$api.put(`/comments/:instance/${comment.id}`, sendComment)
				const updatedComment = { ...data, user: data.user.id }
				this.selectedTask.comments = this.selectedTask.comments.map(x => x.id === comment.id ? updatedComment : x);
			} catch (err) {
				this.$toastError(err, 'Error while updating task comments. Please try again');
			} finally {
				this.loading = false;
			}
		},

		async deleteComment(comment) {
			this.loading = true
			try {
				await this.$api.delete(`/comments/:instance/${comment.id}`)
				this.selectedTask.comments = this.selectedTask.comments.filter(x => x.id !== comment.id);
			} catch (err) {
				this.$toastError(err, 'Error while updating task comments. Please try again');
			} finally {
				this.loading = false;
			}
		},

		async fetchContacts(input) {
			try {
				this.searching = true;
				await this.$store.dispatch('SEARCH_CONTACTS', input)
			} catch (err) {
				this.$toastError(err, 'Error while fetching contact list. Please try again');
			} finally {
				this.searching = false;
			}
		},

		searchContacts: debounceFunc(function (input) {
			if (input.length < 3) return;

			this.fetchContacts(input)
		}, 300),

		onTaskCompletionChange(checked) {
			if (!checked) {
				this.selectedTask.sentiment = null;
			}
		},

		uploadFiles() {
			this.$store.commit('SET_MULTIPLE_IMAGES', true);
			this.$store.commit('MEDIA_SELECT', { callback: this.onFileUpload, type: 'all' });
		},

		onFileUpload(item) {
			this.$set(this.selectedTask, 'files', [...(this.selectedTask.files || []), ...item]);
		},

		async downloadFile(file) {
			this.loading = true;
			FileSaver.saveAs(file.url, file.name);
			this.loading = false;
		},
	},
}
</script>

<style>
.task .ant-card-body {
	padding: 10px;
}
</style>

<style scoped>
.task-contact-avatar {
	color: white;
	background-color: #1070CA;
	margin-right: 0.5rem;
}

.priorityButton {
	border-radius: 4px;
	text-align: center;
	color: white;
	width: 167px;
	cursor: pointer;
}

.popoverContent {
	height: 35px;
	line-height: 35px;
	padding-left: 10px;
	padding-right: 10px;
	display: flex;
	align-items: center;
}

.popoverContent:hover {
	background-color: var(--off-white-light);
	cursor: pointer;
	color: #000;
}

.badge-offer {
	padding: 5px 10px;
	border-radius: 5px;
}

.bg-teal {
	background-color: var(--teal);
}


.select-high,
.select-med,
.select-low,
.high-priority,
.med-priority,
.low-priority {
	cursor: pointer;
	line-height: 1.6;
	padding: 0 15px;
	border-radius: 4px;
	margin-right: 10px;
	border: 1px solid #9EA0A5;
}

.select-high,
.high-priority:hover {
	background-color: var(--danger);
	color: white;
	border-color: var(--danger);
}

.select-med,
.med-priority:hover {
	background-color: var(--orange);
	color: white;
	border-color: var(--orange);
}

.select-low,
.low-priority:hover {
	background-color: var(--teal);
	color: white;
	border-color: var(--teal);
}

.high-priority {
	color: var(--danger);
	border-color: var(--danger);
}

.med-priority {
	color: var(--orange);
	border-color: var(--orange);
}

.low-priority {
	color: var(--teal);
	border-color: var(--teal);
}
</style>
