<template>
	<div style="background-color:white; padding:30px;">
		<BHLoading :show="loading" />
		<h5 class="mb-5">Signature Preview</h5>

		<div v-if="account.template == '1'" style="max-width:500px" :style="`font-family: ${account.fontFamily}`">
			<div class="dF aS jS" style="gap: 2em;">
				<div style="width: 40%;">
					<div v-if="account.logo && account.logo != ''" class="w-full dF jC">
						<img :src="account.logo" style="width: 80%; object-fit:contain" />
					</div>
					<div class="dF jC w-full mt-4">
						<div class="aC" style="display: grid; grid-template-columns: repeat(5, minmax(0px, 1fr)); gap: 7px;">
							<div v-for="(social, socialI) in getSocials(account.socials)" :key="social.id">
								<div style="width: 20px; text-align: center;">
									<img style="object-fit:cover; height:18px; width:18px"
										src="https://bildhive.nyc3.digitaloceanspaces.com/v3/facebook_icon_1f824839f3.png"
										v-if="social.type == 'Facebook'" />
									<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/linkedin_icon_6fd5b99eca.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'LinkedIn'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/twitter_logo_email_2343518957.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Twitter'" />
									<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/instagram_icon_8eb7fba8c2.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Instagram'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/youtube_c77a285d7a.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'YouTube'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/google_plus_2082ce542c.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Google+'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/pinterest_30db360395.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Pinterest'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/wechat_c566d59358.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'WeChat'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/quora_2fb387fc08.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Quora'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/foursquare_54e2bbc3e4.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Foursquare'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/klout_d89258e142.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Klout'" />
								</div>
							</div>
						</div>
					</div>
					<div v-if="account.appointment" class="mt-4">
						<a-button style="pointer-events: none;"
							:style="`color:${account.appointmentColor}; background-color: ${account.appointmentBgColor}`">{{
								account.appointmentText }}</a-button>
					</div>
				</div>
				<div style="width: 60%;">
					<div style="font-weight: bold;">{{ account.name.toUpperCase() }}</div>
					<div><small>{{ account.jobTitle }}</small></div>
					<div class="mb-3"><small>{{ account.company }}</small></div>
					<div v-if="account.email && account.email != ''" class="dF aS">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Email:</small></span>
						<div class="f1"><small>{{ account.email }}</small></div>
					</div>
					<div v-if="account.address && account.address != ''" class="dF aS">
						<span style="width: 65px;"><small
								:style="`color:${account.fieldColor};`">Address:</small></span>
						<div class="f1"><small>{{ account.address }}</small></div>
					</div>
					<div class="dF aS mb-3" v-if="account.website && account.website != ''">
						<span style="width: 65px;"><small
								:style="`color:${account.fieldColor};`">Website:</small></span>
						<div class="f1"><small>{{ account.website }}</small></div>
					</div>
					<div class="dF aS" v-if="account.phone.value">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">{{ account.phone.type }}:</small></span>
						<div class="f1"><small>{{ account.phone.value }}</small></div>
					</div>
					<div v-if="account.officePhone" class="dF aS">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Office:</small></span>
						<div class="f1"><small>{{ account.officePhone }}</small></div>
					</div>
					<div v-if="account.fax" class="dF aS">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Fax:</small></span>
						<div class="f1"><small>{{ account.fax }}</small></div>
					</div>
				</div>
			</div>
		</div>

		<div v-else-if="account.template == '2'" style="max-width:500px" :style="`font-family: ${account.fontFamily}`">
			<div class="w-full dF jC">
				<img v-if="account.logo && account.logo != ''" :src="account.logo"
					style="width: 40%; object-fit:contain;" />
			</div>
			<div class="mt-3">
				<div class="text-center" style="font-weight: bold;">{{ account.name.toUpperCase() }}</div>
				<div class="text-center"><small>{{ account.jobTitle }}</small></div>
				<div class="text-center"><small>{{ account.company }}</small></div>
				<div v-if="account.appointment" class="mt-3 text-center">
					<a-button style="pointer-events: none;"
						:style="`color:${account.appointmentColor}; background-color: ${account.appointmentBgColor}`">{{
							account.appointmentText }}</a-button>
				</div>
			</div>
			<div class="mt-3" style="display:flex; gap:20px">
				<div style="width: 65%;">
					<div v-if="account.email && account.email != ''" class="dF aS">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Email:</small></span>
						<div class="f1"><small>{{ account.email }}</small></div>
					</div>
					<div v-if="account.address && account.address != ''" class="dF aS">
						<span style="width: 65px;"><small
								:style="`color:${account.fieldColor};`">Address:</small></span>
						<div class="f1"><small>{{ account.address }}</small></div>
					</div>
					<div class="dF aS mb-3" v-if="account.website && account.website != ''">
						<span style="width: 65px;"><small
								:style="`color:${account.fieldColor};`">Website:</small></span>
						<div class="f1"><small>{{ account.website }}</small></div>
					</div>
					<div class="dF aS" v-if="account.phone.value">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">{{ account.phone.type
								}}:</small></span>
						<div class="f1"><small>{{ account.phone.value }}</small></div>
					</div>
					<div v-if="account.officePhone" class="dF aS">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Office:</small></span>
						<div class="f1"><small>{{ account.officePhone }}</small></div>
					</div>
					<div v-if="account.fax" class="dF aS">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Fax:</small></span>
						<div class="f1"><small>{{ account.fax }}</small></div>
					</div>
				</div>
				<div class="text-right" style="width: 35%;">
					<div class="dF jE">
						<div class="grid w-full"
							style="grid-template-columns: repeat(4, minmax(0, 1fr)); direction: rtl; gap: 7px;">
							<div v-for="(social, socialI) in getSocials(account.socials)" :key="social.id">
								<div style="width: 20px; text-align: center;">
									<img style="object-fit:cover; height:18px; width:18px"
										src="https://bildhive.nyc3.digitaloceanspaces.com/v3/facebook_icon_1f824839f3.png"
										v-if="social.type == 'Facebook'" />
									<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/linkedin_icon_6fd5b99eca.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'LinkedIn'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/twitter_logo_email_2343518957.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Twitter'" />
									<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/instagram_icon_8eb7fba8c2.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Instagram'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/youtube_c77a285d7a.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'YouTube'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/google_plus_2082ce542c.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Google+'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/pinterest_30db360395.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Pinterest'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/wechat_c566d59358.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'WeChat'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/quora_2fb387fc08.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Quora'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/foursquare_54e2bbc3e4.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Foursquare'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/klout_d89258e142.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Klout'" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-else-if="account.template == '3'" style="max-width:500px" :style="`font-family: ${account.fontFamily}`">
			<div class="dF">
				<div class="relative" style="width: 40%">
					<img style="object-fit:contain; width: 100%;" v-if="account.logo && account.logo != ''"
						:src="account.logo" />
					<div class="mt-3 ">
						<div style="text-align: center; font-weight: bold;">{{ account.name.toUpperCase() }}</div>
						<div style="text-align: center;"><small>{{ account.jobTitle }}</small></div>
						<div style="text-align: center;"><small>{{ account.company }}</small></div>
						<div v-if="account.appointment" class="mt-3">
							<a-button style="pointer-events: none;"
								:style="`color:${account.appointmentColor}; background-color: ${account.appointmentBgColor}`">{{
									account.appointmentText }}</a-button>
						</div>
					</div>
				</div>
				<div class="ml-5 relative" style="width: 60%;">
					<div v-if="account.email && account.email != ''" class="dF aS">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Email:</small></span>
						<div class="f1"><small>{{ account.email }}</small></div>
					</div>
					<div v-if="account.address && account.address != ''" class="dF aS">
						<span style="width: 65px;"><small
								:style="`color:${account.fieldColor};`">Address:</small></span>
						<div class="f1"><small>{{ account.address }}</small></div>
					</div>
					<div class="dF aS mb-3" v-if="account.website && account.website != ''">
						<span style="width: 65px;"><small
								:style="`color:${account.fieldColor};`">Website:</small></span>
						<div class="f1"><small>{{ account.website }}</small></div>
					</div>
					<div class="dF aS" v-if="account.phone.value">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">{{ account.phone.type
								}}:</small></span>
						<div class="f1"><small>{{ account.phone.value }}</small></div>
					</div>
					<div v-if="account.officePhone" class="dF aS">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Office:</small></span>
						<div class="f1"><small>{{ account.officePhone }}</small></div>
					</div>
					<div v-if="account.fax" class="dF aS">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Fax:</small></span>
						<div class="f1"><small>{{ account.fax }}</small></div>
					</div>

					<div class="dF jE mt-3">
						<div class="dF aC" style="flex-wrap: wrap; gap: 7px;">
							<div v-for="(social, socialI) in getSocials(account.socials)" :key="social.id">
								<div style="width: 20px; text-align: center;">
									<img style="object-fit:cover; height:18px; width:18px"
										src="https://bildhive.nyc3.digitaloceanspaces.com/v3/facebook_icon_1f824839f3.png"
										v-if="social.type == 'Facebook'" />
									<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/linkedin_icon_6fd5b99eca.png"
										style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'LinkedIn'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/twitter_logo_email_2343518957.png"
										style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'Twitter'" />
									<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/instagram_icon_8eb7fba8c2.png"
										style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'Instagram'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/youtube_c77a285d7a.png"
										style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'YouTube'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/google_plus_2082ce542c.png"
										style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'Google+'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/pinterest_30db360395.png"
										style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'Pinterest'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/wechat_c566d59358.png"
										style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'WeChat'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/quora_2fb387fc08.png"
										style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'Quora'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/foursquare_54e2bbc3e4.png"
										style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'Foursquare'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/klout_d89258e142.png"
										style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'Klout'" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-else-if="account.template == '4'" style="width:100%" :style="`font-family: ${account.fontFamily}`">
			<div class="dF">
				<div class="mr-4" style="width: 50%;">
					<div class="mb-3">
						<div style="font-weight: bold;">{{ account.name.toUpperCase() }}</div>
						<div><small>{{ account.jobTitle }}</small></div>
						<div><small>{{ account.company }}</small></div>
					</div>
					<div v-if="account.email && account.email != ''" class="dF aS">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Email:</small></span>
						<div class="f1"><small>{{ account.email }}</small></div>
					</div>
					<div v-if="account.address && account.address != ''" class="dF aS">
						<span style="width: 65px;"><small
								:style="`color:${account.fieldColor};`">Address:</small></span>
						<div class="f1"><small>{{ account.address }}</small></div>
					</div>
					<div class="dF aS mb-3" v-if="account.website && account.website != ''">
						<span style="width: 65px;"><small
								:style="`color:${account.fieldColor};`">Website:</small></span>
						<div class="f1"><small>{{ account.website }}</small></div>
					</div>
					<div class="dF aS" v-if="account.phone.value">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">{{ account.phone.type
								}}:</small></span>
						<div class="f1"><small>{{ account.phone.value }}</small></div>
					</div>
					<div v-if="account.officePhone" class="dF aS">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Office:</small></span>
						<div class="f1"><small>{{ account.officePhone }}</small></div>
					</div>
					<div v-if="account.fax" class="dF aS">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Fax:</small></span>
						<div class="f1"><small>{{ account.fax }}</small></div>
					</div>
					<div v-if="account.appointment" class="mt-4">
						<a-button style="pointer-events: none;"
							:style="`color:${account.appointmentColor}; background-color: ${account.appointmentBgColor}`">{{
								account.appointmentText }}</a-button>
					</div>
				</div>
				<div class="dF jE relative" style="width: 50%;">
					<div class="relative w-full">
						<div class="dF jC">
							<img style="object-fit:contain; width: 75%;"
								v-if="account.logo && account.logo != ''" :src="account.logo" />
						</div>
						<div class="mt-4 w-full dF jC">
							<div class="grid" style="grid-template-columns: repeat(4, minmax(0, 1fr)); gap: 0.5em;">
								<div v-for="(social, socialI) in getSocials(account.socials)" :key="social.id">
									<div style="width: 20px; text-align: center;">
										<img style="object-fit:cover; height:18px; width:18px"
											src="https://bildhive.nyc3.digitaloceanspaces.com/v3/facebook_icon_1f824839f3.png"
											v-if="social.type == 'Facebook'" />
										<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/linkedin_icon_6fd5b99eca.png"
											style="object-fit:cover; height:18px; width:18px"
											v-if="social.type == 'LinkedIn'" />
										<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/twitter_logo_email_2343518957.png"
											style="object-fit:cover; height:18px; width:18px"
											v-if="social.type == 'Twitter'" />
										<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/instagram_icon_8eb7fba8c2.png"
											style="object-fit:cover; height:18px; width:18px"
											v-if="social.type == 'Instagram'" />
										<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/youtube_c77a285d7a.png"
											style="object-fit:cover; height:18px; width:18px"
											v-if="social.type == 'YouTube'" />
										<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/google_plus_2082ce542c.png"
											style="object-fit:cover; height:18px; width:18px"
											v-if="social.type == 'Google+'" />
										<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/pinterest_30db360395.png"
											style="object-fit:cover; height:18px; width:18px"
											v-if="social.type == 'Pinterest'" />
										<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/wechat_c566d59358.png"
											style="object-fit:cover; height:18px; width:18px"
											v-if="social.type == 'WeChat'" />
										<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/quora_2fb387fc08.png"
											style="object-fit:cover; height:18px; width:18px"
											v-if="social.type == 'Quora'" />
										<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/foursquare_54e2bbc3e4.png"
											style="object-fit:cover; height:18px; width:18px"
											v-if="social.type == 'Foursquare'" />
										<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/klout_d89258e142.png"
											style="object-fit:cover; height:18px; width:18px"
											v-if="social.type == 'Klout'" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-else-if="account.template == '5'" style="width:100%" :style="`font-family: ${account.fontFamily}`">
			<div class="dF jE relative" style="width: 75%;">
				<div class="relative w-full">
					<div class="dF jS">
						<img style="object-fit:contain; width: 75%;"
							v-if="account.logo && account.logo != ''" :src="account.logo" />
					</div>
					<div class="mt-4 w-full dF jS">
						<div class="dF aC" style="gap: 0.5em;">
							<div v-for="(social, socialI) in getSocials(account.socials)" :key="social.id">
								<div style="width: 20px; text-align: center;">
									<img style="object-fit:cover; height:18px; width:18px"
										src="https://bildhive.nyc3.digitaloceanspaces.com/v3/facebook_icon_1f824839f3.png"
										v-if="social.type == 'Facebook'" />
									<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/linkedin_icon_6fd5b99eca.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'LinkedIn'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/twitter_logo_email_2343518957.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Twitter'" />
									<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/instagram_icon_8eb7fba8c2.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Instagram'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/youtube_c77a285d7a.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'YouTube'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/google_plus_2082ce542c.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Google+'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/pinterest_30db360395.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Pinterest'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/wechat_c566d59358.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'WeChat'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/quora_2fb387fc08.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Quora'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/foursquare_54e2bbc3e4.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Foursquare'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/klout_d89258e142.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Klout'" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="mt-3 mr-4">
				<div class="mb-3">
					<div style="font-weight: bold;">{{ account.name.toUpperCase() }}</div>
					<div><small>{{ account.jobTitle }}</small></div>
					<div><small>{{ account.company }}</small></div>
				</div>
				<div v-if="account.email && account.email != ''" class="dF aS">
					<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Email:</small></span>
					<div class="f1"><small>{{ account.email }}</small></div>
				</div>
				<div v-if="account.address && account.address != ''" class="dF aS">
					<span style="width: 65px;"><small
							:style="`color:${account.fieldColor};`">Address:</small></span>
					<div class="f1"><small>{{ account.address }}</small></div>
				</div>
				<div class="dF aS mb-3" v-if="account.website && account.website != ''">
					<span style="width: 65px;"><small
							:style="`color:${account.fieldColor};`">Website:</small></span>
					<div class="f1"><small>{{ account.website }}</small></div>
				</div>
				<div class="dF aS" v-if="account.phone.value">
					<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">{{ account.phone.type
							}}:</small></span>
					<div class="f1"><small>{{ account.phone.value }}</small></div>
				</div>
				<div v-if="account.officePhone" class="dF aS">
					<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Office:</small></span>
					<div class="f1"><small>{{ account.officePhone }}</small></div>
				</div>
				<div v-if="account.fax" class="dF aS">
					<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Fax:</small></span>
					<div class="f1"><small>{{ account.fax }}</small></div>
				</div>
				<div v-if="account.appointment" class="mt-4">
					<a-button style="pointer-events: none;"
						:style="`color:${account.appointmentColor}; background-color: ${account.appointmentBgColor}`">{{
							account.appointmentText }}</a-button>
				</div>
			</div>
		</div>

		<div @click="exportModal.visible = true" class="dF aC cursor-pointer mt-5" style="color:var(--primary)">
			<a-icon style="font-size:25px" type="upload" class="mr-2" />
			<span>Export Email Signature</span>
		</div>

		<a-modal title="Export to Existing Project" :visible="exportModal.visible" okText="EXPORT"
			@cancel="exportModal.visible = false" @ok="exportEmailSignature" centered>
			<a-row :gutter="16">
				<a-form-model ref="exportModal" :model="exportModal">
					<a-col :span="24">
						<a-form-model-item prop="instance" label="Project" required
							:rules="req('Please select the instance')">
							<a-select show-search :filter-option="filterOption" v-model="exportModal.instance"
								placeholder="Select a Project" size="large">
								<a-select-option v-for="inst in instances" :value="inst.id" :key="inst.id">{{ inst.name
									}}</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
				</a-form-model>
			</a-row>
			<a-alert type="info" show-icon class="mt-3">
				<template slot="message">
					When exporting a signature to another project, ensure to modify the details based on your requirement.
				</template>
			</a-alert>
		</a-modal>
	</div>
</template>

<script>
	import BHLoading from 'bh-mod/components/common/Loading'
	export default {
		components: {
			BHLoading
		},
		props: {
		},
		data() {
			return {
				loading: false,
				templateType: '',
				exportModal: {
					visible: false,
					instance: null,
				},
			}
		},
		watch: {

		},
		computed: {
			user() {
				return this.$store.state.user.user
			},
			account() {
				let previewAccount = this.$store.state.contacts.previewAccount
				if (Object.keys(previewAccount).length !== 0) {
					return previewAccount
				} else {
					let account = {
						name: this.user.firstName + ' ' + this.user.lastName,
						firstName: this.user.firstName,
						lastName: this.user.lastName,
						avatar: this.user.avatar || '',
						jobTitle: '',
						email: this.user.email,
						company: this.user.company || '',
						phone: { value: this.user.phone || '', type: "Work" },
						website: '',
						template: 1,
						socials: [],
						officePhone: '',
						address: '',
						fax: ''
					}
					return account
				}
			},
			instance() {
				return this.$store.state.instance
			},
			instances() {
				return this.$store.state.instances.filter(x => x.id != this.instance.id && x.name).sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
			},
		},
		methods: {
			req: msg => ({ required: true, message: msg }),

			filterOption(input, option) {
				return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
			},

			getSocials(array) {
				if (array && array.length) {
					let social = array.filter(x => {
						if (x.type) return x
					})
					console.log("GET SOCIALS", social)
					return social
				} else {
					return []
				}
			},
			cancel() {
				if (this.$store.state.contacts.allSettings.userApp && this.$store.state.contacts.allSettings.userApp.options && this.$store.state.contacts.allSettings.userApp.options.account) {
					let account = this.$store.state.contacts.allSettings.userApp.options.account
					Object.keys(this.account).forEach(key => {
						if (account[key]) {
							this.account[key] = account[key]
						} else {
							this.account[key] = ''
						}
					})
				}
			},
			saveTemplate() {
				console.log('save template')
				if (!this.account.name || this.account.name == '' || !this.account.email || this.account.email == '') {
					return this.$message.error('Please enter the name and email!')
				}
				let settings = JSON.parse(JSON.stringify(this.$store.state.contacts.allSettings.userApp))
				settings.options.account = JSON.parse(JSON.stringify(this.account))
				this.loading = true
				this.$api.post('/settings/:instance/contacts/user', settings).then(({ data }) => {
					console.log('DATAA COMING BACK', data)
					this.loading = false
					this.$notification['success']({
						message: 'Email Signature Changed',
						description: 'Your email signature has been updated successfully.',
						duration: 4
					});
					this.$store.commit('UPDATE_USER_ALLSETTINGS', data)
				}).catch(err => {
					this.loading = false
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
			},

			exportEmailSignature() {
				let self = this;
				this.$refs.exportModal && this.$refs.exportModal.validate(async (valid) => {
					if (valid) {
						self.$store.commit('LOAD', true)
						try {
							await self.$api.post(`settings/:instance/contacts/clone-user-settings`, {
								targetInstance: self.exportModal.instance,
								keysToClone: ["account"]
							})
							self.$store.commit('LOAD', false)
							self.$message.success('Successfully exported email signature into selected project.')
							self.exportModal = {
								visible: false,
								instance: null
							}
						} catch (err) {
							self.$store.commit('LOAD', false)
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err, `An error occurred while exporting email signature to another project. Please try again.`))
							}
						}
					} else {
						return false;
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped></style>
