<template>
	<div>
		<template v-if="!records.length">
			<EmptyApp :text="noTasksNote" :img="require('@/assets/emptyIcons/tasks.png')" />
		</template>
		<template v-else>
			<a-table :columns="columns" :data-source="records" row-key="id" class="white-table">
				<template slot="taskTitle" slot-scope="task">
					<h6>{{ task.title }}</h6>
					<div class="text-sm" style="color: gray;">
						<a-tooltip overlayClassName="change-tooltip-color">
							<template slot="title" v-if="task.description?.length > 50">
								{{ task.description }}
							</template>
							{{ getTaskDescription(task.description) }}
						</a-tooltip>
					</div>
					<div class="text-sm" v-if="task.sentiment">
						Outcome: <Strong> {{ task.sentiment }} </Strong>
					</div>
				</template>
				<template slot="taskType" slot-scope="task">
					{{ taskTypesObj[task.type] || task.type }}
				</template>
				<template slot="dueDate" slot-scope="task">
					<span>{{ $formatDate(task.dueDate) }}</span>
				</template>
				<template slot="contact" slot-scope="task">
					<template v-if="task.contact">
						<a-avatar class="task-contact-avatar">
							{{ task.contact.firstName.charAt(0) }}{{ task.contact.lastName.charAt(0) }}
						</a-avatar>
						<span class="link" @click="openContactDetails(task.contact)">
							{{ task.contact.firstName }} {{ task.contact.lastName }}
						</span>
					</template>
					<template v-else-if="task.opportunity">
						<a-avatar class="task-contact-avatar">
							{{ task.opportunity.name?.charAt(0) }}
						</a-avatar>
						<span class="link" @click="openOpportunityDetails(task.opportunity)">
							{{ task.opportunity.name }}
						</span>
					</template>
				</template>
				<template slot="priority" slot-scope="task">
					<span class="badge-offer tT" :class="priorityColorClass[task.priority]">
						{{ task.priority }}
					</span>
				</template>
				<div slot="assignTo" slot-scope="task" class="dF">
					<template v-for="(user, userI) in task.assignTo">
						<div v-if="userI < 3" :key="userI" class="owner-icon"
							:style="userI !== 0 ? 'margin-left: -5px' : ''">
							<a-avatar v-if="user.avatar" :src="user.avatar" shape="circle" :size="30" />
							<a-avatar v-else>
								{{ user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase() }}
							</a-avatar>
						</div>
					</template>
				</div>
				<template slot="others" slot-scope="task">
					<div class="dF aC" style="gap: 25px; font-size: 18px;">
						<div>
							<a-icon type="paper-clip"></a-icon>
							{{ task.files?.length || 0 }}
						</div>
						<div>
							<a-tooltip overlayClassName="change-tooltip-color">
								<template slot="title">
									<span v-if="task.comments?.length" v-html="getTaskComments(task)"></span>
								</template>
								<a-icon type="message"></a-icon>
								{{ task.comments?.length || 0 }}
							</a-tooltip>

						</div>
					</div>
				</template>
				<template slot="action" slot-scope="task">
					<a-popover trigger="hover" placement="bottomRight" overlayClassName="popoverStyle">
						<div slot="content">
							<div class="popoverContent dF aC" @click="editTask(task)">
								<a-icon type="edit" class="mr-2" /> Edit
							</div>
							<div class="popoverContent dF aC" @click="copyTask(task)">
								<a-icon type="copy" class="mr-2" /> Copy
							</div>
							<div v-if="$p >= 0" class="popoverContent dF aC" @click="deleteTask(task)">
								<a-icon type="delete" class="mr-2" style="color: red;" /> Delete
							</div>
						</div>
						<div class="more-option-icon">
							<a-icon style="line-height: 40px" type="more" />
						</div>
					</a-popover>
				</template>
			</a-table>
		</template>
		<EditTaskModal />
	</div>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'
import EmptyApp from 'bh-mod/components/common/EmptyApp'
import EditTaskModal from '@/components/contacts/EditTaskModal'
import moment from 'moment'

export default {
	components: {
		BHLoading,
		EmptyApp,
		EditTaskModal
	},
	props: {
		columns: Array,
		records: Array,
		type: String,
	},
	data() {
		return {
			loading: false,
			priorityColorClass: {
				low: 'bg-teal text-white',
				medium: 'bg-orange text-white',
				high: 'bg-danger text-white',
			},
			taskType: {
				completedTasks: 'completed tasks',
				overdueTasks: 'overdue tasks',
				todayTasks: 'tasks due today',
				tomorrowTasks: 'tasks due tomorrow',
				futureTasks: 'future tasks',
				nodueTasks: 'tasks with no due date',
				highPriorityTasks: 'high priority tasks',
				mediumPriorityTasks: 'medium priority tasks',
				lowPriorityTasks: 'low priority tasks',
			},
		}
	},
	computed: {
		taskTypes() {
			const taskTypes = this.$store.state.contacts?.allSettings?.app?.options?.taskType;
			const list = taskTypes?.length ? taskTypes : [
				{ name: 'To-do', id: 'todo' },
				{ name: 'Call', id: 'call' },
				{ name: 'Follow up', id: 'followup' }
			];

			return list;
		},

		taskTypesObj() {
			return this.taskTypes.reduce((acc, curr) => {
				acc[curr.id] = curr.name;
				return acc;
			}, {});
		},

		contacts() {
			return Object.values(this.$store.state.contacts.allContacts)
		},

		externalContacts() {
			return this.$store.state.contacts.externalContacts
		},

		opportunities() {
			return this.$store.state.contacts.opportunities
		},

		users() {
			return this.$store.state.contacts.users
		},

		user() {
			return this.$store.state.user.user || {}
		},

		noTasksNote() {
			return `You don't have any ${this.taskType[this.type] || this.type}`
		},
	},
	methods: {
		moment,

		async openContactDetails(obj) {
			const contact = this.contacts[obj.id] || this.externalContacts[obj.id];
			if (contact) {
				this.$store.commit('OPEN_CONTACT_DETAILS', contact);
			} else {
				this.$nprogress.start();
				const contactDetails = await this.fetchContactDetails(obj.id);
				this.$nprogress.done();
				if (contactDetails) {
					this.$store.commit('OPEN_CONTACT_DETAILS', contactDetails);
				}
			}
		},

		async openOpportunityDetails(newObj) {
			const opportunity = this.opportunities.find(x => x.id === newObj.id);

			if (opportunity) {
				if (typeof opportunity.contact === 'string') {
					const contact = this.contacts[opportunity.contact] || this.externalContacts[opportunity.contact];
					if (contact) {
						opportunity.contact = contact;
					} else {
						this.$nprogress.start();
						const contactDetails = await this.fetchContactDetails(opportunity.contact);
						this.$nprogress.done();
						if (contactDetails) {
							opportunity.contact = contactDetails;
						}
					}
				}
				this.$store.commit('OPEN_OPPORTUNITY_DETAILS', opportunity);
			}
		},

		async fetchContactDetails(contactId) {
			this.loading = true;
			try {
				const { data } = await this.$api.get(`/contacts/:instance/${contactId}`);
				this.$store.commit('ADD_EXTERNAL_CONTACT', data);
				return data;
			} catch (err) {
				this.$toastError(err, 'Error while fetching contact details. Please try again');
			} finally {
				this.loading = false;
			}
		},

		getTaskDescription(description = '') {
			if (description?.length > 50) {
				return description.substring(0, 50) + '...';
			}
			return description;
		},

		getTaskComments(task) {
			let comments = task.comments;
			if (comments.length > 0) {
				let lastComment = comments[comments.length - 1];

				let user = lastComment.user || null;
				let comment = lastComment.content;
				let commentText = comment;
				if (typeof user === 'string') {
					user = this.users.find(u => u.id === user);
				}
				if (user?.firstName && user?.lastName) {
					commentText = `${user.firstName} ${user.lastName}: ${comment}`;
				}

				return commentText;
			}
			return '';
		},

		editTask(task) {
			this.$store.commit('EDIT_TASK', task)
		},

		copyTask(task) {
			this.$store.commit('ADD_NEW', 'Task')
			this.$store.commit('DUPLICATE', task)
		},

		deleteTask(task) {
			if (this.$p < 40 && (!task.createdBy || task.createdBy.id !== this.$store.state.user.user.id)) {
				this.$message.error('You do not have permission to delete tasks')
				return
			}
			this.$confirm({
				title: 'Delete Task',
				content: () => <div>Are you sure you want to delete this task <strong>{task.title}</strong>?</div>,
				okText: 'Yes',
				cancelText: 'No',
				okType: "danger",
				centered: true,
				onOk: async () => {
					this.loading = true
					try {
						await this.$api.delete(`/tasks/:instance/${task.id}`)
						this.$store.commit('DELETE_TASK', task)
					} catch (error) {
						this.$toastError(this.$err(err, `Error while deleting the task. Please try again.`))
					} finally {
						this.loading = false
					}
				},
			})
		}
	},
}
</script>

<style>
.task .ant-card-body {
	padding: 10px;
}
</style>

<style scoped>
.task-contact-avatar {
	color: white;
	background-color: #1070CA;
	margin-right: 0.5rem;
}

.priorityButton {
	border-radius: 4px;
	text-align: center;
	color: white;
	width: 167px;
	cursor: pointer;
}

.popoverContent {
	height: 35px;
	line-height: 35px;
	padding-left: 10px;
	padding-right: 10px;
	display: flex;
	align-items: center;
}

.popoverContent:hover {
	background-color: var(--off-white-light);
	cursor: pointer;
	color: #000;
}

.badge-offer {
	padding: 5px 10px;
	border-radius: 5px;
}

.bg-teal {
	background-color: var(--teal);
}


.select-high,
.select-med,
.select-low,
.high-priority,
.med-priority,
.low-priority {
	cursor: pointer;
	line-height: 1.6;
	padding: 0 15px;
	border-radius: 4px;
	margin-right: 10px;
	border: 1px solid #9EA0A5;
}

.select-high,
.high-priority:hover {
	background-color: var(--danger);
	color: white;
	border-color: var(--danger);
}

.select-med,
.med-priority:hover {
	background-color: var(--orange);
	color: white;
	border-color: var(--orange);
}

.select-low,
.low-priority:hover {
	background-color: var(--teal);
	color: white;
	border-color: var(--teal);
}

.high-priority {
	color: var(--danger);
}

.med-priority {
	color: var(--orange);
}

.low-priority {
	color: var(--teal);
}
</style>
