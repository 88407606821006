<template>
    <div>
        <bhLoading :show="loading" />
		<EditTaskModal @update="updateTasks"/>
        <a-card>
            <div class="dF jE" slot="title">
                <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                    <div slot="title">Add New Task</div>
                    <a-icon @click="addTask" style="font-size:25px; color:var(--orange); cursor:pointer" type="plus-circle" />
                </a-tooltip>
            </div>
            <template v-if="tasks.length">
                <div :class="keyI != 0 ? 'mt-5':''" v-for="(key, keyI) in Object.keys(orderTasks)" :value="key+keyI" :key="key+keyI">
                    <div style="color:#212529; font-size:15px; font-weight:bold;">{{key.toUpperCase()}}</div>
                    <template v-if="orderTasks[key].length">
                        <div class="dF jSB mt-3" v-for="task in orderTasks[key]" :value="task.id" :key="task.id">
                            <div class="dF">
                                <a-checkbox :value="task.id" :checked="task.completed" @change="editTask(task, !task.completed)" class="checkbox-circle mr-3" />
                                <div>
                                    <div style="color:var(--orange);">{{task.title}}</div>
                                    <div v-if="task.description" style="color:#000" v-html="task.description"></div>
                                    <div v-if="task.sentiment">Outcome Sentiment: {{ task.sentiment}}</div>
                                    <div style="color:#9EA0A5">{{`Due Date: ${$formatDate(task.dueDate)}`}}</div>
                                </div>
                            </div>
                            <div class="dF">
	                            <div class="dF mr-3">
	                                <a-tooltip slot="extra" overlayClassName="change-tooltip-color" class="mr-2">
	                                    <template slot="title">Edit Task</template>
	                                    <svg @click="editTask(task)" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 icon-button"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
	                                </a-tooltip>
	                                <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
	                                    <template slot="title">Delete Task</template>
	                                    <i style="color:var(--danger)" @click="deleteTask(task)" class="fe fe-trash-2" />
	                                </a-tooltip>
	                            </div>
	                            <div class="dF">
	                                <template v-for="(user,userI) in task.assignTo">
	                                    <div v-if="userI < 3" :key="userI" class="owner-icon" :style="userI !== 0? 'margin-left: -5px' : ''" style="cursor:pointer">
	                                        <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
	                                            <template slot="title">{{`${user.firstName} ${user.lastName}`}}</template>
	                                            <a-avatar v-if="user && user.avatar" :src="user.avatar" shape="circle" :size="30"/>
	                                            <a-avatar v-else>{{user && user.firstName && user.lastName ? user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase() : ''}}</a-avatar>
	                                        </a-tooltip>
	                                    </div>
	                                </template>
	                            </div>
                        	</div>
                        </div>
                    </template>
                    <div v-else>{{`You don't have any tasks ${key}`}}</div>
                </div>
            </template>
            <template v-else>This lead doesn't have any task...</template>
        </a-card>
    </div>
</template>

<script>
import bhLoading from 'bh-mod/components/common/Loading'
import EditTaskModal from '@/components/contacts/EditTaskModal'

export default {
    components:{
        EditTaskModal, bhLoading
    },
    props:{
        id:{
            type:String,
            default:''
        }
    },
    data() {
        return{
            update:0,
            loading:false,
        }
    },
    computed:{
		dateFormat() {
			return this.$store.state.contacts.allSettings.user?.options?.regional?.dateFormat || 'MM/DD/YYYY';
		},
        tasks(){
            let tasks = []
            let time = this.update
            if (this.storeTask.length) {
                tasks = this.storeTask.filter(x => {
                    if(x.contact && x.contact.id && x.contact.id == this.id) {
                        return x
                    }
                })
            }
            return tasks
        },
        storeTask() {
            return this.$store.state.contacts.tasks || []
        },
		orderTasks() {
			let today = new Date().getDate()
			let month = new Date().getMonth()
			let year = new Date().getFullYear();

			let time = this.update
			let tasks = {}
			if (this.tasks.length) {
				tasks.overdue = this.tasks.filter(x => {
					if (x.dueDate && x.dueDate != 0 && new Date(x.dueDate).getDate() < today && new Date(x.dueDate).getMonth() <= month && new Date(x.dueDate).getFullYear() <= year) {
						return x
					}
				})
				tasks.today = this.tasks.filter(x => {
					if (x.dueDate && x.dueDate != 0 && new Date(x.dueDate).getDate() == today && new Date(x.dueDate).getMonth() == month && new Date(x.dueDate).getFullYear() == year) {
						return x
					}
				})
				tasks.tomorrow = this.tasks.filter(x => {
					if (x.dueDate && x.dueDate != 0 && new Date(x.dueDate).getDate() == today + 1 && new Date(x.dueDate).getMonth() == month && new Date(x.dueDate).getFullYear() == year) {
						return x
					}
				})

				tasks.completed = this.tasks.filter(x => {
					if (x.completed == true) {
						return x
					}
				})

				let tempTask = [].concat(tasks.overdue, tasks.today, tasks.tomorrow, tasks.completed)

				tasks.later = this.tasks.filter(task => {
					let index = tempTask.findIndex(t => t.id == task.id)
					return index == -1
				})
			}
			return tasks
		},
    },
    methods:{
		req: (msg) => ({ required: true, message: msg }),

        viewTask(task) {
            this.$store.commit('OPEN_PREVIEW_MODAL', {object:task, type:'task'})
        },
        updateTasks() {
            this.update = Date.now()
        },
        editTask(task, completed) {
			this.$store.commit('EDIT_TASK', {...task, completed: completed || !!task.completed })
        },
        deleteTask(task) {
			if (this.$p < 40 && (!task.createdBy || task.createdBy.id !== this.$store.state.user.user.id)) {
				this.$message.error('You do not have permission to delete tasks')
				return
			}
			this.$confirm({
				title: 'Delete Task',
				content: () => <div>Are you sure you want to delete this task <strong>{task.title}</strong>?</div>,
				okText: 'Yes',
				cancelText: 'No',
				okType: "danger",
				centered: true,
				onOk: async () => {
					this.loading = true
					try {
						await this.$api.delete(`/tasks/:instance/${task.id}`)
						this.$store.commit('DELETE_TASK', task)
					} catch (error) {
						this.$toastError(this.$err(err, `Error while deleting the task. Please try again.`))
					} finally {
						this.loading = false
					}
				},
			})
        },
        addTask() {
            this.$store.commit('ADD_NEW', 'Task')
            this.$store.commit('ADD_LEAD_ACTION', this.id)
        },
    }
}
</script>

<style>
.checkbox-circle .ant-checkbox-inner{
    border-radius: 50%;
}
.checkbox-circle .ant-checkbox-checked::after {
    border:none;
}
</style>
<style scoped>
.icon-button{
    cursor: pointer;
}
.icon-button:hover{
    color:var(--orange)
}
</style>
