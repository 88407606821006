<template>
	<div>
		<div v-if="instance" :class="$style.subbar" style="background-color: #f7f5f9">
			<div class="dF aC mb-0">
				<div :class="$style.breadcrumbs" style="align-items: center; display: block !important">
					<a-breadcrumb>
						<a-breadcrumb-item>{{
							instance.name
						}}</a-breadcrumb-item>
						<a-breadcrumb-item><router-link :to="'/'">{{
							selectedAppInitial
						}}</router-link></a-breadcrumb-item>
						<template v-if="['Sales Pipeline', 'Archived'].includes(sidebarSelection)">
							<template v-if="
								Object.keys($route.params).length == 0 || !$route.params ||
								Object.keys($route.meta).length == 0 || !$route.meta
							">
								<a-breadcrumb-item>Opportunities</a-breadcrumb-item>
								<a-breadcrumb-item style="color: var(--orange)">{{
									sidebarSelection
								}}</a-breadcrumb-item>
							</template>
							<template v-else-if="
								$route.params?.id &&
								$route.meta?.title ==
								'Opportunity Details'
							">
								<a-breadcrumb-item><router-link
										:to="'/opportunities/sales'">Opportunities</router-link></a-breadcrumb-item>
								<a-breadcrumb-item><router-link :to="'/opportunities/sales'">Sales
										Pipeline</router-link></a-breadcrumb-item>
								<a-breadcrumb-item style="color: var(--orange)">{{
									getOpp($route.params.id)
								}}</a-breadcrumb-item>
							</template>
						</template>
						<template v-else-if="['Inbox', 'Sent'].includes(sidebarSelection)">
							<a-breadcrumb-item>Email</a-breadcrumb-item>
							<a-breadcrumb-item style="color: var(--orange)">{{ sidebarSelection
								}}</a-breadcrumb-item>
						</template>
						<template v-else-if="
							$route.params?.id &&
							$route.meta?.title == 'Lead Details'
						">
							<a-breadcrumb-item><router-link :to="'/leads'">Leads</router-link></a-breadcrumb-item>
							<a-breadcrumb-item v-if="
								contacts[$route.params.id] &&
								contacts[$route.params.id].firstName &&
								contacts[$route.params.id].lastName
							" style="color: var(--orange)">{{
								`${contacts[$route.params.id].firstName
								} ${contacts[$route.params.id].lastName
								}`
							}}</a-breadcrumb-item>
							<a-breadcrumb-item v-else-if="
								externalContacts[$route.params.id] &&
								externalContacts[$route.params.id]
									.firstName &&
								externalContacts[$route.params.id]
									.lastName
							" style="color: var(--orange)">{{
								`${externalContacts[$route.params.id]
									.firstName
								} ${externalContacts[$route.params.id]
									.lastName
								}`
							}}</a-breadcrumb-item>
						</template>
						<template v-else>
							<a-breadcrumb-item style="color: var(--orange)">{{ sidebarSelection
								}}</a-breadcrumb-item>
						</template>
					</a-breadcrumb>
				</div>

				<div v-if="$store.state.sidebarSelection !== 'Settings' && $route.meta &&
					!['My Work Day', 'Lead Details', 'Opportunity Details', 'Event Details'].includes($route.meta.title)"
					:class="$style.amount" class="ml-auto d-none d-sm-flex">
					<template>
						<a-auto-complete size="large" v-if="$store.state.sidebarSelection === 'Leads'"
							v-model="tempSearch" style="width: 300px"
							:placeholder="'Search' + ' ' + `${$store.state.sidebarSelection}...`"
							:dropdown-match-select-width="false" :dropdown-style="{ width: '300px' }"
							option-label-prop="value" @search="handleSearch" class="mr-4 searchBar"
							@select="handleChange">
							<template slot="dataSource">
								<a-select-option v-for="contact in contactSearchResult" :key="contact.value"
									:value="contact.value">
									<div class="dF aC">
										<img class="mr-3 search-image"
											:src="require('@/assets/sideIcons/contacts.svg')" />
										<div class="f1">
											<div class="search-title">
												{{ contact.label }}
											</div>
											<small>{{ `<${contact.email}>` }}</small>
										</div>
									</div>
								</a-select-option>
							</template>
							<a-input>
								<a-icon slot="prefix" type="search" />
							</a-input>
						</a-auto-complete>

						<a-auto-complete size="large" v-else v-model="tempSearch" style="width: 300px"
							placeholder="Search here..." :dropdown-match-select-width="false"
							:dropdown-style="{ width: '300px' }" @search="handleSearch" class="mr-4 searchBar"
							@select="handleChange2">
							<!-- Email -->
							<template slot="dataSource"
								v-if="['Inbox', 'Sent'].includes($store.state.sidebarSelection)">
								<a-select-option v-for="obj in emailSearchResult" :key="obj.id" :value="obj.id">
									<div class="dF aC">
										<img class="mr-3 search-image" :src="require('@/assets/sideIcons/email.svg')" />
										<div class="f1">
											<small>{{ obj.messages?.[0]?.subject || "(No Subject)" }}</small>
										</div>
									</div>
								</a-select-option>
							</template>
							<!-- Opportunities -->
							<template slot="dataSource"
								v-if="['Sales Pipeline', 'Archived'].includes($store.state.sidebarSelection)">
								<a-select-option v-for="obj in opportunitySearchResult" :key="obj.id" :value="obj.id">
									<div class="dF aC">
										<img class="mr-3 search-image" :src="require('@/assets/sideIcons/opportunities.svg')
											" />
										<div class="f1">
											<div class="search-title">
												{{ obj.name }}
											</div>
											<small>
												{{
													obj.contact?.email && obj.contact?.firstName &&
														obj.contact?.lastName
														? `${obj.contact.firstName} ${obj.contact.lastName}
												<${obj.contact.email}>`
														: "No Contact"
												}}
											</small>
										</div>
									</div>
								</a-select-option>
							</template>

							<!-- Appointments -->
							<template slot="dataSource" v-if="$store.state.sidebarSelection === 'Appointments'">
								<a-select-option v-for="obj in appointmentSearchResult" :key="obj.id" :value="obj.id">
									<div class="dF aC">
										<img class="mr-3 search-image" :src="require('@/assets/sideIcons/appointment.svg')
											" />
										<div class="f1">
											<div class="search-title">
												{{ obj.title }}
											</div>
											<small>{{
												obj.date &&
													obj.startTime != 0 &&
													obj.endTime != 0
													? `${convertDate(
														obj.date
													)} | ${getRange(
														obj.startTime,
														obj.endTime
													)}`
													: ""
											}}</small>
										</div>
									</div>
								</a-select-option>
							</template>

							<!-- Tasks -->
							<template slot="dataSource" v-if="$store.state.sidebarSelection === 'Tasks'">
								<a-select-option v-for="obj in taskSearchResult" :key="obj.id" :value="obj.id">
									<div class="dF aC">
										<img class="mr-3 search-image" :src="require('@/assets/sideIcons/tasks.svg')
											" />
										<div class="f1">
											<div class="search-title">
												{{ obj.title }}
											</div>
											<small v-if="
												obj.dueDate != 0
											">{{
												`Due Date: ${$formatDate(
													obj.dueDate
												)}`
											}}</small>
											<small v-else>No Due Date</small>
										</div>
									</div>
								</a-select-option>
							</template>

							<!-- Calendar -->
							<template slot="dataSource" v-if="$store.state.sidebarSelection === 'Calendar'">
								<a-select-option v-for="obj in calendarSearchResult" :key="obj.id" :value="obj.id">
									<div class="dF aC">
										<img v-if="
											obj.class == 'appointment'
										" class="mr-3 search-image" :src="require('@/assets/sideIcons/appointment.svg')
											" />
										<img v-else class="mr-3 search-image" :src="require('@/assets/sideIcons/tasks.svg')
											" />
										<div class="f1">
											<div class="search-title">
												{{ obj.title }}
											</div>
											<small>{{
												obj.date &&
													obj.class ==
													"appointment" &&
													obj.startTime &&
													obj.endTime &&
													obj.startTime != 0 &&
													obj.endTime != 0
													? `${convertDate(
														obj.date
													)} | ${getRange(
														obj.startTime,
														obj.endTime
													)}`
													: obj.dueDate &&
														obj.class !=
														"appointment" &&
														obj.dueDate != 0
														? `Due Date: ${$formatDate(
															obj.dueDate
														)}`
														: "No Due Date"
											}}</small>
										</div>
									</div>
								</a-select-option>
							</template>
							<a-input>
								<a-icon slot="prefix" type="search" />
							</a-input>
						</a-auto-complete>
						<div class="mr-4 d-none d-xl-block dash-divider" />
					</template>

					<template v-if="$route.path.includes('leads')">
						<a-dropdown :trigger="['click']">
							<a-menu slot="overlay" @click="handleMenuClick">
								<a-menu-item key="Contact"> Leads </a-menu-item>
								<a-menu-item @click="importLeads" key="Import">Import Leads </a-menu-item>
							</a-menu>
							<a-button type="primary" icon="plus" size="large">ADD NEW</a-button>
						</a-dropdown>
					</template>
					<template v-else-if="$route.path.includes('calendar')">
						<a-dropdown :trigger="['click']">
							<a-menu slot="overlay" @click="handleMenuClick">
								<a-menu-item key="Appointment"> Appointment </a-menu-item>
								<a-menu-item key="Task"> Task </a-menu-item>
								<a-menu-item key="Opportunity"> Opportunity </a-menu-item>
							</a-menu>
							<a-button type="primary" icon="plus" size="large">ADD NEW</a-button>
						</a-dropdown>
					</template>
					<a-button v-else-if="$route.path.includes('email')" type="primary" size="large"
						@click="handleMenuClick({ key: 'Email' })" icon="form">COMPOSE</a-button>
					<a-button v-else-if="$route.path.includes('tasks')" type="primary" size="large"
						@click="handleMenuClick({ key: 'Task' })" icon="plus">ADD NEW TASK</a-button>
					<a-button v-else-if="$route.path.includes('appointments')" type="primary" size="large"
						@click="handleMenuClick({ key: 'Appointment' })" icon="plus">ADD NEW APPOINTMENT</a-button>
					<a-button v-else-if="$route.path.includes('events')" type="primary" size="large"
						@click="handleMenuClick({ key: 'Event' })" icon="plus">ADD NEW EVENT</a-button>
					<a-button v-else-if="$route.path.includes('opportunities')" type="primary" size="large"
						@click="handleMenuClick({ key: 'Opportunity' })" icon="plus">ADD NEW OPPORTUNITY</a-button>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import moment from "moment";

export default {
	data() {
		return {
			contactSearchResult: [],
			opportunitySearchResult: [],
			taskSearchResult: [],
			calendarSearchResult: [],
			appointmentSearchResult: [],
			emailSearchResult: [],
			tempSearch: "",
		};
	},
	watch: {
		$route: {
			immediate: true,
			handler() {
				this.tempSearch = "";
			},
		},
	},
	computed: {
		dateFormat() {
			return this.$store.state.contacts.allSettings?.user?.options?.regional?.dateFormat || 'MM/DD/YYYY'
		},
		selectedAppInitial() {
			return this.$store.state.theApp?.media?.initial || "CRM"
		},
		sidebarSelection() {
			return this.$store.state.sidebarSelection;
		},
		showSearch() {
			return this.$store.state.contacts.showSearch;
		},
		tags() {
			return this.$store.state.contacts.tags;
		},
		instance() {
			return this.$store.state.instance;
		},
		contacts() {
			return this.$store.state.contacts.allContacts;
		},
		externalContacts() {
			return this.$store.state.contacts.externalContacts;
		},
		opportunities() {
			return this.$store.state.contacts.opportunities;
		},
		tasks() {
			return this.$store.state.contacts.tasks;
		},
		appointments() {
			return this.$store.state.contacts.appointments;
		},
		theApp() {
			return this.$store.state.theApp || {};
		},
		nylasAccount() {
			return this.$store.state.contacts.nylasAccount || {}
		}
	},
	methods: {
		importLeads() {
			this.$router.push("/settings");
			this.$store.commit("IMPORT_LEAD");
		},
		getOpp(oppId) {
			let find = this.opportunities.find((x) => x.id == oppId);
			if (find) return find.name;
			else return "";
		},
		getRange(start, end) {
			if (start && end) {
				const formatTime = (time) => {
					const date = new Date(time);
					const hours = date.getHours().toString().padStart(2, '0');
					const minutes = date.getMinutes().toString().padStart(2, '0');
					return `${hours}:${minutes}`;
				};
				return `${formatTime(start)} - ${formatTime(end)}`;
			}
			return "";
		},
		convertDate(num) {
			if (num != 0) {
				let x = new Date(num)
				//Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
				let year = x.getFullYear()
				let month = x.getMonth() + 1
				let day = x.getDate()
				if (this.dateFormat == 'YYYY/MM/DD') return year + '/' + month + '/' + day
				else if (this.dateFormat == 'DD/MM/YYYY') return day + '/' + month + '/' + year
				else {
					return month + '/' + day + '/' + year
				}
			} else return ''
		},
		moment,

		handleMenuClick(e) {
			if (e.key == 'Email') {
				if (this.nylasAccount && this.nylasAccount.email) {
					this.$store.commit("ADD_NEW", e.key);
				} else {
					this.$notification['error']({
						message: 'No Email Connection',
						description: 'You have not connected your mail account. To connect, go to Settings and select Email & Calendar Settings > Integrations.',
						duration: 5
					});
				}
			} else if (e.key != "Import") {
				this.$store.commit("ADD_NEW", e.key);
			}
		},
		handleSearch(input) {
			if (this.$store.state.sidebarSelection == "Leads") {
				if (input.length < 3) {
					this.timerId = undefined;
					return;
				}

				if (this.timerId) clearTimeout(this.timerId);

				this.timerId = setTimeout(() => {
					this.updateList(input);
					this.timerId = undefined;
				}, 400);
			} else if (['Sales Pipeline', 'Archived'].includes(this.$store.state.sidebarSelection)) {
				if (input?.length > 1) {
					const lowerInput = input.toLowerCase();
					this.opportunitySearchResult = this.opportunities.filter(
						({ name }) => name?.toLowerCase().includes(lowerInput)
					);
				} else {
					this.opportunitySearchResult = [];
				}
			} else if (this.$store.state.sidebarSelection == "Tasks") {
				if (input?.length > 1) {
					const lowerInput = input.toLowerCase();
					this.taskSearchResult = this.tasks.filter(
						({ title }) => title?.toLowerCase().includes(lowerInput)
					);
				} else {
					this.taskSearchResult = []
				}
			} else if (this.$store.state.sidebarSelection == "Calendar") {
				if (input?.length > 1) {
					const lowerInput = input.toLowerCase();
					this.calendarSearchResult = [
						...this.tasks.filter(({ title }) => title.toLowerCase().includes(lowerInput)),
						...this.appointments.filter(({ title }) => title.toLowerCase().includes(lowerInput))
					];
				} else {
					this.calendarSearchResult = [];
				}
			} else if (this.$store.state.sidebarSelection == "Appointments") {
				this.appointmentSearchResult = this.appointments.filter(({ title }) =>
					title.toLowerCase().includes(input.toLowerCase())
				);
			} else if (['Inbox', 'Sent'].includes(this.$store.state.sidebarSelection)) {
				if (input.length < 3) {
					return (this.timerId2 = undefined);
				}
				if (this.timerId2) clearTimeout(this.timerId2);
				this.timerId2 = setTimeout(() => {
					this.updateEmailList(input);
					this.timerId2 = undefined;
				}, 800);
			}
		},

		handleChange(value) {
			let index = this.contactSearchResult.findIndex(
				(x) => x.value == value
			);
			this.tempSearch = this.contactSearchResult[index].label;

			if (this.contacts[value]) {
				this.$store.commit(
					"OPEN_CONTACT_DETAILS",
					this.contacts[value]
				);
			} else if (this.externalContacts[value]) {
				this.$store.commit(
					"OPEN_CONTACT_DETAILS",
					this.externalContacts[value]
				);
			} else {
				this.$store.commit("OPEN_CONTACT_DETAILS", { id: value, email: this.contactSearchResult[index].email });
			}
		},
		handleChange2(value) {
			if (["Sales Pipeline", "Archived"].includes(this.$store.state.sidebarSelection)) {
				let index = this.opportunities.findIndex((x) => x.id == value);
				this.tempSearch = this.opportunities[index].name;
				this.$store.commit("OPEN_OPPORTUNITY_DETAILS", this.opportunities[index]);
			} else if (this.$store.state.sidebarSelection == "Tasks") {
				let index = this.tasks.findIndex((x) => x.id == value);
				this.tempSearch = this.tasks[index].title;
				this.$store.commit("EDIT_TASK", this.tasks[index]);
			} else if (this.$store.state.sidebarSelection == "Appointments") {
				let index = this.appointments.findIndex((x) => x.id == value);
				this.tempSearch = this.appointments[index].title;
				this.$store.commit("EDIT_APPOINTMENT", this.appointments[index]);
			} else if (this.$store.state.sidebarSelection == "Calendar") {
				let index = this.tasks.findIndex((x) => x.id == value);
				if (index == -1) {
					index = this.appointments.findIndex((x) => x.id == value);
					this.tempSearch = this.appointments[index].title;
					this.$store.commit(
						"EDIT_APPOINTMENT",
						this.appointments[index]
					);
					if (this.appointments[index].contact) {
						setTimeout(() => {
							this.$store.commit(
								"UPDATE_QUERY_CONTACT",
								this.appointments[index].contact
							);
						}, 50);
					}
				} else {
					this.tempSearch = this.tasks[index].title;
					this.$store.commit("EDIT_TASK", this.tasks[index]);
				}
			}
		},

		async updateList(input) {
			try {
				let { data } = await this.$api.get(
					`/contacts/:instance/autocomplete?q=${input}`
				);
				this.contactSearchResult = data.map(({ name, id, email }) => ({
					label: name,
					value: id,
					email: email,
				}));
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			}
		},
		async updateEmailList(input) {
			try {
				let { data } = await this.$api.get(
					`/contacts/:instance/inbox/v3?search=${input}`
				);
				this.emailSearchResult = data.data;
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			}
		}
	},
};
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>

<style scoped>
.search-image {
	width: 18px;
	max-height: 18px;
}

.search-title {
	font-size: 15px;
	color: black;
}
</style>

<style lang="scss">
.ant-form-horizontal.search-horizontal {
	.ant-form-item-control-wrapper {
		flex: 1;
	}

	.ant-form-item {
		display: flex;
	}

	.ant-form-item-label {
		min-width: 100px;
	}
}

.ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
	margin-left: 32px;
}
</style>
