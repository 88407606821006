<template>
	<div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar task" style="overflow: scroll; background-color: #F7F5F9;">
		<BHLoading :show="loading" />
		<contactDetails />
		<opportunityDetails />
		<div>
			<a-tabs v-model="taskTab" size="large">
				<template slot="tabBarExtraContent" v-if="taskTab === 'active'">
					<a-select v-model="sortBy" style="width: 125px">
						<a-icon slot="suffixIcon" type="caret-down" />
						<a-select-option v-for="sort in sortType" :key="sort.value" :value="sort.value">
							{{ sort.label }}
						</a-select-option>
					</a-select>
				</template>
				<a-tab-pane key="active">
					<template slot="tab">
						<a-badge :count="activeTasks.length" showZero :offset="[20, 0]"
							:number-style="{ backgroundColor: '#f7941e' }">
							<div class="text-md">
								Active Tasks
							</div>
						</a-badge>
					</template>
					<div class="dF aC mb-3" style="gap: 20px;">
						<a-button v-for="activeType of activeTaskTypeList" :key="activeType.value"
							:type="activeTaskTab === activeType.value ? 'primary' : ''"
							@click="activeTaskTab = activeType.value" size="large">
							{{ activeType.label }}
							<a-badge :count="activeType.records.length" showZero :offset="[20, 0]"
								:number-style="{ backgroundColor: activeType.color }">
							</a-badge>
						</a-button>
					</div>
					<TasksTable :columns="columns" :records="activeTaskTabData[activeTaskTab].records"
						:type="activeTaskTabData[activeTaskTab].name" />
				</a-tab-pane>
				<a-tab-pane key="completed">
					<template slot="tab">
						<a-badge :count="completedTasks.length" showZero :offset="[20, 0]"
							:number-style="{ backgroundColor: '#52c41a' }">
							<div class="text-md">
								Completed Tasks
							</div>
						</a-badge>
					</template>
					<TasksTable :columns="columns" :records="completedTasks" type="completedTasks" />
				</a-tab-pane>
			</a-tabs>
		</div>
	</div>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'
import EmptyApp from 'bh-mod/components/common/EmptyApp'
import contactDetails from '@/components/common/contactDetails'
import opportunityDetails from '@/components/common/opportunityDetails'
import TasksTable from './tasksTable.vue'
import moment from 'moment'

export default {
	components: {
		BHLoading, EmptyApp, contactDetails, opportunityDetails, TasksTable
	},
	data() {
		return {
			taskTab: 'active',
			activeTaskTab: 'overdue',
			sortBy: 'dueDate',
			sortType: [
				{ label: 'Due Date', value: 'dueDate' },
				{ label: 'Priority Level', value: 'priority' }
			],
		}
	},
	computed: {
		loading() {
			return this.$store.state.contacts.loading
		},
		columns() {
			let list = [
				{
					title: 'Title',
					key: 'taskTitle',
					scopedSlots: { customRender: 'taskTitle' },
					sorter: (a, b) => a.title.localeCompare(b.title),
					width: '25%'
				},
				{
					title: 'Type',
					key: 'taskType',
					scopedSlots: { customRender: 'taskType' },
					sorter: (a, b) => a.type.localeCompare(b.type),
				},
				{
					title: 'Primary Contact',
					key: 'contact',
					scopedSlots: { customRender: 'contact' },
				},
				{
					title: 'Priority Level',
					key: 'priority',
					scopedSlots: { customRender: 'priority' },
					sorter: (a, b) => (a.priority || '').localeCompare((b.priority || '')),
				},
				{
					title: 'Due Date',
					key: 'dueDate',
					sorter: (a, b) => (a.dueDate || 0) - (b.dueDate || 0),
					scopedSlots: { customRender: 'dueDate' },
					defaultSortOrder: 'descend',
				},
				{
					title: 'Assign To',
					key: 'assignTo',
					scopedSlots: { customRender: 'assignTo' },
				},
				{
					title: '',
					key: 'others',
					scopedSlots: { customRender: 'others' },
				},
				{
					title: '',
					key: 'action',
					scopedSlots: { customRender: 'action' },
				},
			]

			return list
		},

		activeTaskTypeList() {
			const list = [
				{ label: 'Overdue Tasks', value: 'overdue', records: this.overdueTasks, name: 'overdueTasks', color: '#a90d2c' },
				{ label: 'Today\'s Tasks', value: 'today', records: this.todayTasks, name: 'todayTasks', color: '#f7941e' },
				{ label: 'Tomorrow\'s Tasks', value: 'tomorrow', records: this.tomorrowTasks, name: 'tomorrowTasks', color: '#17a2b8' },
				{ label: 'Future Tasks', value: 'future', records: this.futureTasks, name: 'futureTasks', color: '#20c997' },
				{ label: 'No Due Tasks', value: 'nodue', records: this.nodueTasks, name: 'nodueTasks', color: '#6610f2' },
				{ label: 'High Priority Tasks', value: 'high', records: this.highPriorityTasks, name: 'highPriorityTasks', color: '#a90d2c' },
				{ label: 'Medium Priority Tasks', value: 'medium', records: this.mediumPriorityTasks, name: 'mediumPriorityTasks', color: '#f7941e' },
				{ label: 'Low Priority Tasks', value: 'low', records: this.lowPriorityTasks, name: 'lowPriorityTasks', color: '#20c997' },
			]

			if (this.sortBy === 'dueDate') {
				return list.slice(0, 5)
			} else {
				return list.slice(5)
			}
		},

		activeTaskTabData() {
			return this.activeTaskTypeList.reduce((acc, curr) => {
				acc[curr.value] = curr
				return acc
			}, {})
		},

		taskTypes() {
			const taskTypes = this.$store.state.contacts?.allSettings?.app?.options?.taskType;
			return taskTypes?.length ? taskTypes : [
				{ name: 'To-do', value: 'todo' },
				{ name: 'Call', value: 'call' },
				{ name: 'Follow up', value: 'followup' }
			];
		},

		tasks() {
			return this.$store.state.contacts.tasks || []
		},
		activeTasks() {
			return this.tasks.filter(task => !task.completed)
		},
		completedTasks() {
			return this.tasks.filter(task => task.completed).sort((a, b) => (a.dueDate || 0) - (b.dueDate || 0))
		},

		overdueTasks() {
			const tasks = this.activeTasks
			const today = moment();

			return tasks.filter(task => task.dueDate && moment(task.dueDate).isBefore(today, 'day')).sort((a, b) => (a.dueDate || 0) - (b.dueDate || 0))
		},

		todayTasks() {
			const tasks = this.activeTasks
			const today = moment();

			return tasks.filter(task => task.dueDate && moment(task.dueDate).isSame(today, 'day')).sort((a, b) => (a.dueDate || 0) - (b.dueDate || 0))
		},

		tomorrowTasks() {
			const tasks = this.activeTasks
			const tomorrow = moment().add(1, 'day');

			return tasks.filter(task => task.dueDate && moment(task.dueDate).isSame(tomorrow, 'day')).sort((a, b) => (a.dueDate || 0) - (b.dueDate || 0))
		},

		futureTasks() {
			const tasks = this.activeTasks
			const today = moment();

			return tasks.filter(task => task.dueDate && moment(task.dueDate).isAfter(today, 'day')).sort((a, b) => (a.dueDate || 0) - (b.dueDate || 0))
		},

		nodueTasks() {
			return this.activeTasks.filter(task => !task.dueDate)
		},

		highPriorityTasks() {
			return this.activeTasks.filter(task => task.priority === 'high')
		},

		mediumPriorityTasks() {
			return this.activeTasks.filter(task => task.priority === 'medium')
		},

		lowPriorityTasks() {
			return this.activeTasks.filter(task => task.priority === 'low')
		},

		contacts() {
			return Object.values(this.$store.state.contacts.allContacts)
		},

		externalContacts() {
			return this.$store.state.contacts.externalContacts
		},

		opportunities() {
			return this.$store.state.contacts.opportunities
		},
	},
	watch: {
		sortBy(value) {
			if (value === 'dueDate') {
				this.activeTaskTab = 'overdue'
			} else {
				this.activeTaskTab = 'high'
			}
		}
	},
	created() {
		this.$store.commit('SIDEBAR_SELECTION', { value: 'Tasks' })
		this.$store.commit('CLOSE_CONTACT_DETAILS');
		this.$store.commit('CLOSE_OPPORTUNITY_DETAILS');
	},
	methods: {

	},
}

</script>

<style lang="scss">
.task .ant-card-body {
	padding: 10px;
}


.task .ant-tabs-nav .ant-tabs-tab:last-child {
	margin-right: 20px !important;
}
</style>
