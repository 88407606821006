<template>
    <div>
        <a-table :rowKey="(e) => e.createdAt.toString()" :pagination="false" class="white-table" :columns="columns" :data-source="templateList">

            <div slot="createdBy" slot-scope="obj" class="dF aC">
                <a-avatar v-if="obj.createdBy.avatar && obj.createdBy.avatar !== ''" class="mr-3" :src="obj.createdBy.avatar" />
                <a-avatar v-else>{{obj.createdBy.firstName[0].toUpperCase() + obj.createdBy.lastName[0].toUpperCase()}}</a-avatar>

            </div>
            <div slot="createdAt" slot-scope="obj">
                <div style="color: #9EA0A5;">{{convertDate(obj.createdAt)}}</div>
            </div>
            <div slot="action" slot-scope="obj" class="dF jE">
                <i @click="previewEmailTemplate(obj)" class="fe fe-eye actionIcons" style="font-size: 16px;" />
                <svg @click="editEmailTemplate(obj)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 ml-3 actionIcons"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                <i @click="deleteEmailTemplate(obj)" class="fe fe-trash-2 ml-3 actionIcons" style="font-size: 16px; "/>
            </div>
        </a-table>

		<div class="dF aC mt-4" style="gap: 50px; color:var(--primary);">
			<div @click="addNewTemplate" class="dF aC cursor-pointer">
				<a-icon style="font-size:25px" type="plus-circle" class="mr-2" />
				<div>Add New Template</div>
			</div>

			<div @click="exportModal.visible = true" class="dF aC cursor-pointer">
				<a-icon style="font-size:25px" type="upload" class="mr-2" />
				<span>Export Templates</span>
			</div>
		</div>
        <!-- <div @click="$emit('addNewReason')" class="dF" style="color:var(--orange); cursor:pointer">
            <a-icon style="font-size:25px" type="plus-circle" class="mr-3"/>
            <p>Add Reason</p>
        </div> -->

        <a-modal :width="'1000px'" centered :footer="null" :visible="showEmailModal" @cancel="cancelEmail">
            <template slot="title">
                <div class="dF aC">
                    <div style="color:#000">Subject:</div>&nbsp;<div class="header-subject" style="color:#3A3B3F" v-html="newTemplate.subject" />
                </div>
            </template>
            <div style="padding: 25px">
                <!-- <p class="mb-5">{{newTemplate.greetings}}</p> -->
                <div style="color:#777E8E" v-html="newTemplate.message" class="mb-5" :style="`font-family: ${account.fontFamily}`"></div>

                <div v-if="account.template == '1'" style="max-width:500px" :style="`font-family: ${account.fontFamily}`">
					<div class="dF aS jS" style="gap: 2em;">
						<div style="width: 40%;">
							<div v-if="account.logo && account.logo != ''" class="w-full dF jC">
								<img :src="account.logo" style="width: 80%; object-fit:contain" />
							</div>
							<div class="dF jC w-full mt-4">
								<div class="aC" style="display: grid; grid-template-columns: repeat(5, minmax(0px, 1fr)); gap: 7px;">
									<div v-for="(social, socialI) in getSocials(account.socials)" :key="social.id">
										<div style="width: 20px; text-align: center;">
											<img style="object-fit:cover; height:18px; width:18px"
												src="https://bildhive.nyc3.digitaloceanspaces.com/v3/facebook_icon_1f824839f3.png"
												v-if="social.type == 'Facebook'" />
											<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/linkedin_icon_6fd5b99eca.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'LinkedIn'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/twitter_logo_email_2343518957.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'Twitter'" />
											<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/instagram_icon_8eb7fba8c2.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'Instagram'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/youtube_c77a285d7a.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'YouTube'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/google_plus_2082ce542c.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'Google+'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/pinterest_30db360395.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'Pinterest'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/wechat_c566d59358.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'WeChat'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/quora_2fb387fc08.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'Quora'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/foursquare_54e2bbc3e4.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'Foursquare'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/klout_d89258e142.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'Klout'" />
										</div>
									</div>
								</div>
							</div>
							<div v-if="account.appointment" class="mt-4">
								<a-button style="pointer-events: none;"
									:style="`color:${account.appointmentColor}; background-color: ${account.appointmentBgColor}`">{{
										account.appointmentText }}</a-button>
							</div>
						</div>
						<div style="width: 60%;">
							<div style="font-weight: bold;">{{ account.name.toUpperCase() }}</div>
							<div><small>{{ account.jobTitle }}</small></div>
							<div class="mb-3"><small>{{ account.company }}</small></div>
							<div v-if="account.email && account.email != ''" class="dF aS">
								<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Email:</small></span>
								<div class="f1"><small>{{ account.email }}</small></div>
							</div>
							<div v-if="account.address && account.address != ''" class="dF aS">
								<span style="width: 65px;"><small
										:style="`color:${account.fieldColor};`">Address:</small></span>
								<div class="f1"><small>{{ account.address }}</small></div>
							</div>
							<div class="dF aS mb-3" v-if="account.website && account.website != ''">
								<span style="width: 65px;"><small
										:style="`color:${account.fieldColor};`">Website:</small></span>
								<div class="f1"><small>{{ account.website }}</small></div>
							</div>
							<div class="dF aS" v-if="account.phone.value">
								<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">{{ account.phone.type }}:</small></span>
								<div class="f1"><small>{{ account.phone.value }}</small></div>
							</div>
							<div v-if="account.officePhone" class="dF aS">
								<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Office:</small></span>
								<div class="f1"><small>{{ account.officePhone }}</small></div>
							</div>
							<div v-if="account.fax" class="dF aS">
								<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Fax:</small></span>
								<div class="f1"><small>{{ account.fax }}</small></div>
							</div>
						</div>
					</div>
				</div>

				<div v-else-if="account.template == '2'" style="max-width:500px" :style="`font-family: ${account.fontFamily}`">
					<div class="w-full dF jC">
						<img v-if="account.logo && account.logo != ''" :src="account.logo"
							style="width: 40%; object-fit:contain;" />
					</div>
					<div class="mt-3">
						<div class="text-center" style="font-weight: bold;">{{ account.name.toUpperCase() }}</div>
						<div class="text-center"><small>{{ account.jobTitle }}</small></div>
						<div class="text-center"><small>{{ account.company }}</small></div>
						<div v-if="account.appointment" class="mt-3 text-center">
							<a-button style="pointer-events: none;"
								:style="`color:${account.appointmentColor}; background-color: ${account.appointmentBgColor}`">{{
									account.appointmentText }}</a-button>
						</div>
					</div>
					<div class="mt-3" style="display:flex; gap:20px">
						<div style="width: 65%;">
							<div v-if="account.email && account.email != ''" class="dF aS">
								<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Email:</small></span>
								<div class="f1"><small>{{ account.email }}</small></div>
							</div>
							<div v-if="account.address && account.address != ''" class="dF aS">
								<span style="width: 65px;"><small
										:style="`color:${account.fieldColor};`">Address:</small></span>
								<div class="f1"><small>{{ account.address }}</small></div>
							</div>
							<div class="dF aS mb-3" v-if="account.website && account.website != ''">
								<span style="width: 65px;"><small
										:style="`color:${account.fieldColor};`">Website:</small></span>
								<div class="f1"><small>{{ account.website }}</small></div>
							</div>
							<div class="dF aS" v-if="account.phone.value">
								<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">{{ account.phone.type
										}}:</small></span>
								<div class="f1"><small>{{ account.phone.value }}</small></div>
							</div>
							<div v-if="account.officePhone" class="dF aS">
								<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Office:</small></span>
								<div class="f1"><small>{{ account.officePhone }}</small></div>
							</div>
							<div v-if="account.fax" class="dF aS">
								<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Fax:</small></span>
								<div class="f1"><small>{{ account.fax }}</small></div>
							</div>
						</div>
						<div class="text-right" style="width: 35%;">
							<div class="dF jE">
								<div class="grid w-full"
									style="grid-template-columns: repeat(4, minmax(0, 1fr)); direction: rtl; gap: 7px;">
									<div v-for="(social, socialI) in getSocials(account.socials)" :key="social.id">
										<div style="width: 20px; text-align: center;">
											<img style="object-fit:cover; height:18px; width:18px"
												src="https://bildhive.nyc3.digitaloceanspaces.com/v3/facebook_icon_1f824839f3.png"
												v-if="social.type == 'Facebook'" />
											<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/linkedin_icon_6fd5b99eca.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'LinkedIn'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/twitter_logo_email_2343518957.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'Twitter'" />
											<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/instagram_icon_8eb7fba8c2.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'Instagram'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/youtube_c77a285d7a.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'YouTube'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/google_plus_2082ce542c.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'Google+'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/pinterest_30db360395.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'Pinterest'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/wechat_c566d59358.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'WeChat'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/quora_2fb387fc08.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'Quora'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/foursquare_54e2bbc3e4.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'Foursquare'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/klout_d89258e142.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'Klout'" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div v-else-if="account.template == '3'" style="max-width:500px" :style="`font-family: ${account.fontFamily}`">
					<div class="dF">
						<div class="relative" style="width: 40%">
							<img style="object-fit:contain; width: 100%;" v-if="account.logo && account.logo != ''"
								:src="account.logo" />
							<div class="mt-3 ">
								<div style="text-align: center; font-weight: bold;">{{ account.name.toUpperCase() }}</div>
								<div style="text-align: center;"><small>{{ account.jobTitle }}</small></div>
								<div style="text-align: center;"><small>{{ account.company }}</small></div>
								<div v-if="account.appointment" class="mt-3">
									<a-button style="pointer-events: none;"
										:style="`color:${account.appointmentColor}; background-color: ${account.appointmentBgColor}`">{{
											account.appointmentText }}</a-button>
								</div>
							</div>
						</div>
						<div class="ml-5 relative" style="width: 60%;">
							<div v-if="account.email && account.email != ''" class="dF aS">
								<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Email:</small></span>
								<div class="f1"><small>{{ account.email }}</small></div>
							</div>
							<div v-if="account.address && account.address != ''" class="dF aS">
								<span style="width: 65px;"><small
										:style="`color:${account.fieldColor};`">Address:</small></span>
								<div class="f1"><small>{{ account.address }}</small></div>
							</div>
							<div class="dF aS mb-3" v-if="account.website && account.website != ''">
								<span style="width: 65px;"><small
										:style="`color:${account.fieldColor};`">Website:</small></span>
								<div class="f1"><small>{{ account.website }}</small></div>
							</div>
							<div class="dF aS" v-if="account.phone.value">
								<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">{{ account.phone.type
										}}:</small></span>
								<div class="f1"><small>{{ account.phone.value }}</small></div>
							</div>
							<div v-if="account.officePhone" class="dF aS">
								<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Office:</small></span>
								<div class="f1"><small>{{ account.officePhone }}</small></div>
							</div>
							<div v-if="account.fax" class="dF aS">
								<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Fax:</small></span>
								<div class="f1"><small>{{ account.fax }}</small></div>
							</div>

							<div class="dF jE mt-3">
								<div class="dF aC" style="flex-wrap: wrap; gap: 7px;">
									<div v-for="(social, socialI) in getSocials(account.socials)" :key="social.id">
										<div style="width: 20px; text-align: center;">
											<img style="object-fit:cover; height:18px; width:18px"
												src="https://bildhive.nyc3.digitaloceanspaces.com/v3/facebook_icon_1f824839f3.png"
												v-if="social.type == 'Facebook'" />
											<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/linkedin_icon_6fd5b99eca.png"
												style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'LinkedIn'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/twitter_logo_email_2343518957.png"
												style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'Twitter'" />
											<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/instagram_icon_8eb7fba8c2.png"
												style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'Instagram'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/youtube_c77a285d7a.png"
												style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'YouTube'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/google_plus_2082ce542c.png"
												style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'Google+'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/pinterest_30db360395.png"
												style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'Pinterest'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/wechat_c566d59358.png"
												style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'WeChat'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/quora_2fb387fc08.png"
												style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'Quora'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/foursquare_54e2bbc3e4.png"
												style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'Foursquare'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/klout_d89258e142.png"
												style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'Klout'" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div v-else-if="account.template == '4'" style="max-width:600px" :style="`font-family: ${account.fontFamily}`">
					<div class="dF">
						<div class="mr-4" style="width: 65%;">
							<div class="mb-3">
								<div style="font-weight: bold;">{{ account.name.toUpperCase() }}</div>
								<div><small>{{ account.jobTitle }}</small></div>
								<div><small>{{ account.company }}</small></div>
							</div>
							<div v-if="account.email && account.email != ''" class="dF aS">
								<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Email:</small></span>
								<div class="f1"><small>{{ account.email }}</small></div>
							</div>
							<div v-if="account.address && account.address != ''" class="dF aS">
								<span style="width: 65px;"><small
										:style="`color:${account.fieldColor};`">Address:</small></span>
								<div class="f1"><small>{{ account.address }}</small></div>
							</div>
							<div class="dF aS mb-3" v-if="account.website && account.website != ''">
								<span style="width: 65px;"><small
										:style="`color:${account.fieldColor};`">Website:</small></span>
								<div class="f1"><small>{{ account.website }}</small></div>
							</div>
							<div class="dF aS" v-if="account.phone.value">
								<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">{{ account.phone.type
										}}:</small></span>
								<div class="f1"><small>{{ account.phone.value }}</small></div>
							</div>
							<div v-if="account.officePhone" class="dF aS">
								<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Office:</small></span>
								<div class="f1"><small>{{ account.officePhone }}</small></div>
							</div>
							<div v-if="account.fax" class="dF aS">
								<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Fax:</small></span>
								<div class="f1"><small>{{ account.fax }}</small></div>
							</div>
							<div v-if="account.appointment" class="mt-4">
								<a-button style="pointer-events: none;"
									:style="`color:${account.appointmentColor}; background-color: ${account.appointmentBgColor}`">{{
										account.appointmentText }}</a-button>
							</div>
						</div>
						<div class="dF jE relative" style="width: 35%;">
							<div class="relative w-full">
								<div class="dF jC">
									<img style="object-fit:contain; width: 75%;"
										v-if="account.logo && account.logo != ''" :src="account.logo" />
								</div>
								<div class="mt-4 w-full dF jC">
									<div class="grid" style="grid-template-columns: repeat(4, minmax(0, 1fr)); gap: 0.5em;">
										<div v-for="(social, socialI) in getSocials(account.socials)" :key="social.id">
											<div style="width: 20px; text-align: center;">
												<img style="object-fit:cover; height:18px; width:18px"
													src="https://bildhive.nyc3.digitaloceanspaces.com/v3/facebook_icon_1f824839f3.png"
													v-if="social.type == 'Facebook'" />
												<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/linkedin_icon_6fd5b99eca.png"
													style="object-fit:cover; height:18px; width:18px"
													v-if="social.type == 'LinkedIn'" />
												<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/twitter_logo_email_2343518957.png"
													style="object-fit:cover; height:18px; width:18px"
													v-if="social.type == 'Twitter'" />
												<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/instagram_icon_8eb7fba8c2.png"
													style="object-fit:cover; height:18px; width:18px"
													v-if="social.type == 'Instagram'" />
												<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/youtube_c77a285d7a.png"
													style="object-fit:cover; height:18px; width:18px"
													v-if="social.type == 'YouTube'" />
												<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/google_plus_2082ce542c.png"
													style="object-fit:cover; height:18px; width:18px"
													v-if="social.type == 'Google+'" />
												<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/pinterest_30db360395.png"
													style="object-fit:cover; height:18px; width:18px"
													v-if="social.type == 'Pinterest'" />
												<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/wechat_c566d59358.png"
													style="object-fit:cover; height:18px; width:18px"
													v-if="social.type == 'WeChat'" />
												<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/quora_2fb387fc08.png"
													style="object-fit:cover; height:18px; width:18px"
													v-if="social.type == 'Quora'" />
												<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/foursquare_54e2bbc3e4.png"
													style="object-fit:cover; height:18px; width:18px"
													v-if="social.type == 'Foursquare'" />
												<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/klout_d89258e142.png"
													style="object-fit:cover; height:18px; width:18px"
													v-if="social.type == 'Klout'" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div v-else-if="account.template == '5'" style="max-width:600px" :style="`font-family: ${account.fontFamily}`">
					<div class="dF jE relative" style="width: 35%;">
						<div class="relative w-full">
							<div class="dF jS">
								<img style="object-fit:contain; width: 75%;"
									v-if="account.logo && account.logo != ''" :src="account.logo" />
							</div>
							<div class="mt-4 w-full dF jS">
								<div class="dF aC" style="gap: 0.5em;">
									<div v-for="(social, socialI) in getSocials(account.socials)" :key="social.id">
										<div style="width: 20px; text-align: center;">
											<img style="object-fit:cover; height:18px; width:18px"
												src="https://bildhive.nyc3.digitaloceanspaces.com/v3/facebook_icon_1f824839f3.png"
												v-if="social.type == 'Facebook'" />
											<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/linkedin_icon_6fd5b99eca.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'LinkedIn'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/twitter_logo_email_2343518957.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'Twitter'" />
											<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/instagram_icon_8eb7fba8c2.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'Instagram'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/youtube_c77a285d7a.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'YouTube'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/google_plus_2082ce542c.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'Google+'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/pinterest_30db360395.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'Pinterest'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/wechat_c566d59358.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'WeChat'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/quora_2fb387fc08.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'Quora'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/foursquare_54e2bbc3e4.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'Foursquare'" />
											<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/klout_d89258e142.png"
												style="object-fit:cover; height:18px; width:18px"
												v-if="social.type == 'Klout'" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="mt-3 mr-4" style="width: 65%;">
						<div class="mb-3">
							<div style="font-weight: bold;">{{ account.name.toUpperCase() }}</div>
							<div><small>{{ account.jobTitle }}</small></div>
							<div><small>{{ account.company }}</small></div>
						</div>
						<div v-if="account.email && account.email != ''" class="dF aS">
							<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Email:</small></span>
							<div class="f1"><small>{{ account.email }}</small></div>
						</div>
						<div v-if="account.address && account.address != ''" class="dF aS">
							<span style="width: 65px;"><small
									:style="`color:${account.fieldColor};`">Address:</small></span>
							<div class="f1"><small>{{ account.address }}</small></div>
						</div>
						<div class="dF aS mb-3" v-if="account.website && account.website != ''">
							<span style="width: 65px;"><small
									:style="`color:${account.fieldColor};`">Website:</small></span>
							<div class="f1"><small>{{ account.website }}</small></div>
						</div>
						<div class="dF aS" v-if="account.phone.value">
							<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">{{ account.phone.type
									}}:</small></span>
							<div class="f1"><small>{{ account.phone.value }}</small></div>
						</div>
						<div v-if="account.officePhone" class="dF aS">
							<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Office:</small></span>
							<div class="f1"><small>{{ account.officePhone }}</small></div>
						</div>
						<div v-if="account.fax" class="dF aS">
							<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Fax:</small></span>
							<div class="f1"><small>{{ account.fax }}</small></div>
						</div>
						<div v-if="account.appointment" class="mt-4">
							<a-button style="pointer-events: none;"
								:style="`color:${account.appointmentColor}; background-color: ${account.appointmentBgColor}`">{{
									account.appointmentText }}</a-button>
						</div>
					</div>
				</div>
            </div>
			<template v-if="newTemplate && newTemplate.attachments && newTemplate.attachments.length">
				<div class="mt-5 attachments">
					<a-icon class="mr-2" type="paper-clip" />{{newTemplate.attachments.length}} {{newTemplate.attachments.length > 1 ? 'attachments' : 'attachment'}}
				</div>
				<div class="mt-3" style="display: grid; grid-template-columns: repeat(3, minmax(0, 1fr)); gap:2em;">
					<div v-for="(file,fileI) in newTemplate.attachments" :key="file+fileI" class="dF aC jSB px-2 py-1" style="background-color: #F7F5F9; border: 1px solid #E6EAF0; border-radius: 5px;">
						<div style="overflow: hidden; white-space: nowrap;">
							<div>{{file.name}}</div>
							<div style="color: #A3A7AC;">{{fileSize(file.size)}}</div>
						</div>
					</div>
				</div>
			</template>
            <div class="dF aC jSB mt-5">
				<a-button @click="sendEmail" size="large" class="mr-3 cancel-button">SEND TEST</a-button>
                <a-button @click="cancelEmail" class="cancel-button" size="large">CLOSE</a-button>
            </div>
        </a-modal>

        <a-modal :width="'1000px'" centered :header="null" :footer="null" :visible="showEditModal" @cancel="cancelEdit">
            <BHLoading :show="loading" />
			<div>
				<h5 class="mb-4">Edit Email Template</h5>
				<a-form-model ref="newEmail" :model="newTemplate">
					<a-row :gutter="16">
						<a-col :span="24">
							<a-form-model-item label="Template Name" required prop="name" :rules="req('Please enter a name for this email template')">
								<a-input size="large" v-model="newTemplate.name" />
							</a-form-model-item>
						</a-col>

						<a-col :span="24">
							<a-form-model-item label="Subject" required prop="subject" :rules="req('Please enter a subject for this email')">
								<a-input size="large" v-model="newTemplate.subject" />
							</a-form-model-item>
						</a-col>

						<a-col :span="24">
							<a-form-model-item required>
								<slot name="label">
									Message<span style="color: var(--danger);">*</span> - <span style="color: var(--primary); font-weight: bold;"><i>Type @ to view list of variables</i></span>
								</slot>
								<template v-if="showEditModal">
									<Mentions :message="newTemplate.message" :template="false" :editTemplate="newTemplate" @close="cancelEdit" @previewEmail="previewEmail" :okButton="'SAVE'" :cancelButton="'CANCEL'" class="edit__template task__comment" />
								</template>
							</a-form-model-item>
						</a-col>
					</a-row>
				</a-form-model>
			</div>
        </a-modal>

		<a-modal title="Export to Existing Project" :visible="exportModal.visible" okText="EXPORT"
			@cancel="exportModal.visible = false; exportModal.indicesToClone = []" @ok="exportTemplates" centered>
			<a-row :gutter="16">
				<a-form-model ref="exportModal" :model="exportModal">
					<a-col :span="24">
						<a-form-model-item prop="instance" label="Project" required
							:rules="req('Please select the instance')">
							<a-select show-search :filter-option="filterOption" v-model="exportModal.instance" placeholder="Select a Project" size="large">
								<a-select-option v-for="inst in instances" :value="inst.id" :key="inst.id">{{ inst.name
								}}</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :span="24" class="mt-2" style="position: relative;">
						<a-tooltip overlayClassName="change-tooltip-color"
							class="absolute" style="left: 172px; top: -3px; z-index: 2;">
							<template slot="title">
								Note: All templates will be exported if no template is selected.
							</template>
							<a-icon type="question-circle"
								style="line-height: 22.5px; margin-top: 5px; color: var(--orange);" />
						</a-tooltip>
						<a-form-model-item prop="indicesToClone" label="Export Specific Template(s)">
							<a-select mode="multiple" :filter-option="filterOption" v-model="exportModal.indicesToClone" size="large">
								<a-select-option v-for="(temp,tempI) in templateList" :value="tempI" :key="temp+tempI">
									{{ temp.name }}
								</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
				</a-form-model>
			</a-row>
		</a-modal>
    </div>
</template>

<script>
import Mentions from '@/components/common/Mentions'
import BHLoading from 'bh-mod/components/common/Loading'
export default {
    components:{ Mentions, BHLoading},
    computed:{
		dateFormat(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
        templateList(){
            return this.$store.state.contacts?.allSettings?.app?.options?.templateList || [];
        },
        settings(){
            return this.$store.state.contacts.allSettings.app
        },
        account() {
            return this.$store.state.contacts.allSettings.userApp.options.account
        },
		instance() {
			return this.$store.state.instance
		},
		instances() {
			return this.$store.state.instances.filter(x => x.id != this.instance.id && x.name).sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
		},
		vendor(){
            let vendors = Object.values(this.$store.state.contacts.vendors)
			return vendors.find(v => v.active)
        },
		nylasAccount() {
            return this.$store.state.contacts.nylasAccount || {}
        },
		user(){
            return this.$store.state.user.user
        }
    },
    data(){
        return{
            loading:false,
            columns:[
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: 'Date Created',
                    key: 'createdAt',
                    scopedSlots: { customRender: 'createdAt' },
                },
                {
                    title: 'Modified By',
                    key: 'createdBy',
                    scopedSlots: { customRender: 'createdBy' },
                },
                {
                    title: 'Action',
                    key: 'action',
                    scopedSlots: { customRender: 'action' },
                    align: 'right',
                },
            ],
            showEmailModal:false,
            showEditModal:false,
            newTemplate:{
				attachments:[],
                subject:'',
                message:'',
                name:''
            },
			exportModal: {
				visible: false,
				instance: null,
				indicesToClone: []
			},
        }
    },
    methods:{
		sendEmail(){
            console.log('preview email', this.newTemplate)
            let socials = this.account.socials.map(x => x = x.type)
            let mjml = ''
            if (this.account.template == '1') {
                mjml = `
					<mjml>
						<mj-body>
							<mj-section>
								<mj-column>
									<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
										<div>${this.newTemplate.message}</div>
									</mj-text>
								</mj-column>
							</mj-section>
				`
				mjml += `
						<mj-section>
							<mj-column width="40%">
								${this.account.logo && this.account.logo != '' ? `
								<mj-image src="${this.account.logo}"></mj-image>`:''}
								<mj-column width="100%">
									<mj-social icon-padding="2px">
										${socials.includes('Facebook') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/facebook_icon_1f824839f3.png" href="${this.getLink('Facebook')}"></mj-social-element>`:''}
										${socials.includes('Instagram') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/instagram_icon_8eb7fba8c2.png" href="${this.getLink('Instagram')}"></mj-social-element>` : ''}
										${socials.includes('Twitter') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/twitter_logo_email_2343518957.png" href="${this.getLink('Twitter')}"></mj-social-element>` : ''}
										${socials.includes('LinkedIn') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/linkedin_icon_6fd5b99eca.png" href="${this.getLink('LinkedIn')}"></mj-social-element>` : ''}
										${socials.includes('YouTube') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/youtube_c77a285d7a.png" href="${this.getLink('YouTube')}"></mj-social-element>`:''}
										${socials.includes('Google+') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/google_plus_2082ce542c.png" href="${this.getLink('Google+')}"></mj-social-element>` : ''}
										${socials.includes('Pinterest') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/pinterest_30db360395.png" href="${this.getLink('Pinterest')}"></mj-social-element>` : ''}
										${socials.includes('WeChat') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/wechat_c566d59358.png" href="${this.getLink('WeChat')}"></mj-social-element>` : ''}
										${socials.includes('Quora') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/quora_2fb387fc08.png" href="${this.getLink('Quora')}"></mj-social-element>`:''}
										${socials.includes('Foursquare') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/foursquare_54e2bbc3e4.png" href="${this.getLink('Foursquare')}"></mj-social-element>` : ''}
										${socials.includes('Klout') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/klout_d89258e142.png" href="${this.getLink('Klout')}"></mj-social-element>` : ''}
									</mj-social>
								</mj-column>
								${this.account.appointment && this.settings && this.settings.options && this.settings.options.appointments && this.settings.options.appointments.widgetUrl && this.vendor && this.vendor.active ? `<mj-button background-color="${this.account.appointmentBgColor}" color="${this.account.appointmentColor}" href="${this.settings.options.appointments.widgetUrl}?userId=${this.vendor.user.id}">${this.account.appointmentText}</mj-button>` : ''}
							</mj-column>
							<mj-column width="60%">
								<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
									<strong style="font-size:17px">${this.account.name.toUpperCase()}</strong>
									${this.account.jobTitle && this.account.jobTitle != '' ? `<div>${this.account.jobTitle}</div>`:''}
									${this.account.company && this.account.company != '' ? `<div>${this.account.company}</div>`:''}
								</mj-text>
								<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
									${this.account && (this.account.email != '' || this.account.address != '' || this.account.website != '') ?
										`
										<table style="width:100%">
											${this.account.email != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Email: </td><td>${this.account.email}</td></tr>` : ''}
											${this.account.address != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Address: </td><td>${this.account.address}</td></tr>` : ''}
											${this.account.website != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Website: </td><td>${this.account.website}</td></tr>` : ''}
										</table>` : ''
									}
								</mj-text>
								<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
									${this.account && ((this.account.phone && this.account.phone.value != '') || this.account.officePhone != '' || this.account.fax != '') ?
										`
										<table style="width:100%">
											${this.account.phone.value != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">${this.account.phone.type}: </td><td>${this.account.phone.value}</td></tr>` : ''}
											${this.account.officePhone != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Office: </td><td>${this.account.officePhone}</td></tr>` : ''}
											${this.account.fax != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Fax: </td><td>${this.account.fax}</td></tr>` : ''}
										</table>` : ''
									}
								</mj-text>
							</mj-column>
						</mj-section>
					</mj-body>
				</mjml>
				`
            } else if (this.account.template == '2') {
                mjml = `
					<mjml>
						<mj-body>
							<mj-section>
								<mj-column>
									<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
										<div>${this.newTemplate.message}</div>
									</mj-text>
								</mj-column>
							</mj-section>
				`
				mjml += `
						<mj-section padding-bottom="0px">
							<mj-column width="100%">
								<mj-column width="40%" align="center">
									${this.account.logo && this.account.logo != '' ? `<mj-image src="${this.account.logo}"></mj-image>`:''}
								</mj-column>
								<mj-text align="center" line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
									<strong>${this.account.name.toUpperCase()}</strong>
									${this.account.jobTitle && this.account.jobTitle != '' ? `<div>${this.account.jobTitle}</div>`:''}
									${this.account.company && this.account.company != '' ? `<div>${this.account.company}</div>`:''}
								</mj-text>
								${this.account.appointment && this.settings && this.settings.options && this.settings.options.appointments && this.settings.options.appointments.widgetUrl && this.vendor && this.vendor.active ? `<mj-button background-color="${this.account.appointmentBgColor}" color="${this.account.appointmentColor}" href="${this.settings.options.appointments.widgetUrl}?userId=${this.vendor.user.id}">${this.account.appointmentText}</mj-button>` : ''}
							</mj-column>
						</mj-section>
						<mj-section padding-top="0px">
							<mj-column width="65%">
								<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
									${this.account && (this.account.email != '' || this.account.address != '' || this.account.website != '') ?
										`
										<table style="width:100%">
											${this.account.email != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Email: </td><td>${this.account.email}</td></tr>` : ''}
											${this.account.address != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Address: </td><td>${this.account.address}</td></tr>` : ''}
											${this.account.website != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Website: </td><td>${this.account.website}</td></tr>` : ''}
										</table>` : ''
									}
								</mj-text>
								<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
									${this.account && ((this.account.phone && this.account.phone.value != '') || this.account.officePhone != '' || this.account.fax != '') ?
										`
										<table style="width:100%">
											${this.account.phone.value != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">${this.account.phone.type}: </td><td>${this.account.phone.value}</td></tr>` : ''}
											${this.account.officePhone != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Office: </td><td>${this.account.officePhone}</td></tr>` : ''}
											${this.account.fax != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Fax: </td><td>${this.account.fax}</td></tr>` : ''}
										</table>` : ''
									}
								</mj-text>
							</mj-column>
							<mj-column width="35%">
								<mj-column width="100%">
									<mj-social icon-padding="2px">
										${socials.includes('Facebook') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/facebook_icon_1f824839f3.png" href="${this.getLink('Facebook')}"></mj-social-element>`:''}
										${socials.includes('Instagram') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/instagram_icon_8eb7fba8c2.png" href="${this.getLink('Instagram')}"></mj-social-element>` : ''}
										${socials.includes('Twitter') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/twitter_logo_email_2343518957.png" href="${this.getLink('Twitter')}"></mj-social-element>` : ''}
										${socials.includes('LinkedIn') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/linkedin_icon_6fd5b99eca.png" href="${this.getLink('LinkedIn')}"></mj-social-element>` : ''}
										${socials.includes('YouTube') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/youtube_c77a285d7a.png" href="${this.getLink('YouTube')}"></mj-social-element>`:''}
										${socials.includes('Google+') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/google_plus_2082ce542c.png" href="${this.getLink('Google+')}"></mj-social-element>` : ''}
										${socials.includes('Pinterest') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/pinterest_30db360395.png" href="${this.getLink('Pinterest')}"></mj-social-element>` : ''}
										${socials.includes('WeChat') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/wechat_c566d59358.png" href="${this.getLink('WeChat')}"></mj-social-element>` : ''}
										${socials.includes('Quora') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/quora_2fb387fc08.png" href="${this.getLink('Quora')}"></mj-social-element>`:''}
										${socials.includes('Foursquare') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/foursquare_54e2bbc3e4.png" href="${this.getLink('Foursquare')}"></mj-social-element>` : ''}
										${socials.includes('Klout') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/klout_d89258e142.png" href="${this.getLink('Klout')}"></mj-social-element>` : ''}
									</mj-social>
								</mj-column>
							</mj-column>
						</mj-section>
					</mj-body>
				</mjml>
				`
			} else if (this.account.template == '3') {
                mjml = `
					<mjml>
						<mj-body>
							<mj-section>
								<mj-column>
									<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
										<div>${this.newTemplate.message}</div>
									</mj-text>
								</mj-column>
							</mj-section>
				`
				mjml += `
						<mj-section>
							<mj-column width="40%">
								${this.account.logo && this.account.logo != '' ? `<mj-image src="${this.account.logo}"></mj-image>`:''}
								<mj-text align="center" line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
									<strong>${this.account.name.toUpperCase()}</strong>
									${this.account.jobTitle && this.account.jobTitle != '' ? `<div>${this.account.jobTitle}</div>`:''}
									${this.account.company && this.account.company != '' ? `<div>${this.account.company}</div>`:''}
								</mj-text>
								${this.account.appointment && this.settings && this.settings.options && this.settings.options.appointments && this.settings.options.appointments.widgetUrl && this.vendor && this.vendor.active ? `<mj-button background-color="${this.account.appointmentBgColor}" color="${this.account.appointmentColor}" href="${this.settings.options.appointments.widgetUrl}?userId=${this.vendor.user.id}">${this.account.appointmentText}</mj-button>` : ''}
							</mj-column>
							<mj-column width="60%">
								<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
									${this.account && (this.account.email != '' || this.account.address != '' || this.account.website != '') ?
										`
										<table style="width:100%">
											${this.account.email != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Email: </td><td>${this.account.email}</td></tr>` : ''}
											${this.account.address != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Address: </td><td>${this.account.address}</td></tr>` : ''}
											${this.account.website != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Website: </td><td>${this.account.website}</td></tr>` : ''}
										</table>` : ''
									}
								</mj-text>
								<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
									${this.account && ((this.account.phone && this.account.phone.value != '') || this.account.officePhone != '' || this.account.fax != '') ?
										`
										<table style="width:100%">
											${this.account.phone.value != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">${this.account.phone.type}: </td><td>${this.account.phone.value}</td></tr>` : ''}
											${this.account.officePhone != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Office: </td><td>${this.account.officePhone}</td></tr>` : ''}
											${this.account.fax != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Fax: </td><td>${this.account.fax}</td></tr>` : ''}
										</table>` : ''
									}
								</mj-text>
								<mj-social icon-padding="2px" align="right">
									${socials.includes('Facebook') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/facebook_icon_1f824839f3.png" href="${this.getLink('Facebook')}"></mj-social-element>`:''}
									${socials.includes('Instagram') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/instagram_icon_8eb7fba8c2.png" href="${this.getLink('Instagram')}"></mj-social-element>` : ''}
									${socials.includes('Twitter') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/twitter_logo_email_2343518957.png" href="${this.getLink('Twitter')}"></mj-social-element>` : ''}
									${socials.includes('LinkedIn') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/linkedin_icon_6fd5b99eca.png" href="${this.getLink('LinkedIn')}"></mj-social-element>` : ''}
									${socials.includes('YouTube') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/youtube_c77a285d7a.png" href="${this.getLink('YouTube')}"></mj-social-element>`:''}
									${socials.includes('Google+') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/google_plus_2082ce542c.png" href="${this.getLink('Google+')}"></mj-social-element>` : ''}
									${socials.includes('Pinterest') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/pinterest_30db360395.png" href="${this.getLink('Pinterest')}"></mj-social-element>` : ''}
									${socials.includes('WeChat') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/wechat_c566d59358.png" href="${this.getLink('WeChat')}"></mj-social-element>` : ''}
									${socials.includes('Quora') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/quora_2fb387fc08.png" href="${this.getLink('Quora')}"></mj-social-element>`:''}
									${socials.includes('Foursquare') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/foursquare_54e2bbc3e4.png" href="${this.getLink('Foursquare')}"></mj-social-element>` : ''}
									${socials.includes('Klout') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/klout_d89258e142.png" href="${this.getLink('Klout')}"></mj-social-element>` : ''}
								</mj-social>
							</mj-column>
						</mj-section>
					</mj-body>
				</mjml>
				`
            } else if (this.account.template == '4') {
                mjml = `
					<mjml>
						<mj-body>
							<mj-section>
								<mj-column>
									<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
										<div>${this.newTemplate.message}</div>
									</mj-text>
								</mj-column>
							</mj-section>
				`
				mjml += `
						<mj-section>
							<mj-column width="65%">
								<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
									<strong>${this.account.name.toUpperCase()}</strong>
									${this.account.jobTitle && this.account.jobTitle != '' ? `<div>${this.account.jobTitle}</div>`:''}
									${this.account.company && this.account.company != '' ? `<div>${this.account.company}</div>`:''}
								</mj-text>
								<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
									${this.account && (this.account.email != '' || this.account.address != '' || this.account.website != '') ?
										`
										<table style="width:100%">
											${this.account.email != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Email: </td><td>${this.account.email}</td></tr>` : ''}
											${this.account.address != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Address: </td><td>${this.account.address}</td></tr>` : ''}
											${this.account.website != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Website: </td><td>${this.account.website}</td></tr>` : ''}
										</table>` : ''
									}
								</mj-text>
								<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
									${this.account && ((this.account.phone && this.account.phone.value != '') || this.account.officePhone != '' || this.account.fax != '') ?
										`
										<table style="width:100%">
											${this.account.phone.value != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">${this.account.phone.type}: </td><td>${this.account.phone.value}</td></tr>` : ''}
											${this.account.officePhone != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Office: </td><td>${this.account.officePhone}</td></tr>` : ''}
											${this.account.fax != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Fax: </td><td>${this.account.fax}</td></tr>` : ''}
										</table>` : ''
									}
								</mj-text>
								${this.account.appointment && this.settings && this.settings.options && this.settings.options.appointments && this.settings.options.appointments.widgetUrl && this.vendor && this.vendor.active ? `<mj-button align="left" background-color="${this.account.appointmentBgColor}" color="${this.account.appointmentColor}" href="${this.settings.options.appointments.widgetUrl}?userId=${this.vendor.user.id}">${this.account.appointmentText}</mj-button>` : ''}
							</mj-column>
							<mj-column width="35%">
								<mj-column width="100%" align="center">
									${this.account.logo && this.account.logo != '' ? `<mj-image src="${this.account.logo}"></mj-image>`:''}
								</mj-column>
								<mj-social icon-padding="2px">
									${socials.includes('Facebook') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/facebook_icon_1f824839f3.png" href="${this.getLink('Facebook')}"></mj-social-element>`:''}
									${socials.includes('Instagram') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/instagram_icon_8eb7fba8c2.png" href="${this.getLink('Instagram')}"></mj-social-element>` : ''}
									${socials.includes('Twitter') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/twitter_logo_email_2343518957.png" href="${this.getLink('Twitter')}"></mj-social-element>` : ''}
									${socials.includes('LinkedIn') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/linkedin_icon_6fd5b99eca.png" href="${this.getLink('LinkedIn')}"></mj-social-element>` : ''}
									${socials.includes('YouTube') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/youtube_c77a285d7a.png" href="${this.getLink('YouTube')}"></mj-social-element>`:''}
									${socials.includes('Google+') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/google_plus_2082ce542c.png" href="${this.getLink('Google+')}"></mj-social-element>` : ''}
									${socials.includes('Pinterest') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/pinterest_30db360395.png" href="${this.getLink('Pinterest')}"></mj-social-element>` : ''}
									${socials.includes('WeChat') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/wechat_c566d59358.png" href="${this.getLink('WeChat')}"></mj-social-element>` : ''}
									${socials.includes('Quora') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/quora_2fb387fc08.png" href="${this.getLink('Quora')}"></mj-social-element>`:''}
									${socials.includes('Foursquare') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/foursquare_54e2bbc3e4.png" href="${this.getLink('Foursquare')}"></mj-social-element>` : ''}
									${socials.includes('Klout') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/klout_d89258e142.png" href="${this.getLink('Klout')}"></mj-social-element>` : ''}
								</mj-social>
							</mj-column>
						</mj-section>
					</mj-body>
				</mjml>
				`
            } else if (this.account.template == '5') {
                mjml = `
					<mjml>
						<mj-body>
							<mj-section>
								<mj-column>
									<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
										<div>${this.newTemplate.message}</div>
									</mj-text>
								</mj-column>
							</mj-section>
				`
				mjml += `
						<mj-section>
							<mj-column width="100%" align="left">
								<mj-column width="35%" align="left">
									<mj-column width="100%" align="left">
										${this.account.logo && this.account.logo != '' ? `<mj-image src="${this.account.logo}"></mj-image>`:''}
									</mj-column>
								</mj-column>
								<mj-column width="100%" align="left">
									<mj-social icon-padding="2px" align="left">
										${socials.includes('Facebook') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/facebook_icon_1f824839f3.png" href="${this.getLink('Facebook')}"></mj-social-element>`:''}
										${socials.includes('Instagram') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/instagram_icon_8eb7fba8c2.png" href="${this.getLink('Instagram')}"></mj-social-element>` : ''}
										${socials.includes('Twitter') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/twitter_logo_email_2343518957.png" href="${this.getLink('Twitter')}"></mj-social-element>` : ''}
										${socials.includes('LinkedIn') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/linkedin_icon_6fd5b99eca.png" href="${this.getLink('LinkedIn')}"></mj-social-element>` : ''}
										${socials.includes('YouTube') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/youtube_c77a285d7a.png" href="${this.getLink('YouTube')}"></mj-social-element>`:''}
										${socials.includes('Google+') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/google_plus_2082ce542c.png" href="${this.getLink('Google+')}"></mj-social-element>` : ''}
										${socials.includes('Pinterest') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/pinterest_30db360395.png" href="${this.getLink('Pinterest')}"></mj-social-element>` : ''}
										${socials.includes('WeChat') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/wechat_c566d59358.png" href="${this.getLink('WeChat')}"></mj-social-element>` : ''}
										${socials.includes('Quora') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/quora_2fb387fc08.png" href="${this.getLink('Quora')}"></mj-social-element>`:''}
										${socials.includes('Foursquare') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/foursquare_54e2bbc3e4.png" href="${this.getLink('Foursquare')}"></mj-social-element>` : ''}
										${socials.includes('Klout') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/klout_d89258e142.png" href="${this.getLink('Klout')}"></mj-social-element>` : ''}
									</mj-social>
								</mj-column>
							</mj-column>
							<mj-column width="100%" align="left">
								<mj-column width="65%" align="left">
									<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
										<strong>${this.account.name.toUpperCase()}</strong>
										${this.account.jobTitle && this.account.jobTitle != '' ? `<div>${this.account.jobTitle}</div>`:''}
										${this.account.company && this.account.company != '' ? `<div>${this.account.company}</div>`:''}
									</mj-text>
									<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
										${this.account && (this.account.email != '' || this.account.address != '' || this.account.website != '') ?
											`
											<table style="width:100%">
												${this.account.email != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Email: </td><td>${this.account.email}</td></tr>` : ''}
												${this.account.address != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Address: </td><td>${this.account.address}</td></tr>` : ''}
												${this.account.website != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Website: </td><td>${this.account.website}</td></tr>` : ''}
											</table>` : ''
										}
									</mj-text>
									<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
										${this.account && ((this.account.phone && this.account.phone.value != '') || this.account.officePhone != '' || this.account.fax != '') ?
											`
											<table style="width:100%">
												${this.account.phone.value != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">${this.account.phone.type}: </td><td>${this.account.phone.value}</td></tr>` : ''}
												${this.account.officePhone != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Office: </td><td>${this.account.officePhone}</td></tr>` : ''}
												${this.account.fax != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Fax: </td><td>${this.account.fax}</td></tr>` : ''}
											</table>` : ''
										}
									</mj-text>
									${this.account.appointment && this.settings && this.settings.options && this.settings.options.appointments && this.settings.options.appointments.widgetUrl && this.vendor && this.vendor.active ? `<mj-button align="left" background-color="${this.account.appointmentBgColor}" color="${this.account.appointmentColor}" href="${this.settings.options.appointments.widgetUrl}?userId=${this.vendor.user.id}">${this.account.appointmentText}</mj-button>` : ''}
								</mj-column>
							</mj-column>
						</mj-section>
					</mj-body>
				</mjml>
				`
            }


            let sendObj = {}
            sendObj.mjml = mjml
            sendObj.subject = this.newTemplate.subject
            sendObj.from = this.nylasAccount.email
			sendObj.media = this.newTemplate.attachments.map(x => x = {url:x.url, filename:x.name})
			sendObj.to = this.user.email
			sendObj.bcc = []
			sendObj.cc = []

            console.log('sendObj', sendObj)
            this.$store.commit('LOAD',true)
            let url = `/services/email/send`
            if (this.vendor && this.vendor.active){
                url = `/contacts/:instance/sendmail`
                delete sendObj.from
            }
            this.$api.post(url, sendObj).then(({data}) =>{
                    console.log('data', data)
                    this.$notification['success']({
                        message: `Test Email Sent`,
                        description: 'Your email has been sent successfully.',
                        duration: 4
                    });
                })
                .catch( (err) => {
                    console.error('errr',err)
                    this.$message.error('Error sending email. Please try again.')
                })
                .finally(() => {
                    this.$store.commit('LOAD',false)
                })
        },
		fileSize(size){
            //kb size convert to mb and gb
			let kb = size;
			let mb = size / 1024;
			let gb = size / 1024 / 1024;
			if(kb < 1) {
				return '1 KB'
			} else if(kb < 1024) {
				return kb.toFixed(2) + ' KB';
			} else if(mb < 1024) {
				return mb.toFixed(2) + ' MB';
			} else if(gb < 1024) {
				return gb.toFixed(2) + ' GB';
			}
        },
		getSocials(array) {
            let social = array.filter(x => {
                if (x.type) return x
            })
            return social
        },
		getLink(type) {
            let socials = this.account.socials.map(x => x = x.type)
            if (socials.includes(type)) {
                let index = this.account.socials.findIndex(x => x.type == type)
                return this.account.socials[index].link
            }
        },
        addNewTemplate() {
            this.$store.commit('ADD_NEW', 'Email')
            this.$store.commit('ADD_TEXT_TEMPLATE')
        },
        saveEdit(text){
            if (text == '') {
                console.log('no edit to be updated')
            } else if (text.length > 7) {
                let settings = JSON.parse(JSON.stringify(this.settings))
                let templates = settings.options.templateList
                let index = templates.findIndex(x => x.createdAt == this.newTemplate.createdAt)
                templates[index].subject = text
				templates[index].attachments = this.newTemplate.attachments
                // this.loading=true
                this.$api.post(`/settings/:instance/contacts`, settings).then(({data}) => {
                    this.$store.commit('UPDATE_ALLSETTINGS', data)
                    // this.loading = false
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            } else if (text.length == 7) {
                return this.$message.error('This email needs a subject')
            }
        },
        previewEmail(html = {}){
			console.log('PREVIEW EMAIL CLICKED', html)
			let message = this.newTemplate.message && this.newTemplate.message.replace(/<[^>]+>/g, '')
            if ((!html?.html?.trim()) && !message?.trim()) {
                return this.$message.error('This email needs a body')
            } else {
				let name = this.newTemplate.name
				let subject = this.newTemplate.subject
                let text = this.newTemplate.message
                if (html?.html?.trim()) text = html.html
                let settings = JSON.parse(JSON.stringify(this.settings))
                let templates = settings.options.templateList
                let index = templates.findIndex(x => x.createdAt == this.newTemplate.createdAt)
				templates[index].name = name
				templates[index].subject = subject
                templates[index].message = text
				templates[index].attachments = html.attachments
                this.loading = true
                this.$api.post(`/settings/:instance/contacts`, settings).then(({data}) => {
                    this.$store.commit('UPDATE_ALLSETTINGS', data)
                    this.loading = false
                    this.cancelEdit()
                }).catch(err => {
					this.loading = false
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            }

        },
        convertDate(num){
			let x = new Date(num)
			//Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
			let year = x.getFullYear()
			let month = x.getMonth()+1
			let day = x.getDate()
			if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
			else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
			else {
				return month+'/'+day+'/'+year
			}
		},
        saveTemplate(){
            this.$refs.newTemplate.validate(valid => {
                if (valid) {
                    console.log('newTemplate', this.newTemplate)
                    this.showEditModal = false
                } else {
                    console.log('error submit!!');
                return false;
                }
            })
        },
        req:msg=>({required:true,message:msg}),
        previewEmailTemplate(item) {
            this.showEmailModal = true,
            this.newTemplate = item
        },
        cancelEmail(){
            this.showEmailModal = false
        },

        editEmailTemplate(item){
			console.log('EDITING EMAIL TEMPLATE', item)
            this.newTemplate = JSON.parse(JSON.stringify(item))
            this.showEditModal = true
        },
        cancelEdit(){
            this.newTemplate = {}
            this.showEditModal = false
        },
        deleteEmailTemplate(item){
            let self = this
            if (this.$p < 40){
                return this.$message.error('You do not have permission to delete Email Templates')
            }
            this.$confirm({
                title: "Delete Template",
                content: h => <div>Do you want to delete this Email Template?</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    console.log('deleteEmailTemplate', item)
                    let sendObj = JSON.parse(JSON.stringify(self.settings))
                    let index = sendObj.options.templateList.findIndex( x => x.createdAt == item.createdAt)
                    sendObj.options.templateList.splice(index,1)
                    self.$api.post(`/settings/:instance/contacts`, sendObj).then(({data}) => {
                        self.$store.commit('UPDATE_ALLSETTINGS', data)
                        self.$store.commit('CLOSE_EMAIL_TEMPLATE')
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },

		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
		},

		exportTemplates() {
			let self = this;
			this.$refs.exportModal && this.$refs.exportModal.validate(async (valid) => {
				if (valid) {
					self.$store.commit('LOAD', true)
					try {
						await self.$api.post(`settings/:instance/contacts/clone-settings`, {
							targetInstance: self.exportModal.instance,
							keysToClone: [
								"templateList",
							],
							indicesToClone: self.exportModal.indicesToClone
						})
						self.$store.commit('LOAD', false)
						self.$message.success('Successfully exported all the templates into selected project.')
						self.exportModal = {
							visible: false,
							instance: null,
							indicesToClone: []
						}
					} catch (err) {
						self.$store.commit('LOAD', false)
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err, `An error occurred while exporting templates to another project. Please try again.`))
						}
					}
				} else {
					return false;
				}
			})
		},
    },
	mounted() {
		let queryParam = this.$route.query["template"]
		if (queryParam === 'addNew') {
			this.addNewTemplate();
		} else if (queryParam) {
			const template = this.templateList.find((t) => t.createdAt === +(queryParam))
			if (template) {
				this.editEmailTemplate(template)
			}
		}
	}
}
</script>

<style lang="scss" scoped>

    .actionIcons {
        color: #9EA0A5;
        cursor: pointer;
        transition:color .1s ease-in;
    }
    .actionIcons:hover {
        color: var(--orange);
        transition:color .1s ease-in;
    }
</style>

<style lang="scss">
.header-subject p{
    margin:0 !important;
}
.header-subject ul{
    margin:0 !important;
}
.header-subject ol{
    margin:0 !important;
}
.header-subject h1{
    margin:0 !important;
}
.header-subject h2{
    margin:0 !important;
}
.header-subject h3{
    margin:0 !important;
}
.form-test .ant-form-item{
    margin-top:20px;
    margin-bottom:0px!important
}
</style>
