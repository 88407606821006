<template>
    <div>
        <BHLoading :show="loading" />
        <a-modal :visible="visible" :centered="true" :footer="null" @cancel="onClose">
            <div v-if="editModal.object.type && editModal.object.type == 'leadStatus'">
                <h5>Edit Lead Status</h5>
                <a-form-model class="mt-4" :model="leadStatus" ref="leadStatus">
                    <a-form-model-item label="Name" prop="name" required :rules="req('Please enter a name')">
                        <a-input size="large" placeholder="Please enter a name" v-model="leadStatus.name" />
                    </a-form-model-item>
                </a-form-model>
            </div>
            <div v-else-if="editModal.object.type && editModal.object.type == 'tag'">
                <h5>Edit Tag</h5>
                <a-form-model class="mt-4" :model="tag" ref="tag">
                    <a-form-model-item label="Name" prop="name" required :rules="req('Please enter a name')">
                        <a-input size="large" placeholder="Please enter a name" v-model="tag.name" />
                    </a-form-model-item>
                </a-form-model>
            </div>
            <div v-else-if="editModal.object.type && editModal.object.type == 'leadSource'">
                <h5>Edit Lead Source</h5>
                <a-form-model class="mt-4" :model="leadSource" ref="leadSource">
                    <a-form-model-item label="Name" prop="name" required :rules="req('Please enter a name')">
                        <a-input size="large" placeholder="Please enter a name" v-model="leadSource.name" />
                    </a-form-model-item>
                </a-form-model>
            </div>
            <div v-else-if="editModal.object.type && editModal.object.type == 'dealSource'">
                <h5>Edit Deal Source</h5>
                <a-form-model class="mt-4" :model="dealSource" ref="dealSource">
                    <a-form-model-item label="Name" prop="name" required :rules="req('Please enter a name')">
                        <a-input size="large" placeholder="Please enter a name" v-model="dealSource.name" />
                    </a-form-model-item>
                </a-form-model>
            </div>
            <div v-else-if="editModal.object.type && editModal.object.type == 'lossReason'">
                <h5>Edit Loss Reason</h5>
                <a-form-model class="mt-4" :model="lossReason" ref="lossReason">
                    <a-form-model-item label="Name" prop="name" required :rules="req('Please enter a name')">
                        <a-input size="large" placeholder="Please enter a name" v-model="lossReason.name" />
                    </a-form-model-item>
                </a-form-model>
            </div>
            <div v-else-if="editModal.object.type && editModal.object.type == 'taskType'">
                <h5>Edit Task Type</h5>
                <a-form-model class="mt-4" :model="taskType" ref="taskType">
                    <a-form-model-item label="Name" prop="name" required :rules="req('Please enter a name')">
                        <a-input size="large" placeholder="Please enter a name" v-model="taskType.name" />
                    </a-form-model-item>
                </a-form-model>
            </div>
			<div v-else-if="editModal.object.type && editModal.object.type == 'sentimentOption'">
                <h5>Edit Sentiment</h5>
                <a-form-model class="mt-4" :model="sentimentOption" ref="sentimentOption">
                    <a-form-model-item label="Name" prop="name" required :rules="req('Please enter a name')">
                        <a-input size="large" placeholder="Please enter a name" v-model="sentimentOption.name" />
                    </a-form-model-item>
                </a-form-model>
            </div>
            <div v-else-if="editModal.object.type && editModal.object.type == 'appointmentType'">
                <h5>Edit Appointment Type</h5>
                <a-form-model class="mt-4" :model="appointmentType" ref="appointmentType">
                    <a-form-model-item label="Name" prop="name" required :rules="req('Please enter a name')">
                        <a-input size="large" placeholder="Please enter a name" v-model="appointmentType.name" />
                    </a-form-model-item>
                </a-form-model>
            </div>
            <div class="dF jE">
                <a-button @click="onClose" size="large" class="cancel-button mr-3" style="width:116px">CANCEL</a-button>
                <a-button @click="submit" size="large" type="primary" style="width:116px">SAVE</a-button>
            </div>
        </a-modal>
    </div>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'
export default {
    components:{BHLoading},
    watch:{
        visible:{
            handler(val) {
                if (val) {
                    if (this.editModal.object.type == 'leadStatus') {
                        this.leadStatus = JSON.parse(JSON.stringify(this.editModal.object.item))
                    } else if (this.editModal.object.type == 'tag') {
                        this.tag = JSON.parse(JSON.stringify(this.editModal.object.item))
                    } else if (this.editModal.object.type == 'leadSource') {
                        this.leadSource = JSON.parse(JSON.stringify(this.editModal.object.item))
                    } else if (this.editModal.object.type == 'dealSource') {
                        this.dealSource = JSON.parse(JSON.stringify(this.editModal.object.item))
                    } else if (this.editModal.object.type == 'lossReason') {
                        this.lossReason = JSON.parse(JSON.stringify(this.editModal.object.item))
                    } else if (this.editModal.object.type == 'taskType') {
                        this.taskType = JSON.parse(JSON.stringify(this.editModal.object.item))
                    } else if (this.editModal.object.type == 'sentimentOption') {
                        this.sentimentOption = JSON.parse(JSON.stringify(this.editModal.object.item))
                    } else if (this.editModal.object.type == 'appointmentType') {
                        this.appointmentType = JSON.parse(JSON.stringify(this.editModal.object.item))
                    }
                }
            }
        }
    },
    computed:{
        visible() {
            return this.$store.state.contacts.editModal.visible
        },
        editModal() {
            return this.$store.state.contacts.editModal
        },
        settings() {
            return this.$store.state.contacts.allSettings
        }
    },
    data() {
        return{
            loading:false,
            leadStatus:{},
            tag:{},
            leadSource:{},
            dealSource:{},
            lossReason:{},
            taskType:{},
			sentimentOption:{},
            appointmentType:{}
        }
    },
    methods:{
        onClose() {
            if(this.editModal.object.type == 'leadStatus') {
				if(this.$refs.leadStatus) this.$refs.leadStatus.resetFields()
                this.leadStatus = {}
            } else if(this.editModal.object.type == 'tag') {
                if(this.$refs.tag) this.$refs.tag.resetFields()
                this.tag = {}
            } else if (this.editModal.object.type == 'leadSource') {
                if(this.$refs.leadSource) this.$refs.leadSource.resetFields()
                this.leadSource = {}
            } else if (this.editModal.object.type == 'dealSource') {
                if(this.$refs.dealSource) this.$refs.dealSource.resetFields()
                this.dealSource = {}
            } else if (this.editModal.object.type == 'lossReason') {
                if(this.$refs.lossReason) this.$refs.lossReason.resetFields()
                this.lossReason = {}
            } else if (this.editModal.object.type == 'taskType') {
                if(this.$refs.taskType) this.$refs.taskType.resetFields()
                this.taskType = {}
            } else if (this.editModal.object.type == 'sentimentOption') {
                if(this.$refs.sentimentOption) this.$refs.sentimentOption.resetFields()
                this.sentimentOption = {}
            } else if (this.editModal.object.type == 'appointmentType') {
                if(this.$refs.appointmentType) this.$refs.appointmentType.resetFields()
                this.appointmentType = {}
            }
            this.$store.commit('CLOSE_EDIT_MODAL')
        },
        submit() {
            if (this.editModal.object.type == 'leadStatus') {
                this.$refs.leadStatus.validate(valid => {
                    if (valid) {
                        let sendObj = JSON.parse(JSON.stringify(this.leadStatus))
                        this.loading = true
                        this.$api.put(`/lead-statuses/:instance/${sendObj.id}`, sendObj).then( ({data}) => {
                            this.$store.commit('UPDATE_LEADSTATUS', data)
                            this.loading = false
                            this.onClose()
                        }).catch(err => {
							this.loading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    }
                })
            } else if (this.editModal.object.type == 'tag') {
                this.$refs.tag.validate(valid => {
                    if (valid) {
                        let sendObj = JSON.parse(JSON.stringify(this.tag))
                        this.loading = true
                        this.$api.put(`/tags/:instance/${sendObj.id}`, sendObj).then( ({data}) => {
                            this.$store.commit('UPDATE_TAG', data)
                            this.loading = false
                            this.onClose()
                        }).catch(err => {
							this.loading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    }
                })
            } else if (this.editModal.object.type == 'leadSource') {
                this.$refs.leadSource.validate(valid => {
                    if (valid) {
                        let sendObj = JSON.parse(JSON.stringify(this.leadSource))
                        if (this.settings.app && this.settings.app.options && this.settings.app.options.leadSource) {
                            let settings = JSON.parse(JSON.stringify(this.settings))
                            let index = settings.app.options.leadSource.findIndex(x => x.id == sendObj.id)
                            if (index != -1) {
                                settings.app.options.leadSource[index] = sendObj
                                this.loading = true
                                this.$api.post('/settings/:instance/contacts', settings.app).then( ({data}) => {
                                    this.$store.commit('UPDATE_ALLSETTINGS', data)
                                    this.loading = false
                                    this.onClose()
                                }).catch(err => {
									this.loading = false
									if (!err || !err.response || !err.response.status || err.response.status !== 400) {
										this.$message.error(this.$err(err))
									}
								})
                            }
                        }
                    }
                })
            } else if (this.editModal.object.type == 'dealSource') {
                this.$refs.dealSource.validate(valid => {
                    if (valid) {
                        let sendObj = JSON.parse(JSON.stringify(this.dealSource))
                        if (this.settings.app && this.settings.app.options && this.settings.app.options.dealSources) {
                            let settings = JSON.parse(JSON.stringify(this.settings))
                            let index = settings.app.options.dealSources.findIndex(x => x.id == sendObj.id)
                            if (index != -1) {
                                settings.app.options.dealSources[index] = sendObj
                                this.loading = true
                                this.$api.post('/settings/:instance/contacts', settings.app).then( ({data}) => {
                                    this.$store.commit('UPDATE_ALLSETTINGS', data)
                                    this.loading = false
                                    this.onClose()
                                }).catch(err => {
									this.loading = false
									if (!err || !err.response || !err.response.status || err.response.status !== 400) {
										this.$message.error(this.$err(err))
									}
								})
                            }
                        }
                    }
                })
            } else if (this.editModal.object.type == 'lossReason') {
                this.$refs.lossReason.validate(valid => {
                    if (valid) {
                        let sendObj = JSON.parse(JSON.stringify(this.lossReason))
                        if (this.settings.app && this.settings.app.options && this.settings.app.options.lostReasons) {
                            let settings = JSON.parse(JSON.stringify(this.settings))
                            let index = settings.app.options.lostReasons.findIndex(x => x.id == sendObj.id)
                            if (index != -1) {
                                settings.app.options.lostReasons[index] = sendObj
                                this.loading = true
                                this.$api.post('/settings/:instance/contacts', settings.app).then( ({data}) => {
                                    this.$store.commit('UPDATE_ALLSETTINGS', data)
                                    this.loading = false
                                    this.onClose()
                                }).catch(err => {
									this.loading = false
									if (!err || !err.response || !err.response.status || err.response.status !== 400) {
										this.$message.error(this.$err(err))
									}
								})
                            }
                        }
                    }
                })
            } else if (this.editModal.object.type == 'taskType') {
                this.$refs.taskType.validate(valid => {
                    if (valid) {
                        let sendObj = JSON.parse(JSON.stringify(this.taskType))
                        if (this.settings.app && this.settings.app.options && this.settings.app.options.taskType) {
                            let settings = JSON.parse(JSON.stringify(this.settings))
                            let index = settings.app.options.taskType.findIndex(x => x.id == sendObj.id)
                            if (index != -1) {
                                settings.app.options.taskType[index] = sendObj
                                this.loading = true
                                this.$api.post('/settings/:instance/contacts', settings.app).then( ({data}) => {
                                    this.$store.commit('UPDATE_ALLSETTINGS', data)
                                    this.loading = false
                                    this.onClose()
                                }).catch(err => {
									this.loading = false
									if (!err || !err.response || !err.response.status || err.response.status !== 400) {
										this.$message.error(this.$err(err))
									}
								})
                            }
                        }
                    }
                })
            } else if (this.editModal.object.type == 'sentimentOption') {
                this.$refs.sentimentOption.validate(valid => {
                    if (valid) {
                        let sendObj = JSON.parse(JSON.stringify(this.sentimentOption))
                        if (this.settings.app && this.settings.app.options && this.settings.app.options.sentimentOptions) {
                            let settings = JSON.parse(JSON.stringify(this.settings))
                            let index = settings.app.options.sentimentOptions.findIndex(x => x.id == sendObj.id)
                            if (index != -1) {
                                settings.app.options.sentimentOptions[index] = sendObj
                                this.loading = true
                                this.$api.post('/settings/:instance/contacts', settings.app).then( ({data}) => {
                                    this.$store.commit('UPDATE_ALLSETTINGS', data)
                                    this.loading = false
                                    this.onClose()
                                }).catch(err => {
									this.loading = false
									if (!err || !err.response || !err.response.status || err.response.status !== 400) {
										this.$message.error(this.$err(err))
									}
								})
                            }
                        }
                    }
                })
            } else if (this.editModal.object.type == 'appointmentType') {
                this.$refs.appointmentType.validate(valid => {
                    if (valid) {
                        let sendObj = JSON.parse(JSON.stringify(this.appointmentType))
                        if (this.settings.app && this.settings.app.options && this.settings.app.options.appointmentTypes) {
                            let settings = JSON.parse(JSON.stringify(this.settings))
                            let index = settings.app.options.appointmentTypes.findIndex(x => x.id == sendObj.id)
                            if (index != -1) {
                                settings.app.options.appointmentTypes[index] = sendObj
                                this.loading = true
                                this.$api.post('/settings/:instance/contacts', settings.app).then( ({data}) => {
                                    this.$store.commit('UPDATE_ALLSETTINGS', data)
                                    this.loading = false
                                    this.onClose()
                                }).catch(err => {
									this.loading = false
									if (!err || !err.response || !err.response.status || err.response.status !== 400) {
										this.$message.error(this.$err(err))
									}
								})
                            }
                        }
                    }
                })
            }
        },
        req:msg=>({required:true,message:msg}),
    }
}
</script>

<style>

</style>
