import Vue from 'vue'
import Router from 'vue-router'
import AppLayout from 'bh-mod/layouts/App'
import AuthLayout from 'bh-mod/layouts/Auth'
import Splash from '@/layouts/Splash'
import store from '@/store'
import { VueAppMiddleware } from 'bh-mod'

Vue.use(Router)

const router = new Router({
	base: process.env.BASE_URL,
	mode: 'history',
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			path: '/',
			redirect: '/v3',
			component: AppLayout,
			meta: { instanceRequired: true, auth: true, hidden: true, },
			children: [
				// Dashboards
				{
					path: '/',
					meta: {
						title: 'My Work Day',
						sidebar: true,
						dataRequired: true
					},
					component: () =>
						import('./views/app/overview'),
				},
				{
					path: '/appointments',
					meta: {
						title: 'Appointments',
						sidebar: true,
						dataRequired: true
					},
					component: () =>
						import('./views/app/appointments'),
				},
				{
                    path: '/events',
                    meta: {
                        title: 'Events',
                        sidebar: true,
                        dataRequired: true
                    },
                    component: () =>
                        import ('./views/app/events'),
                },
				{
                    path: '/events/:id',
                    meta: {
                        title: 'Event Details',
                        sidebar: true,
                        dataRequired: true,
                    },
                    component: () =>
                        import ('./components/common/EventDetails'),
                },
				{
					path: '/leads',
					meta: {
						title: 'Leads',
						sidebar: true,
						dataRequired: true
					},
					component: () =>
						import('./views/app'),
				},
				{
					path: '/leads/:id',
					// redirect: '/leads',
					meta: {
						title: 'Lead Details',
						sidebar: true,
						dataRequired: true,
					},
					component: () =>
						import('./components/common/ExpandedDetails'),
				},
				// Opportunities
				{
					path: '/opportunities/sales',
					meta: {
						title: 'Opportunities',
						sidebar: true,
						dataRequired: true
					},
					component: () =>
						import('./views/app/salespipeline'),
				},
				{
					path: '/opportunities/sales/:id',
					meta: {
						title: 'Opportunity Details',
						sidebar: true,
						dataRequired: true
					},
					component: () =>
						import('./components/common/ExpandedOpportunityDetails'),
				},
				{
					path: '/opportunities/archived',
					meta: {
						title: 'Opportunities',
						sidebar: true,
						dataRequired: true
					},
					component: () =>
						import('./views/app/archived'),
				},
				// Tasks
				{
                    path: '/tasks',
                    meta: {
                        title: 'Tasks',
                        sidebar: true,
                        dataRequired: true
                    },
                    component: () =>
                        import ('./views/app/tasks'),
                },
				// Email
				{
					path: '/email/inbox',
					meta: {
						title: 'Email',
						sidebar: true,
						dataRequired: true
					},
					component: () =>
						import('./views/app/inbox'),
				},
				{
					path: '/email/sent',
					meta: {
						title: 'Email',
						sidebar: true,
						dataRequired: true
					},
					component: () =>
						import('./views/app/sent'),
				},

				// Calendar
				{
					path: '/calendar',
					meta: {
						title: 'Calendar',
						sidebar: true,
						dataRequired: true
					},
					component: () =>
						import('./views/app/calendar'),
				},

				// Settings
				{
					path: '/settings',
					meta: {
						title: 'Settings',
						sidebar: true,
						dataRequired: true
					},
					component: () =>
						import('./views/app/settings'),
				},
				{
					path: "/notifications",
					meta: {
						title: "All Notifications",
						sidebar: true,
						dataRequired: true
					},
					component: () => import('bh-mod/components/layout/TopBar/Actions/notifications.vue'),
				},
			],
		},
		{
			path: '/',
			component: AuthLayout,
			meta: { instanceRequired: true, auth: true, hidden: true },
			children: [
				// Dashboards
				{
					path: '/authenticate/outlook',
					meta: {
						title: 'Outlook Authentication',
					},
					component: () =>
						import('./views/app/authenticate'),
				}
			],
		},
		{
			path: '/setup',
			component: Splash,
			meta: { hidden: true, splash: true },
			children: [{
				path: '/setup',
				meta: {
					title: 'Setup Contacts',
					splashScreen: true
				},
				component: () =>
					import('./views/setup'),

			}]
		},
		{
			path: '/404',
			component: Splash,
			children: [
				{
					path: '/404',
					meta: {
						title: 'Error 404',
					},
					component: () => import('bh-mod/components/common/404.vue'),
				}
			]
		},
		{
			path: '/unauthorized',
			component: AuthLayout,
			children: [
				{
					path: '/unauthorized',
					meta: {
						title: 'Unauthorized',
						unauthorized: true
					},
					component: () => import('bh-mod/components/common/unauthorized.vue'),
				}
			]
		},
		// Other routes...
		{
			// Catch-all route: This will match any invalid route
			path: '*',
			redirect: '/404'
		}
	]
})
let hiveRequested = false
router.beforeEach(async (to, from, next) => {
	let dataRequired = to.matched.some(record => record.meta.dataRequired)
	let splashScreen = to.matched.some(record => record.meta.splashScreen)
	let unauthorized = to.matched.some(record => record.meta.unauthorized)
	let error = false

	if (!hiveRequested) {
		let response = await VueAppMiddleware(to, store, 'contacts', '?_sort=createdAt:DESC&pageSize=' + store.state.contacts.pageSize)
		error = response.parsed
		hiveRequested = true
	}

	if (error) {
		if (error === 'User does not have required permission!') {
			return next('/unauthorized')
		}
		return console.error('ERROR', error)
	} else {
		if (unauthorized) {
			return next();
		}
		if (splashScreen && store.state.contacts.allSettings.userApp.options.seenIntro) {
			return next('/')
		} else if (dataRequired && !store.state.contacts.getStarted && !store.state.contacts.allSettings.userApp.options.seenIntro) {
			return next('/setup')
		}
	}

	next()
})


export default router
