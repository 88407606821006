<template>
    <a-card class="salesCard" v-if="obj.hasOwnProperty('id')">
        <a-row type="flex">
            <a-col flex="230px">
                <div class="dF aC" style="font-weight: bold;">
                    {{obj.name}}
                    <a-tooltip class="ml-2" v-if="obj.product && checkAvailability(obj)" overlayClassName="change-tooltip-color">
                        <template slot="title">
                            The product for this opportunity is no longer available.
                        </template>
                        <a-icon type="warning" style="color: var(--danger);" />
                    </a-tooltip>
                </div>
            </a-col>
            <a-col flex="auto" style="display: flex; justify-content: flex-end;">
                <a-popover trigger="hover" placement="bottomRight" overlayClassName="popoverStyle">
                    <div slot="content">
                        <div @click="opportunityDetails(obj)" class="popoverContent"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye mr-2"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>Quick View</div>
                        <div @click="$router.push(`/opportunities/sales/${obj.id}`)" v-if="obj.stage !== 'won' && obj.stage !== 'lost'" class="popoverContent"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>Edit Opportunity</div>
                        <div v-if="obj.stage != 'won' && obj.stage != 'lost'" @click="addNote(obj)" class="popoverContent dF aC"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square mr-2"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg></svg><div>Add Note</div></div>
                        <div v-if="obj.stage != 'won' && obj.stage != 'lost'" @click="addTask(obj)" class="popoverContent dF aC"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-square mr-2"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg><div>Add Task</div></div>
                        <div v-if="obj.stage != 'won' && obj.stage != 'lost'" @click="addAppointment(obj)" class="popoverContent dF aC"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar mr-2"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg><div>Add Appointment</div></div>
                        <div v-if="obj.stage != 'won' && obj.stage != 'lost' && obj.contact && obj.contact.id" @click="emailContact(obj.contact.id)" class="popoverContent dF aC"><svg style="cursor:pointer" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail mr-2"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg><div>Email</div></div>
                        <div @click="$emit('archive',obj)" v-if="obj.stage === 'won' || obj.stage === 'lost'" class="popoverContent"><i class="fa fa-archive mr-2"/>Archive</div>
                        <div @click="$emit('marklost',obj)" v-if="obj.stage !== 'won' && obj.stage !== 'lost' && obj.stage !== 'transaction'" class="popoverContent"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-thumbs-down mr-2"><path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17"></path></svg>Mark as Lost</div>
                        <div @click="$emit('markwon',obj)" v-if="obj.stage !== 'won' && obj.stage !== 'lost' && obj.stage !== 'transaction'" class="popoverContent"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-thumbs-up mr-2"><path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path></svg>Mark as Won</div>
                        <a v-if="!obj.transaction && obj.stage !== 'won' && obj.stage !== 'lost' && obj.contact && obj.contact.id && obj.contact.id != ''" class="popoverContent dF aC" style="color:rgba(0, 0, 0, 0.65)" :href="`https://transactions.bildhive.${$tld}/new?c=${obj.contact.id}&o=${obj.id}`" target="_blank"><svg width="14" height="14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exchange-alt" class="mr-2 svg-inline--fa fa-exchange-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M0 168v-16c0-13.255 10.745-24 24-24h360V80c0-21.367 25.899-32.042 40.971-16.971l80 80c9.372 9.373 9.372 24.569 0 33.941l-80 80C409.956 271.982 384 261.456 384 240v-48H24c-13.255 0-24-10.745-24-24zm488 152H128v-48c0-21.314-25.862-32.08-40.971-16.971l-80 80c-9.372 9.373-9.372 24.569 0 33.941l80 80C102.057 463.997 128 453.437 128 432v-48h360c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24z"></path></svg><div></div>Process Transaction</a>
                        <div @click="$emit('deleteopp',obj)" v-if="obj.stage != 'won' && obj.stage != 'lost' && obj.stage !== 'transaction'" class="popoverContent"><svg style="color:var(--danger)" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 mr-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>Delete Opportunity</div>
                    </div>
                    <div class="more-option-icon">
                        <a-icon type="more" />
                    </div>
                </a-popover>
            </a-col>
        </a-row>

        <div class="dF mt-1">
            <a-col class="dF" style="padding:0; font-size: 13px;">
                <div v-if="obj.contact" class="mr-1">{{obj.contact.firstName + ' ' + obj.contact.lastName}}</div>
                <div v-else class="mr-1">No Contact</div>
                |
                <div class="ml-1 mr-3" style="font-weight: bold;">${{$formatNumber(obj.dealValue)}}</div>
            </a-col>
            <a-col style="padding:0;">
                <div style="background-color: #F4F3F6; text-align: center; width: 50px;">
                    {{obj.probability || '0'}}%
                </div>
            </a-col>
        </div>

        <div style="font-size: 13px;">
            <template v-if="obj.stage !== 'won' && obj.stage !== 'lost'">
                Estimated Opportunity Close Date: {{convertDate(obj.closeDate)}}
            </template>
            <template v-else>
                Executed Date: {{convertDate(obj.closeDate)}}
            </template>
			<div v-if="obj.worksheet && obj.worksheet.id" class="mt-2">
				<a :href="`https://worksheets.bildhive.${$tld}/edit/${obj.worksheet.id}`" target="_blank">
					<a-tag style="cursor: pointer;">
						Worksheet - {{ obj.worksheet.readableId }} <a-icon type="man" class="ml-1" />
					</a-tag>
				</a>
			</div>
        </div>

        <a-row v-if="obj.stage !== 'won' && obj.stage !== 'lost'" :gutter="16" type="flex" style="margin-left: 0; margin-right: 0; align-items: center;" class="mt-3">
            <a-col :span="16" style="padding: 0;">
                <div class="dF" style="color: #9EA0A5;">
                    <a-icon type="clock-circle" style="font-size: 16px;" />
                    <div class="ml-2 mr-3" style="vertical-align: middle;">{{moment(obj.closeDate).fromNow()}}</div>
                    <div class="dF" style="color: #9ea0a5; align-items: center;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12.048 12.049" fill="#9ea0a5"> <path id="Icon_awesome-comment-alt" data-name="Icon awesome-comment-alt" class="cls-1" d="M10.542,0H1.506A1.507,1.507,0,0,0,0,1.506V8.283A1.507,1.507,0,0,0,1.506,9.789H3.765v1.977a.283.283,0,0,0,.449.228l2.939-2.2h3.388a1.507,1.507,0,0,0,1.506-1.506V1.506A1.507,1.507,0,0,0,10.542,0Z"/> </svg>
                        <div class="ml-1 mr-2">{{obj.notes.length}}</div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18.622 19.049" style="margin-top: -4px;" fill="#9ea0a5"><g id="Group_4967" data-name="Group 4967" transform="translate(-476.378 -389)"> <path id="Icon_awesome-check-square" data-name="Icon awesome-check-square" d="M13.617,17.5H1.634A1.634,1.634,0,0,1,0,15.867V3.884A1.634,1.634,0,0,1,1.634,2.25H13.617a1.634,1.634,0,0,1,1.634,1.634V15.867A1.634,1.634,0,0,1,13.617,17.5ZM6.649,14.163,12.913,7.9a.545.545,0,0,0,0-.77l-.77-.77a.545.545,0,0,0-.77,0L6.264,11.467,3.879,9.082a.545.545,0,0,0-.77,0l-.77.77a.545.545,0,0,0,0,.77l3.541,3.541a.545.545,0,0,0,.77,0Z" transform="translate(476.378 390.547)"/> <g v-if="hasActiveTasks(obj)" id="Components_Badges_Number" data-name="Components/Badges/Number" transform="translate(487.292 389)"> <g id="Components_Badges_Number-2" data-name="Components/Badges/Number" transform="translate(0 0)"> <g id="Inner" transform="translate(0)"> <circle id="Oval" fill="#f7941e" cx="4" cy="4" r="4" transform="translate(-0.292)"/> </g> </g> </g> </g> </svg>
						<div class="ml-1">{{ taskCount }}</div>
                    </div>
                </div>
            </a-col>
            <a-col :span="8" class="dF" style="justify-content: flex-end; padding: 0;">
				<a-popover trigger="click" placement="bottom">
					<div slot="content" class="dF">
						<a-select
							v-model="assignees"
							style="width:200px"
							mode="multiple"
							placeholder="Type to search..."
							size="large"
							:not-found-content="null"
							:filter-option="filterOption"
							@search="searchAssign">
							<a-select-option v-for="user in userSource" :key="user.id" :value="user.id">
								{{ user.name }}
							</a-select-option>
						</a-select>
						<div class="ml-3">
							<a-button size="large" @click="saveAssignee(obj)" type="primary">SAVE</a-button>
						</div>
					</div>
					<a-avatar shape="circle" icon="plus" :size="28" class="mr-1" style="border: 1px dashed rgb(158, 160, 165); background-color: white; color: #9EA0A5; cursor: pointer;" />
				</a-popover>
                <template v-for="(user,userI) in obj.owners">
                    <div v-if="userI < 2" :key="user+userI" style="cursor:pointer">
                        <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                            <template slot="title">{{`${user.firstName} ${user.lastName}`}}</template>
                            <a-avatar v-if="user.avatar && user.avatar !== ''" :class="userI < obj.owners.length-1?'mr-1':''" :src="user.avatar" shape="circle" :size="28"/>
                            <a-avatar v-else :class="userI < obj.owners.length-1?'mr-1':''" shape="circle" :size="28">{{(user?.firstName?.[0]?.toUpperCase() || '') + (user?.lastName?.[0]?.toUpperCase() || '')}}</a-avatar>
                        </a-tooltip>
                    </div>
                </template>
            </a-col>
        </a-row>
        <a-row v-else :gutter="16" type="flex" style="margin-left: 0; margin-right: 0;" class="mt-3">
            <a-col :span="16" style="padding: 0;">
                <div class="dF" style="color: #9EA0A5; align-items: center;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12.048 12.049" fill="#9ea0a5"> <path id="Icon_awesome-comment-alt" data-name="Icon awesome-comment-alt" class="cls-1" d="M10.542,0H1.506A1.507,1.507,0,0,0,0,1.506V8.283A1.507,1.507,0,0,0,1.506,9.789H3.765v1.977a.283.283,0,0,0,.449.228l2.939-2.2h3.388a1.507,1.507,0,0,0,1.506-1.506V1.506A1.507,1.507,0,0,0,10.542,0Z"/> </svg>
                    <div class="ml-1 mr-2">{{obj.notes.length}}</div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18.622 19.049" style="margin-top: -4px;" fill="#9ea0a5"><g id="Group_4967" data-name="Group 4967" transform="translate(-476.378 -389)"> <path id="Icon_awesome-check-square" data-name="Icon awesome-check-square" d="M13.617,17.5H1.634A1.634,1.634,0,0,1,0,15.867V3.884A1.634,1.634,0,0,1,1.634,2.25H13.617a1.634,1.634,0,0,1,1.634,1.634V15.867A1.634,1.634,0,0,1,13.617,17.5ZM6.649,14.163,12.913,7.9a.545.545,0,0,0,0-.77l-.77-.77a.545.545,0,0,0-.77,0L6.264,11.467,3.879,9.082a.545.545,0,0,0-.77,0l-.77.77a.545.545,0,0,0,0,.77l3.541,3.541a.545.545,0,0,0,.77,0Z" transform="translate(476.378 390.547)"/> <g v-if="hasActiveTasks(obj)" id="Components_Badges_Number" data-name="Components/Badges/Number" transform="translate(487.292 389)"> <g id="Components_Badges_Number-2" data-name="Components/Badges/Number" transform="translate(0 0)"> <g id="Inner" transform="translate(0)"> <circle id="Oval" fill="#f7941e" cx="4" cy="4" r="4" transform="translate(-0.292)"/> </g> </g> </g> </g> </svg>
					<div class="ml-1">{{ taskCount }}</div>
                </div>
            </a-col>
            <a-col :span="8" class="dF" style="justify-content: flex-end; padding: 0;">
                <template v-for="(user,userI) in obj.owners">
                    <div v-if="userI < 2" :key="user+userI">
                        <a-avatar v-if="user.avatar && user.avatar !== ''" :class="userI < obj.owners.length-1?'mr-1':''" :src="user.avatar" shape="circle" :size="28"/>
                        <a-avatar v-else :class="userI < obj.owners.length-1?'mr-1':''" shape="circle" :size="28">{{(user?.firstName?.[0]?.toUpperCase() || '') + (user?.lastName?.[0]?.toUpperCase() || '')}}</a-avatar>
                    </div>
                </template>
            </a-col>
        </a-row>
    </a-card>
</template>

<script>

import moment from 'moment'

export default {
    props:['obj'],
    data(){
        return {
            userSource:[],
            assignees:[],
			taskCount:0,
        }
    },
    computed: {
		dateFormat(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
        origProducts(){
            return this.$store.state.products
        },
        instance() {
            return this.$store.state.instance
        },
        allContacts(){
            return this.$store.state.contacts.allContacts
        },
        storeUsers(){
            return this.$store.state.contacts.users
        },
		nylasAccount() {
			return this.$store.state.contacts.nylasAccount || {}
		}
    },
    methods:{
		hasActiveTasks(obj){
			let completed = true;
			let taskCount = 0;
			obj.tasks.forEach(x => {
				if (!x.completed){
					completed = false;
					taskCount++;
				}
			})
			this.taskCount = taskCount;
			return !completed
		},
        checkAvailability(prod){
            let prodId = prod.product
            if (this.instance.productType == 'lowrise'){
                let findProduct = this.origProducts.find(x => x.id == prodId)
                if (findProduct){
                    return !['available', 'approved_for_reservation', 'reserved'].includes(findProduct.status);
                } else {
                    return false
                }
            } else if (this.instance.productType == 'highrise'){
                let floorId = prod.floor
                let findFloor = this.origProducts.find(x => x.id == floorId)
                if (findFloor){
                    let findProduct = findFloor.units.find(x => x.id == prodId)
                    if (findProduct){
                        return !['available', 'approved_for_reservation', 'reserved'].includes(findProduct.salesStatus)
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
        },
        emailContact(contactId) {
            if (contactId != '') {
				if (this.nylasAccount && this.nylasAccount.email){
					this.$store.commit('ADD_LEAD_ACTION', contactId)
					this.$store.commit('ADD_NEW', 'Email')
				} else {
					this.$notification['error']({
						message: 'No Email Connection',
						description: 'You have not connected your mail account. To connect, go to Settings and select Email & Calendar Settings > Integrations.',
						duration: 5
					});
				}
            }
        },
        addNote(obj) {
            this.$store.commit('ADD_NEW', 'Note')
            this.$store.commit('ADD_OPPORTUNITY_ACTION', obj.id)
        },
        addTask(obj) {
            this.$store.commit('ADD_NEW', 'Task')
            this.$store.commit('ADD_OPPORTUNITY_ACTION', obj.id)
        },
        addAppointment(obj) {
            this.$store.commit('ADD_NEW', 'Appointment')
            this.$store.commit('ADD_OPPORTUNITY_ACTION', obj.id)
        },
        moment,
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        searchAssign(input){
            if(input && input.length>1) {
                this.storeUsers.forEach(x => {
                    x.name = x.firstName+ ' ' + x.lastName
                })
                this.userSource = this.storeUsers.filter(({name}) => {
                    name = name.toLowerCase()
                    input = input.toLowerCase()
                    return name.includes(input)
                })
            } else {
                return this.userSource = []
            }
        },
        saveAssignee(obj){
            let list = []
            obj.owners.forEach(x => {
                list.push(x.id)
            })
            this.assignees.forEach(a => {
                let index = list.findIndex(x => x == a)
                if (index == -1) {
                    list.push(a)
                }
            })
            let sendObj = JSON.parse(JSON.stringify(obj))
            sendObj.owners = list
            this.$api.put(`/opportunities/:instance/${sendObj.id}`, sendObj).then( ({data}) => {
                this.$emit('update',data)
                this.assignees = []
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        convertDate(num){
			let x = new Date(num)
			//Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
			let year = x.getFullYear()
			let month = x.getMonth()+1
			let day = x.getDate()
			if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
			else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
			else {
				return month+'/'+day+'/'+year
			}
		},
        opportunityDetails(obj){
            this.$store.commit('OPEN_OPPORTUNITY_DETAILS',obj)
        },
    },
	created(){
	}
}
</script>

<style lang="scss">
    .salesCard .ant-card-body {
        padding: 15px;
    }
    .popoverStyle .ant-popover-inner-content {
        padding: 0;
        background-color: white;
        border-radius: 20px;
    }

</style>

<style lang="scss" scoped>
    .more-option-icon{
        width: 24px;
        height: 24px;
        border-radius: 24px;
        padding:4px 0;
        text-align: center;
        background-color: transparent;
    }
    .more-option-icon:hover{
        color: var(--orange) !important;
        cursor: pointer;
        background-color: var(--light-gray);
	}

    .popoverContent{
        height: 35px;
        width: 160px;
        line-height: 35px;
        padding-left: 10px;
    }
    .popoverContent:hover{
        background-color: var(--off-white-light);
        cursor: pointer;
        color:#000;
    }
</style>
