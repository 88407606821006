<template>
    <div>
        <a-input
            v-if="recipient || type === 'forward'"
            addon-before="To"
            v-model="recipient"
            :disabled="type === 'reply'"
        />
		<div :key="refresh + '-editor'">
        	<editor-content class="editor__content" :editor="editor" />
		</div>

        <div class="menu_buttons">
            <div v-if="editor" class="dF aC">
                <a-icon
                    class="menubar-button"
                    @click="editor.chain().focus().toggleBold().run()"
                    :class="{ 'is-active': editor.isActive('bold') }"
                    type="bold"
                />
                <a-icon
                    class="menubar-button"
                    @click="editor.chain().focus().toggleItalic().run()"
                    :class="{ 'is-active': editor.isActive('italic') }"
                    type="italic"
                />
				<a-icon
                    class="menubar-button"
                    @click="editor.chain().focus().toggleUnderline().run()"
                    :class="{ 'is-active': editor.isActive('underline') }"
                    type="underline"
                />
				<a-icon
                    class="menubar-button"
                    @click="editor.chain().focus().setTextAlign('left').run()"
                    :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
                    type="align-left"
                />
				<a-icon
                    class="menubar-button"
                    @click="editor.chain().focus().setTextAlign('center').run()"
                    :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
                    type="align-center"
                />
				<a-icon
                    class="menubar-button"
                    @click="editor.chain().focus().setTextAlign('right').run()"
                    :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
                    type="align-right"
                />
                <a-icon
                    class="menubar-button"
                    @click="editor.chain().focus().toggleBulletList().run()"
                    :class="{ 'is-active': editor.isActive('bulletList') }"
                    type="unordered-list"
                />
                <a-icon
                    class="menubar-button"
                    @click="editor.chain().focus().toggleOrderedList().run()"
                    :class="{ 'is-active': editor.isActive('orderedList') }"
                    type="ordered-list"
                />
				<a-icon
                    class="menubar-button"
                    @click="addImage"
                    type="picture"
                />
				<a-popover :visible="showLinkOptionsBoolean" title="Apply Link" trigger="click">
					<div class="button-editor-container" slot="content">

						<template>
							<p style="line-height:1" class="my-2">External Link (URL)</p>
							<a-input v-model="form.value" />
						</template>

						<div class="mt-4 dF" style="gap:1ch;">
							<a-button type="primary" @click="applyLink">Apply</a-button>
							<a-button @click="showLinkOptionsBoolean = false">Cancel</a-button>
						</div>
					</div>
					<a-tooltip title="Link" placement="top" overlayClassName="change-tooltip-color">
						<a-icon @click="showLinkOptions" class="menubar-button" type="link" :class="{'is-active':editor.isActive('link')}" ></a-icon>
					</a-tooltip>
				</a-popover>
				<a-popover :visible="showFontSizeBoolean" title="Apply Font Size" trigger="click">
					<div class="button-editor-container" slot="content">

						<template>
							<p style="line-height:1" class="my-2">Font Size</p>
							<a-input-number :min="8" :max="48" v-model="fontSize" />
						</template>

						<div class="mt-4 dF" style="gap:1ch;">
							<a-button type="primary" @click="applyFontSize">Apply</a-button>
							<a-button @click="showFontSizeBoolean = false">Cancel</a-button>
						</div>
					</div>
					<a-tooltip title="Font Size" placement="top" overlayClassName="change-tooltip-color">
						<a-icon @click="showFontSizeOptions" class="menubar-button" type="font-size" :class="{'is-active':editor.isActive('textStyle')}" ></a-icon>
					</a-tooltip>
				</a-popover>
				<a-tooltip title="Text Color" placement="top" overlayClassName="change-tooltip-color">
					<div class="colorpicker email-template menubar-button" :class="{ 'is-active': editor.isActive('textStyle', { color: '#958DF1' })}">
						<ColorPicker :value="editor.getAttributes('textStyle').color || '#aaa'" :key="editor.getAttributes('textStyle').color" @input="setColor" style="width:20px;height:20px; overflow: hidden; border-radius: 50%;" :style="`background-color: ${editor.getAttributes('textStyle').color || '#aaa'}`" />
					</div>
				</a-tooltip>
				<a-tooltip title="Highlight Color" placement="top" overlayClassName="change-tooltip-color">
					<div class="colorpicker email-template menubar-button" :class="{ 'is-active': editor.isActive('highlight', { color: '#958DF1' })}">
						<ColorPicker :value="editor.getAttributes('highlight').color || '#aaa'" :key="editor.getAttributes('highlight').color" @input="setHighlight" style="width:20px;height:20px; overflow: hidden; border-radius: 50%;" :style="`background-color: ${editor.getAttributes('highlight').color || '#aaa'}`" />
					</div>
				</a-tooltip>
            </div>

            <div v-if="template" class="dF">
                <div class="dF aC mr-3">
                    <a-popover
                        overlayClassName="popoverStyleEmail"
                        placement="bottom"
                        trigger="click"
                    >
                        <div style="width: 250px" slot="content">
                            <div style="padding: 0 14px">
                                <a-input size="large" placeholder="Search" />
                            </div>
                            <div
                                @click="displayTemplate(template)"
                                class="display_template"
                                style="cursor: pointer"
                                v-for="(template, templateI) in templateList"
                                :key="templateI"
                            >
                                {{ template.name }}
                            </div>
                            <hr style="margin: 0" />
                            <div
                                style="padding: 14px"
                                @click="$emit('saveTemplate', updatedHTML)"
                                class="saveTemplate_button_Editor"
                            >
                                <div class="dF aC" style="cursor: pointer">
                                    <a-icon
                                        style="color: var(--orange)"
                                        type="plus"
                                    />
                                    Save as Template
                                </div>
                            </div>
                        </div>
                        <div class="dF aC" style="cursor: pointer">
                            <i
                                class="fe fe-type mr-2"
                                style="
                                    color: var(--orange);
                                    -webkit-text-stroke-width: thick;
                                "
                            />
                            Plain Text Templates
                            <a-icon class="ml-2" type="caret-down" />
                        </div>
                    </a-popover>
                </div>

                <div
                    class="dF aC"
                    v-if="templates && templates.length && false"
                >
                    <a-popover
                        overlayClassName="popoverStyleEmail"
                        placement="bottom"
                        trigger="click"
                    >
                        <div slot="content">
                            <div style="padding: 0 14px">
                                <a-input size="large" placeholder="Search" />
                            </div>
                            <div style="overflow-y: scroll; max-height: 200px">
                                <div
                                    @click="selectEmailBuilderTemplate(temp)"
                                    class="display_template"
                                    style="cursor: pointer"
                                    v-for="(temp, tempI) in templates"
                                    :key="tempI"
                                >
                                    {{ temp.name }}
                                </div>
                            </div>
                            <hr style="margin: 0" />
                        </div>
                        <div class="dF aC" style="cursor: pointer">
                            <svg
                                class="mr-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 17 17"
                                fill="#9ea0a5"
                            >
                                <path
                                    id="Icon_awesome-envelope-open-text"
                                    data-name="Icon awesome-envelope-open-text"
                                    class="cls-1"
                                    d="M5.844,7.172h5.313a.531.531,0,0,0,.531-.531V6.109a.531.531,0,0,0-.531-.531H5.844a.531.531,0,0,0-.531.531v.531A.531.531,0,0,0,5.844,7.172ZM5.313,9.828a.531.531,0,0,0,.531.531h5.313a.531.531,0,0,0,.531-.531V9.3a.531.531,0,0,0-.531-.531H5.844a.531.531,0,0,0-.531.531ZM8.5,13.85a2.653,2.653,0,0,1-1.556-.5L0,8.329v7.077A1.594,1.594,0,0,0,1.594,17H15.407A1.594,1.594,0,0,0,17,15.406V8.329l-6.944,5.016A2.655,2.655,0,0,1,8.5,13.85Zm7.89-8.44c-.294-.23-.572-.447-.983-.757V3.187a1.594,1.594,0,0,0-1.594-1.594H11.238l-.3-.218C10.38.968,9.271-.012,8.5,0,7.73-.012,6.621.968,6.063,1.376l-.3.218H3.188A1.594,1.594,0,0,0,1.594,3.187V4.653c-.411.31-.689.527-.983.757A1.594,1.594,0,0,0,0,6.665v.354l3.188,2.3V3.187H13.813V9.321L17,7.018V6.665A1.594,1.594,0,0,0,16.39,5.41Z"
                                    transform="translate(0 0)"
                                />
                            </svg>
                            Email Builder Templates
                            <a-icon class="ml-2" type="caret-down" />
                        </div>
                    </a-popover>
                </div>
            </div>
        </div>
        <a-form-model-item class="mt-4" label="Attachments" prop="attachments">
            <div>
                <a-button
                    @click="uploadFiles"
                >
                    <a-icon type="upload" /> Upload
                </a-button>
            </div>
            <div v-if="fileList.length">
                <div
                    class="dF aC"
                    v-for="(file, fileI) in fileList"
                    :key="`filePreview${fileI}`"
                >
                    <div style="flex: 1">
                        <strong>{{ file.name }}</strong>
                    </div>
                    <i
                        style="text-alight: end; cursor: pointer"
                        class="fe fe-trash-2"
                        @click="fileList.splice(fileI, 1)"
                    />
                </div>
            </div>
            <div v-else>
                <div><strong>No selected files</strong></div>
            </div>
        </a-form-model-item>
        <template v-if="!addTemplate">
            <div
                class="dF mt-3"
                style="justify-content: flex-end"
                v-if="type != 'taskComment'"
            >
                <a-button
                    @click="$emit('close')"
                    type="secondary"
                    size="large"
                    class="mr-3 cancel-button"
                    >{{
                        cancelButton !== "" ? cancelButton : "DISCARD "
                    }}</a-button
                >
                <a-button
                    @click="
                        $emit('previewEmail', {
                            html: updatedHTML,
                            attachments: fileList,
							recipient
                        })
                    "
                    type="primary"
                    size="large"
                    >{{ okButton !== "" ? okButton : "PREVIEW" }}</a-button
                >
            </div>
        </template>
        <template v-else>
            <div class="dF jE mt-3">
                <a-button
                    @click="$emit('close')"
                    type="secondary"
                    size="large"
                    class="mr-3 cancel-button"
                    >CANCEL</a-button
                >
                <a-button
                    @click="$emit('saveTemplate', {
						html: updatedHTML,
						attachments: fileList
					})"
                    type="primary"
                    size="large"
                    >SAVE</a-button
                >
            </div>
        </template>
    </div>
</template>

<script>
import tippy from "tippy.js";
import { Editor, EditorContent, VueRenderer } from "@tiptap/vue-2";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Underline from '@tiptap/extension-underline';
import TextStyle from '@tiptap/extension-text-style';
import { Color } from '@tiptap/extension-color'
import Highlight from '@tiptap/extension-highlight'
import { FontSize } from 'tiptap-extension-font-size';
import TextAlign from '@tiptap/extension-text-align';
import Image from '@tiptap/extension-image'
import Text from "@tiptap/extension-text";
import Link from '@tiptap/extension-link'
import Mention from "@tiptap/extension-mention";
import MentionList from "@/components/contacts/MentionList";
import StarterKit from "@tiptap/starter-kit";

export default {
    props: {
        addTemplate: {
            type: Boolean,
            default: false,
        },
        showMentions: {
            type: Boolean,
            default: true,
        },
        message: {
            default: "",
            type: String,
        },
        recipient: {
            default: "",
            type: String,
        },
        template: {
            default: false,
            type: Boolean,
        },
		editTemplate: {
			default: () => {},
			type: Object
		},
        okButton: {
            default: "",
            type: String,
        },
        cancelButton: {
            default: "",
            type: String,
        },
        type: {
            default: "",
            type: String,
        },
        activeButton: {
            default: false,
            type: Boolean,
        },
        templates: {
            default: () => [],
            type: Array,
        },
    },
    components: {
        EditorContent,
		ColorPicker: () => import('@/components/common/ColorPicker'),
    },
    data() {
        return {
			form:{
				type: "link",
				value:'',
			},
			fontSize: 16,
            editor: null,
            limit: 280,
            updatedHTML: "",
            fileList: [],
			showLinkOptionsBoolean:false,
			showFontSizeBoolean:false,
			refresh:0
        };
    },

    computed: {
        users() {
            return ["Full Name", "First Name", "Last Name", "Phone Number", "Email Address"];
        },
        templateList() {
            return this.$store.state.contacts.allSettings.app.options
                .templateList;
        },
    },
    methods: {
		async loadEditor(){
			if (this.editor) await this.editor.destroy();
			if (this.showMentions) {
				this.editor = new Editor({
					extensions: [
						Document,
						Paragraph,
						Underline,
						TextStyle,
						Color,
						Highlight.configure({ multicolor: true }),
						FontSize,
						TextAlign.configure({
							types: ['heading', 'paragraph'],
						}),
						Image.configure({
							HTMLAttributes: {
								style: 'max-width: 275px; height: auto;',
							},
							inline: true,
						}),
						Text,
						StarterKit,
						Link.configure({
							openOnClick: false,
							HTMLAttributes: {
								class: 'editor-html-link',
								style: 'text-decoration:underline;color: #f7941e;cursor: pointer;',
							},
						}),
						Mention.configure({
							HTMLAttributes: {
								class: "mention",
							},
							suggestion: {
								items: (query) => {
									if(!query || !query.query) return this.users
									return this.users
										.filter((item) =>
											item && item.toLowerCase().startsWith(query && query.query && query.query.toLowerCase())
										)
										.slice(0, 5);
								},
								render: () => {
									let component;
									let popup;

									return {
										onStart: (props) => {
											component = new VueRenderer(
												MentionList,
												{
													parent: this,
													propsData: props,
												}
											);

											popup = tippy("body", {
												getReferenceClientRect:
													props.clientRect,
												appendTo: () => document.body,
												content: component.element,
												showOnCreate: true,
												interactive: true,
												trigger: "manual",
												placement: "bottom-start",
											});
										},
										onUpdate(props) {
											component.updateProps(props);
											console.log("heillo", props);
											popup[0].setProps({
												getReferenceClientRect:
													props.clientRect,
											});
										},
										onKeyDown(props) {
											if (
												component.ref &&
												component.ref.onKeyDown
											) {
												return component.ref.onKeyDown(
													props
												);
											}
										},
										onExit() {
											console.log("exit");
											popup && popup[0] && popup[0].destroy();
											component.destroy();
										},
									};
								},
							},
						}),
					],
					onStart: () => {
						console.log("this thing starts");
					},
					onUpdate: () => {
						this.updatedHTML = this.editor.view.dom.innerHTML;
						console.log("update", this.editor);
					},
					onBlur: () => {
						if (this.updatedHTML == null) {
							this.updatedHTML = "";
						}
						console.log("HTML", this.updatedHTML);
						// this.$emit('save', this.updatedHTML)
					},
					content: this.message,
				});
			} else {
				this.editor = new Editor({
					extensions: [
						Document,
						Paragraph,
						Underline,
						TextStyle,
						Color,
						Highlight.configure({ multicolor: true }),
						FontSize,
						TextAlign.configure({
							types: ['heading', 'paragraph'],
						}),
						Image.configure({
							HTMLAttributes: {
								style: 'max-width: 275px; height: auto;',
							},
							inline: true,
						}),
						Text,
						StarterKit,
						Link.configure({
							openOnClick: false,
							HTMLAttributes: {
								class: 'editor-html-link',
								style: 'text-decoration:underline;color: #f7941e;cursor: pointer;',
							},
						}),
					],
					onStart: () => {
						console.log("this thing starts");
					},
					onUpdate: () => {
						this.updatedHTML = this.editor.view.dom.innerHTML;
						console.log("update", this.editor);
					},
					onBlur: () => {
						if (this.updatedHTML == null) {
							this.updatedHTML = "";
						}
						console.log("HTML", this.updatedHTML);
						// this.$emit('save', this.updatedHTML)
					},
					content: this.message,
				});
			}
		},
		uploadFiles(){
			this.$store.commit('SET_MULTIPLE_IMAGES', true)
			this.$store.commit('MEDIA_SELECT', {
				callback: this.selectFiles,
				type: 'all',
			})
		},
        selectFiles(item) {
			console.log('SELECT FILES', item)
			item.forEach(x => {
				this.fileList.push(x);
			})
        },
		setColor(val){
			this.editor.chain().focus().setColor(val).run()
		},
		setHighlight(val){
			this.editor.chain().focus().toggleHighlight({ color: val }).run()
		},
		setFontSize(size) {
			this.editor.chain().focus().setFontSize(`${size}px`).run()
			console.log(this.editor.getHTML());
		},
		addImage(){
			console.log('Adding image...')
			this.$store.commit('MEDIA_SELECT', {
				callback: this.selectImage,
				type: 'all',
			})
		},
		selectImage(item){
			this.editor.commands.setImage({ src: item.url })
		},

        displayTemplate(template) {
			console.log('DISPLAY TEMPLATE', template)
			if (template.attachments && template.attachments.length){
				this.fileList = JSON.parse(JSON.stringify(template.attachments))
			} else {
				this.fileList = []
			}
			console.log('FILE LIST', this.fileList)
            this.$store.commit("DISPLAY_MESSAGE", template.message);
			this.$message.success('Template loaded successfully');
			this.$emit('loadTemplate', template)
			this.loadEditor()
			this.refresh = Date.now()
        },
        selectEmailBuilderTemplate(template) {
            console.log("SELECT EMAIL BUILDER TEMPLATE", template);
        },
		setLink(url){
			this.editor
				.chain()
				.focus()
				.extendMarkRange('link')
				.setLink({ href: url })
				.run()
		},
		applyLink(){
			if (this.form.value && this.form.value.trim()){
				this.setLink(this.form.value)
			}

			this.showLinkOptionsBoolean = false
		},
		applyFontSize(){
			if (this.fontSize){
				this.setFontSize(this.fontSize)
			}

			this.showLinkOptionsBoolean = false
		},
		showLinkOptions(){
			console.log('HELLO')
			if (this.editor.isActive('link')) {
				return this.editor.chain().focus().unsetLink().run()
				// return this.editor
				// .chain()
				// .focus()
				// .updateAttributes('link', {
				//     href: 'https://duckduckgo.com'
				// })
			}

			this.showLinkOptionsBoolean = true
			let url = ''
			url = this.editor.getAttributes('link').href
			this.form.value = url

		},
		showFontSizeOptions(){

			if (this.editor.isActive('textStyle', { fontSize: `${this.fontSize}px` })) {
				return this.editor.chain().focus().setMark('textStyle', { fontSize: null }).run()
			}

			this.showFontSizeBoolean = true
			let fontSize = ''
			fontSize = this.editor.getAttributes('textStyle').fontSize
			this.fontSize = fontSize ? parseInt(fontSize) : 16
		},
    },
    beforeDestroy() {
        this.editor.destroy();
    },
    mounted() {
		if (this.editTemplate && this.editTemplate.attachments && this.editTemplate.attachments.length){
			this.fileList = JSON.parse(JSON.stringify(this.editTemplate.attachments))
		} else {
			this.fileList = []
		}
		this.loadEditor()
    },
};
</script>


<style>
.popoverStyleEmail .ant-popover-inner-content {
    padding: 12px 0 0 0;
}
.cancel-button.ant-btn:hover {
    border-color: #ece9f1 !important;
}
</style>
<style lang="scss">
/* Basic editor styles */
.ProseMirror {
	p {
		margin: 0;
	}
    > * + * {
        margin-top: 0.75em;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1;
    }
}

.editor__content .ProseMirror p {
	line-height: 2;
}

.mention {
    color: var(--orange);
    background-color: var(--off-white-dark);
    border-radius: 0.3rem;
    padding: 0.1rem 0.3rem;
}

.character-count {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    color: #68cef8;

    &--warning {
        color: #fb5151;
    }

    &__graph {
        margin-right: 0.5rem;
    }

    &__text {
        color: #868e96;
    }
}
</style>
<style lang="scss">
.email-template .bordered-avatar {
	border: 0 !important;
}
.edit__template .ProseMirror {
    // border:1px solid #d9d9d9;
    padding: 4px 11px;
    // height:100px;
    border-radius: 4px 4px 0px 0px;
    &:focus {
        border: 1px solid #d9d9d9;
        background-color: white;
        border: 1px var(--orange) solid !important;
    }
    &:hover {
        border: 1px var(--orange) solid !important;
    }
}
.task__comment .ProseMirror {
    border: 1px solid #d9d9d9;
    padding: 4px 11px;
    // height:100px;
    border-radius: 4px 4px 0px 0px;
    &:focus {
        background-color: white;
        border: 1px var(--orange) solid !important;
    }
    &:hover {
        border: 1px var(--orange) solid !important;
    }
}
.menu_buttons {
    display: flex;
    justify-content: space-between;
    background-color: var(--off-white-dark);
    padding: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 0px 0px 4px 4px;
}
.menubar-button {
    -webkit-text-stroke-width: thin;
    stroke: 2px;
    color: black;
    background: transparent;
    border: none;
    padding: 0.2rem 0.5rem;
    margin-right: 0.2rem;
    border-radius: 3px;
    cursor: pointer;
    &.is-active {
        color: var(--orange);
    }
}
</style>
<style scoped lang="scss">
.display_template {
    max-height: 100px;
    padding: 14px;
    &:hover {
        background-color: var(--off-white-dark);
    }
}

.editor-html-link {
	color: var(--primary);
	cursor: pointer;
	text-decoration: underline;
}
</style>
