<template>
	<div>
		<bhLoading :show="loading" />
		<div style="padding:20px 0; background-color:#FFF;">
			<a-table :rowKey="(e) => e.id" :columns="columns" :dataSource="workflows" :loading="loading"
				class="white-table" :scroll="{ x: 1100 }">
				<div slot="status" slot-scope="workflow">
					{{ statusObj[workflow.status] || workflow.status || 'In Progress' }}
				</div>
				<div slot="currentStep" slot-scope="workflow">
					{{ workflow.current_step }}
				</div>
                <div slot="stoppedReason" slot-scope="workflow">
					<span v-if="workflow.completedAt">
						Contact Exited
					</span>
					<span v-else>
						{{ workflow.stoppedReason || '' }}
					</span>
				</div>
                <div slot="stoppedAt" slot-scope="workflow">
					{{ $formatDate(workflow.completedAt || workflow.stoppedAt, 'lll') }}
				</div>
				<div slot="startedAt" slot-scope="workflow" class="text-center">
					{{ $formatDate(workflow.startedAt, 'lll') }}
				</div>
				<div slot="updatedAt" slot-scope="workflow">
					{{ $formatDate(workflow.updatedAt, 'lll') }}
				</div>
				<div slot="actions" slot-scope="workflow" class="dF jSA">
					<a-button type="primary" ghost @click="viewJourney(workflow)">
						View Journey
					</a-button>
				</div>
			</a-table>
		</div>
		<a-modal v-if="selectedWorkflowProgress" v-model="journeyModal" @cancel="journeyModal = false" :footer="null"
			title="Lead Journey" :width="1000">
			<h5 class="mb-3">Workflow: <strong> {{ selectedWorkflowProgress.name }}</strong></h5>
			<a-card :bodyStyle="{ padding: 0 }">
				<a-table :rowKey="(e) => e.id" :columns="journeyColumns"
					:dataSource="leadJourney[selectedWorkflowProgress.id]" :loading="loading" class="white-table">
					<div slot="stepAction" slot-scope="workflow">
						{{
							actionTypes[workflow.marketing_workflow_step.action] || workflow.marketing_workflow_step.name
						}}
					</div>
					<div slot="stepDescription" slot-scope="workflow">
						{{ stepDescription(workflow.marketing_workflow_step) }}
					</div>
					<div slot="createdAt" slot-scope="workflow">
						{{ $formatDate(workflow.createdAt, 'lll') }}
					</div>
				</a-table>
			</a-card>
		</a-modal>
	</div>
</template>

<script>
	import bhLoading from 'bh-mod/components/common/Loading'
	import moment from 'moment'
	import { formatDate } from 'bh-mod'

	export default {
		components: {
			bhLoading
		},
		props: {
			contact: {
				type: Object,
				default: () => { }
			}
		},
		computed: {
			marketingWorkflowList() {
				return this.$store.state.contacts.marketingWorkflowList
			},

			workflows() {
				if (!this.contact.workflow_progresses) return [];
				return this.contact.workflow_progresses.map(w => {
					const { readableId, name, steps = [] } = this.marketingWorkflowList[w.marketing_workflow] || {}
					const current_step = steps.find(s => s.id === w.current_step);
					const description = this.stepDescription(current_step)

					return {
						...w,
						readableId,
						name,
						description,
						current_step: current_step ? this.actionTypes[current_step.action] : w.current_step
					}
				}) || [];
			},

			leadTags() {
				return this.$store.state.contacts.tags
			},

			forms() {
				return this.$store.state.contacts.forms
			},

			taskTypes() {
				if (this.$store.state.contacts?.allSettings?.app?.options?.taskType?.length) {
					return this.$store.state.contacts.allSettings.app.options.taskType
				} else {
					return [
						{ name: 'To-do', id: 'todo' },
						{ name: 'Call', id: 'call' },
						{ name: 'Follow up', id: 'followup' }
					]
				}
			}
		},
		data() {
			return {
				loading: false,
				journeyModal: false,
				selectedWorkflowProgress: null,
				leadJourney: {},
				journeyColumns: [
					{
						title: "Step Action",
						key: "action",
						scopedSlots: { customRender: "stepAction" },
						sorter: (a, b) => a.marketing_workflow_step.action < b.marketing_workflow_step.action ? -1 : a.marketing_workflow_step.action > b.marketing_workflow_step.action ? 1 : 0,
					},
					{
						title: "Step Description",
						key: "description",
						scopedSlots: { customRender: "stepDescription" },
					},
					{
						title: "Date Started",
						key: "createdAt",
						scopedSlots: { customRender: "createdAt" },
						sorter: (a, b) => a.createdAt - b.createdAt,
					},
				],
				columns: [
					{
						title: "ID",
						key: "readableId",
						dataIndex: "readableId",
						sorter: (a, b) => a.readableId < b.readableId ? -1 : a.readableId > b.readableId ? 1 : 0,
					},
					{
						title: "Name",
						key: "name",
						dataIndex: "name",
						sorter: (a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
					},
                    {
						title: "Date Started",
						key: "startedAt",
						scopedSlots: { customRender: "startedAt" },
						sorter: (a, b) => a.startedAt - b.startedAt,
					},
					{
						title: "Current Step",
						key: "current_step",
						scopedSlots: { customRender: "currentStep" },
						sorter: (a, b) => a.current_step < b.current_step ? -1 : a.current_step > b.current_step ? 1 : 0,
					},
					{
						title: "Step Description",
						key: "description",
						dataIndex: "description",
						sorter: (a, b) => a.description < b.description ? -1 : a.description > b.description ? 1 : 0,
					},
                    {
						title: "Status",
						key: "status",
						scopedSlots: { customRender: "status" },
						sorter: (a, b) => a.status < b.status ? -1 : a.status > b.status ? 1 : 0,
					},
                    {
						title: "Stopped Reason",
						key: "stoppedReason",
						scopedSlots: { customRender: "stoppedReason" },
						sorter: (a, b) => a.stoppedReason < b.stoppedReason ? -1 : a.stoppedReason > b.stoppedReason ? 1 : 0,
					},
					{
						title: "Stopped At",
						key: "stoppedAt",
						scopedSlots: { customRender: "stoppedAt" },
						sorter: (a, b) =>
							moment(a.stoppedAt).format("x") -
							moment(b.stoppedAt).format("x"),
					},
					{
						title: "Date Updated",
						key: "updatedAt",
						scopedSlots: { customRender: "updatedAt" },
						sorter: (a, b) =>
							moment(a.updatedAt).format("x") -
							moment(b.updatedAt).format("x"),
						defaultSortOrder: 'descend'
					},
					{
						title: "",
						key: "actions",
						scopedSlots: { customRender: "actions" },
					},
				],
				statusObj: {
					'in_progress': 'In Progress',
					'completed': 'Completed',
					'stopped': 'Stopped'
				},
				actionTypes: {
					'entry_point': 'Entry Point: Tags/Forms/Zapier',
					'send_email': 'Send Email',
					'check_email_click': 'Check Email Click',
					'check_email_open': 'Check Email Open',
					'check_form_submission': 'Check Form Submission',
					'follow_up_task': 'Follow-up with leads',
					'wait': 'Time Delay',
					'add_tags': 'Add Tags',
					'remove_tags': 'Remove Tags',
					'add_lead_score': 'Add Lead Score',
					'reduce_lead_score': 'Reduce Lead Score',
					'conditional': 'If/Else Conditional',
					'contact_exit': 'Contact Exited'
				}
			}
		},
		methods: {
			moment, formatDate,

			async viewJourney(workflowProgress) {
				this.selectedWorkflowProgress = workflowProgress
				if (this.leadJourney[workflowProgress.id]) {
					this.journeyModal = true
				} else {
					this.loading = true;
					try {
						const { data } = await this.$api.get(`contact-workflow-progress-journeys?contact=${this.contact.id}&marketing_workflow=${workflowProgress.marketing_workflow}`);
						const journey = data;
						if (workflowProgress.completedAt) {
							journey.push({
								marketing_workflow_step: {
									action: 'contact_exit'
								},
								createdAt: workflowProgress.completedAt
							})
						}
						this.leadJourney[workflowProgress.id] = data
						this.journeyModal = true;
					} catch (error) {
						this.$toastError(error, 'Error occurred while fetching lead journey for selected workflow')
					} finally {
						this.loading = false;
					}
				}
			},

			stepTags(step) {
				if (step.actionData.tags) {
					return step.actionData.tags.map(tag => {
						return this.leadTags[tag] ? this.leadTags[tag].name : ''
					}).filter(tag => tag).join(', ')
				}
				return ''
			},

			stepForms(step) {
				if (step.actionData.forms) {
					return step.actionData.forms.map(form => {
						return this.forms[form] ? this.forms[form].name : ''
					}).filter(forms => forms).join(', ')
				}
				return ''
			},

			getLinkedStep(step) {
				let workflow = this.marketingWorkflowList[step.marketing_workflow];
				let linkedStep = null
				if (workflow && step.actionData?.stepId) {
					linkedStep = workflow.steps.find(s => s.stepId === step.actionData.stepId)
				}
				return linkedStep
			},

			stepDescription(step) {
				if (!step) return null;
				if (step.action === 'contact_exit') {
					return 'Contact Exited'
				} else if (step.action === 'entry_point') {
					let description = ''
					if (step.actionData.allContacts) {
						description += 'CRM: All Contacts'
					} else if (step?.actionData?.tags?.length) {
						description += ('Tags: ' + this.stepTags)
					}
					if (step?.actionData?.forms?.length) {
						if (description) {
							description += ' / '
						}
						description += ('Forms: ' + this.stepForms)
					}
					if (step.actionData?.addZapierLeadToWorkflow) {
						if (description) {
							description += ' / '
						}
						description += 'Zapier: All New Leads'
					}
					return description
				} else if (step.action === 'add_tags' || step.action === 'remove_tags') {
					if (step?.actionData?.tags?.length) {
						return ('Tags: ' + this.stepTags(step))
					}
				} else if (step.action === 'wait') {
					if (step.actionData.time && step.actionData.timeUnit) {
						return `${step.actionData.time} ${step.actionData.timeUnit} delay`
					}
				} else if (step.action === 'add_lead_score') {
					if (step.actionData.score !== null && step.actionData.score > -1) {
						return `+ ${step.actionData.score.toLocaleString("en-US", { useGrouping: true })}`
					}
				} else if (step.action === 'reduce_lead_score') {
					if (step.actionData.score !== null && step.actionData.score > -1) {
						return `- ${step.actionData.score.toLocaleString("en-US", { useGrouping: true })}`
					}
				} else if (['check_email_open', 'check_email_click'].includes(step.action)) {
					let linkedStep = this.getLinkedStep(step);
					if (linkedStep?.action === 'send_email' && linkedStep?.actionData?.emailSubject) {
						return linkedStep.actionData.emailSubject || ''
					}
				} else if (step.action === 'follow_up_task') {
					if (step.actionData.taskType) {
						const task = this.taskTypes.find(t => t.id === step.actionData.taskType)
						return (step.actionData.taskTitle || '') + (task ? ' - ' + task.name : '')
					}
				} else if (step.action === 'check_form_submission') {
					const form = this.forms[step.actionData.formId]
					return form ? form.name : ''
				} else if (step.action === 'send_email') {
					return step.actionData.emailSubject || step.name
				}

				return null
			}
		}
	}
</script>

<style scoped>
	.link {
		color: var(--orange);
	}

	.link:hover {
		text-decoration: underline;
		color: var(--orange);
	}
</style>
