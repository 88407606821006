<template>
	<div>
		<a-tabs class="taskTab" default-active-key="1">
			<a-tab-pane key="1" tab="Task Types">
				<TaskTypesTab />
			</a-tab-pane>

			<a-tab-pane key="2" tab="Sentiments">
				<SentimentsTab />
			</a-tab-pane>
		</a-tabs>
	</div>
</template>

<script>
	import TaskTypesTab from "@/components/contacts/TaskTypesTab";
	import SentimentsTab from "@/components/contacts/SentimentsTab"
	export default {
		components: {
			TaskTypesTab,
			SentimentsTab
		}
	}
</script>

<style lang="scss">
.taskTab .ant-tabs-nav {
    font-size:16px;
    .ant-tabs-tab {
        padding-left:25px;
        padding-right:25px;
    }
}

.taskTab .ant-tabs-bar {
    margin:0 0 25px 0
}
</style>
