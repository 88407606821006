import Vue from 'vue'
import Vuex from 'vuex'
import { $api } from 'bh-mod'
import { setProp, deleteProp } from 'bh-mod'
Vue.use(Vuex)

const buildQuery = (OBJ) => {
    let searchOBJ = JSON.parse(JSON.stringify(OBJ))
    if (!Object.keys(searchOBJ).length) return ''
    let query = ''
    Object.entries(searchOBJ).forEach(([key, obj]) => {
        obj.value = String(obj.value)
        if (!obj.value.trim()) return

        if (key.includes('tag')) {
            let tags = obj.value
            let str = ''
            if (key === 'tagI') {
                str = `tagI_${obj.type}=${tags}`
            } else {
                str = `tagE_${obj.type}=${tags}`
            }
            let ampersand = '&'
            if (query.length === 0) ampersand = ''
            query += `${ampersand}${str}`

        } else {
            let ampersand = '&'
            if (query.length === 0) ampersand = ''
            query += `${ampersand}${key}${obj.type}=${obj.value.trim()}`
        }
    })
    return query
}

export default {
    state: {
		appDataLoaded: false,
		queryParams:'',
        unsubscribedContactCount:0,
        leadStatusModal: {
            visible: false,
            object: {}
        },
        editDrawer: {
            visible: false,
            object: {}
        },
        columnsDrawer: {
            visible: false,
            object: {}
        },
        emailTemplate: {
            data: {},
            visible: false,
        },
        previewEmail: {
            data: {},
            visible: false,
        },
        users: [],
        completedTask: [],
        allSettings: {},
        loadingPage: false,
        searchTimeStamp: Date.now(),
        sortQuery:'_sort=createdAt:DESC',
        searchOBJ: {},
        searchQuery: '',
		multipleImages: false,
        showSearch: false,
        pageSize: 10,
        totalContacts: 0,
        newStoreTime: Date.now(),
        resultLength: 0,
        data: {}, //don't access this directly from Vue files
        getStarted: false,
        tags: {},
        appData: {},
        currentPage: 1,
        allContacts: {},
        externalContacts: {},
        queriedContacts: {},
        editingContact: '',
        appointments: [],
		events: [],
        opportunities: [],
        tasks: [],
        editTask: {
            task: {},
            visible: false,
        },
        editAppointment: {
            task: {},
            visible: false,
        },
		editEvent: {
            event: {}
        },
        contact: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            leadScore: 0,
            address: '',
            city: '',
            region: '',
            country: '',
            instance: '',
            tasks: [],
            source: '',
            sourceType: '',
            status: '',
            tags: [],
            customFields: [{
                id: '',
                value: '',
            }],
            subscribed: [],
        },
        appointment: {
            fromTime: 0,
            toTime: 0,
            note: '',
            contact: '',
        },
        modal: {
            show: false,
            index: null,
            type: '',
            settings: null,
            title: '',
            identifier: '',
            text: '',
            lot: null,
            confirm: () => {},
            load: false,
            editor: null
        },
        contactDrawer: {
            type: '',
            show: false
        },
        appointmentControl: {
            modal: false,
            drawer: false,
            contact: {}
        },
        mailModal: {
            show: false,
            load: false,
            contact: 'asdf'
        },
        taskControl: {
            modal: false,
            drawer: false,
            contact: {}
        },
        statusModal: {
            type: 'add',
            show: false
        },
        statuses: {

        },
		forms: {

		},
        fields: {

        },

        queryContact: {
            contact: {},
            time: 0,
        },
        changeType: {
            opportunity: {},
            time: 0,
        },

        contactDetails: {
            contact: {},
            show: false,
        },
        expandDetails: {
            contact: {},
            visible: false,
        },

        editNote: {
            note: {
                subject: '',
                content: '',
                objId: '',
            },
            type: '',
            visible: false
        },

        opportunityDetails: {
            opportunity: {},
            show: false,
        },
        expandOpportunity: {
            opportunity: {},
            visible: false,
        },
        message: '<p></p>',
        previewAccount: {},
        colourModal: {
            visible: false,
            object: {}
        },
        opportunityAction: '',
        leadAction: '',
        bulkEmail: [],
        filterDrawer: {
            visible: false,
            filter: {
                lastUpdateDate: [],
                inactiveDays: [],
                owners: ['any'],
                leadStatus: ['any'],
				source:['any'],
                opportunities: ['any'],
                tags: [],
                city: ['any'],
                region: ['any'],
                country: ['any'],
				unsub: ['any'],
                postal: '',
                hideInactive:false,
				isAgent:['any'],
				smsConsent:['any']
            },
            query: '',
            showFilter: false
        },
        filteredContacts: {},
        editContact: '',
        transactions: [],
        importLead: false,
        editModal: {
            visible: false,
            object: {}
        },
        addTextTemplate: false,
        vendors: {},
        integrations: {},
        inbox:[],
        moreLoaded:2,
        sentMoreLoaded:2,
        sent:[],
		sent_next_page_cursor: null,
		inbox_next_page_cursor: null,
        openedThreads:[],
        previewModal:{
            visible:false,
            object:{},
            type:''
        },
		maxLeadScore: null,
		nylasAccount: {},
		instance: {},
		marketingWorkflowList: [],
		contactTimeline: {},
		searchedContactList: [],
    },
    mutations: {
		SET_INSTANCE: (state, data) => {
			state.instance = data
		},
		SET_MULTIPLE_IMAGES(state, data){
			state.multipleImages = data
		},
        ADD_NEW_SOURCE(state){
            if (state.allSettings && state.allSettings.app && state.allSettings.app.options && state.allSettings.app.options.leadSource) {
                state.allSettings.app.options.leadSource.push('Imported')
            }
        },
        EDIT_LEAD_SOURCE(state, data) {
            if (state.allSettings && state.allSettings.app && state.allSettings.app.options && state.allSettings.app.options.leadSource) {
                state.allSettings.app.options.leadSource = data
            }
        },
        INCREASE_SENT_MORE_LOADED(state){
            state.sentMoreLoaded += 1
        },
        INCREASE_MORE_LOADED(state){
            state.moreLoaded += 1
        },
        UPDATE_GMAIL_VENDOR(state, data) {
            if (state.vendors.gmail) state.vendors.gmail.active = data.active
        },
        UPDATE_OUTLOOK_VENDOR(state, data) {
            if (state.vendors.outlook) state.vendors.outlook.active = data.active
        },
        UPDATE_OFFICE365_VENDOR(state, data) {
            if (state.vendors.office365) state.vendors.office365.active = data.active
        },
        UPDATE_TEAMS_VENDOR(state, data) {
            if (state.integrations.teams) state.integrations.teams.active = data.active
        },
        UPDATE_ZOOM_VENDOR(state, data) {
            if (state.integrations.zoom) state.integrations.zoom.active = data.active
        },
        OPEN_PREVIEW_MODAL(state,data) {
            state.previewModal.visible = true
            if (data.object) state.previewModal.object = data.object
            if (data.type) state.previewModal.type = data.type
        },
        CLOSE_PREVIEW_MODAL(state) {
            state.previewModal.visible = false
            state.previewModal.object = {}
            state.previewModal.type = ''
        },
        ADD_THREAD(state, data) {
            let index = state.openedThreads.findIndex(x => x.id == data.id)
            if (index == -1) {
                state.openedThreads.push(data)
            }
        },
        ADD_INBOX(state, data) {
            if (state.inbox.length || state.sent.length) {
                let index = state.inbox.findIndex(x => x.id == data.threadId)
                if (index != -1) {
                    data.email.id = Date.now().toString()
                    state.inbox[index].messages.push(data.email)
                }
                let sentIndex = state.sent.findIndex(x => x.id == data.threadId)
                if (index != -1) {
                    data.email.id = Date.now().toString()
                    state.sent[sentIndex].messages.push(data.email)
                }
            }
        },
		STORE_INBOX_NEXT_PAGE_CURSOR(state,data) {
            state.inbox_next_page_cursor = data
        },
        STORE_INBOX(state, data) {
            state.inbox = data
        },
        UPDATE_INBOX(state, data) {
			let ids = data.map(x => x.id)
            state.inbox = state.inbox.filter(x => !ids.includes(x.id))
            state.inbox = [...state.inbox, ...data]
        },
        NEW_INBOX(state, data) {
            let ids = data.map(x => x.id)
            state.inbox = state.inbox.filter(x => !ids.includes(x.id))
            state.inbox = [...data,...state.inbox]
        },
		STORE_SENT_NEXT_PAGE_CURSOR(state,data) {
            state.sent_next_page_cursor = data
        },
        STORE_SENT(state,data) {
            state.sent = data
        },
        UPDATE_SENT(state,data) {
			let ids = data.map(x => x.id)
            state.sent = state.sent.filter(x => !ids.includes(x.id))
            state.sent = [...state.sent,...data]
        },
        NEW_SENT(state,data) {
            let ids = data.map(x => x.id)
            state.sent = state.sent.filter(x => !ids.includes(x.id))
            state.sent = [...data,...state.sent]
        },
        ADD_TEXT_TEMPLATE(state) {
            state.addTextTemplate = true
        },
        CLEAR_TEXT_TEMPLATE(state) {
            state.addTextTemplate = false
        },
        UPDATE_LEADSTATUS(state, data) {
            let newObj = JSON.parse(JSON.stringify(state.statuses))
            if (newObj[data.id]) {
                newObj[data.id] = data
                state.statuses = newObj
            }
        },
        UPDATE_TAG(state, data) {
            let newObj = JSON.parse(JSON.stringify(state.tags))
            if (newObj[data.id]) {
                newObj[data.id] = data
                state.tags = newObj
            }
        },
        OPEN_EDIT_MODAL(state, data) {
            state.editModal.visible = true
            state.editModal.object = data
        },
        CLOSE_EDIT_MODAL(state) {
            state.editModal.visible = false
            state.editModal.object = {}
        },
        IMPORT_LEAD(state) {
            state.importLead = true
        },
        DELETE_IMPORT_LEAD(state) {
            state.importLead = false
        },
        EDIT_CONTACT(state, data) {
            state.editContact = data
        },
        BULK_EMAIL(state, data) {
            state.bulkEmail = data
        },
        CLEAR_BULK_EMAIL(state) {
            state.bulkEmail = []
        },
        OPEN_LEAD_STATUS(state, data) {
            state.leadStatusModal.visible = true
            state.leadStatusModal.object = data
        },
        CLOSE_LEAD_STATUS(state) {
            state.leadStatusModal.visible = false
            state.leadStatusModal.object = {}
        },
        APPLY_FILTER(state, data) {
            state.filterDrawer.filter = data.filter
            state.filteredContacts = data.contacts
            state.filterDrawer.showFilter = data.showFilter
        },
        OPEN_FILTER_DRAWER(state) {
            state.filterDrawer.visible = true
        },
        CLOSE_FILTER_DRAWER(state, data) {
            state.filterDrawer.visible = false
            state.filterDrawer.filter = data
        },
        EDIT_SORT_QUERY(state,data) {
            state.sortQuery = data
        },
        EDIT_QUERY(state, data) {
			state.currentPage = 1;
            state.filterDrawer.query = data
        },
        DELETE_NOTE(state, data) {
            let find = Object.values(state.allContacts).find(x => {
                if (x.notes && x.notes.length && typeof x.notes[0] == 'object') {
                    let ids = x.notes.map(x => x = x.id)
                    if (ids.includes(data.id)) return x
                } else if (x.notes && x.notes.length && typeof x.notes[0] == 'string') {
                    if (x.notes.includes(data.id)) return x
                }
            })
            if (find) {
                let index = find.notes.findIndex(x => x.id == data.id)
                if (index != -1) {
                    find.notes.splice(index, 1)
                }
            } else {
                let indexOpp = state.opportunities.findIndex(x => {
                    if (x.notes && x.notes.length) {
                        let ids = x.notes.map(x => x = x.id)
                        if (ids.includes(data.id)) return x
                    }
                })
                if (indexOpp != -1) {
                    let index = state.opportunities[indexOpp].notes.findIndex(x => x.id == data.id)
                    if (index != -1) state.opportunities[indexOpp].notes.splice(index, 1)
                } else {
                    find = Object.values(state.externalContacts).find(x => {
                        if (x.notes && x.notes.length && typeof x.notes[0] == 'object') {
                            let ids = x.notes.map(x => x = x.id)
                            if (ids.includes(data.id)) return x
                        } else if (x.notes && x.notes.length && typeof x.notes[0] == 'string') {
                            if (x.notes.includes(data.id)) return x
                        }
                    })
                    if (find) {
                        let index = find.notes.findIndex(x => x.id == data.id)
                        if (index != -1) {
                            find.notes.splice(index, 1)
                        }
                    }
                }
            }
        },
        UPDATE_NOTE(state, data) {
            let find = Object.values(state.allContacts).find(x => {
                if (x.notes && x.notes.length && typeof x.notes[0] == 'object') {
                    let ids = x.notes.map(x => x = x.id)
                    if (ids.includes(data.id)) return x
                } else if (x.notes && x.notes.length && typeof x.notes[0] == 'string') {
                    if (x.notes.includes(data.id)) return x
                }
            })
            if (find) {
                let index = find.notes.findIndex(x => x.id == data.id)
                if (index != -1) {
                    find.notes[index] = data
                }
            } else {
                let indexOpp = state.opportunities.findIndex(x => {
                    if (x.notes && x.notes.length) {
                        let ids = x.notes.map(x => x = x.id)
                        if (ids.includes(data.id)) return x
                    }
                })
                if (indexOpp != -1) {
                    let index = state.opportunities[indexOpp].notes.findIndex(x => x.id == data.id)
                    if (index != -1) state.opportunities[indexOpp].notes[index] = data
                } else {
                    find = Object.values(state.externalContacts).find(x => {
                        if (x.notes && x.notes.length && typeof x.notes[0] == 'object') {
                            let ids = x.notes.map(x => x = x.id)
                            if (ids.includes(data.id)) return x
                        } else if (x.notes && x.notes.length && typeof x.notes[0] == 'string') {
                            if (x.notes.includes(data.id)) return x
                        }
                    })
                    if (find) {
                        let index = find.notes.findIndex(x => x.id == data.id)
                        if (index != -1) {
                            find.notes[index] = data
                        }
                    }
                }
            }
        },
        UPDATE_OPPORTUNITIES(state, data) {
            let index = state.opportunities.findIndex(x => x.id == data.id)
            if (index != -1) {
                state.opportunities.splice(index, 1)
            }
        },
        OPEN_EDIT_DRAWER(state, data) {
            state.editDrawer.visible = true
            state.editDrawer.object = data
        },
        CLOSE_EDIT_DRAWER(state) {
            state.editDrawer.visible = false
            state.editDrawer.object = {}
        },
        OPEN_COLUMNS_DRAWER(state, data) {
            state.columnsDrawer.visible = true
            state.columnsDrawer.object = data
        },
        CLOSE_COLUMNS_DRAWER(state) {
            state.columnsDrawer.visible = false
            state.columnsDrawer.object = {}
        },
        UPDATE_OPPORTUNITY(state, data) {
            let index
            index = state.opportunities.findIndex(x => x.id == data.id)
            if (index != -1) {
                state.opportunities[index] = data
            }
        },
        ADD_OPPORTUNITY_ACTION(state, data) {
            state.opportunityAction = data
        },
        CLEAR_OPPORTUNITY_ACTION(state) {
            state.opportunityAction = ''
        },
        ADD_LEAD_ACTION(state, data) {
            state.leadAction = data
        },
        CLEAR_LEAD_ACTION(state) {
            state.leadAction = ''
        },
        OPEN_COLOUR(state, data) {
            state.colourModal.visible = true
            state.colourModal.object = data
        },
        CLOSE_COLOUR(state) {
            state.colourModal.visible = false
            state.colourModal.object = {}
        },
        DELETE_CUSTOM_FIELD(state, data) {
            let fields = JSON.parse(JSON.stringify(state.fields))
            delete fields[data.id]
            state.fields = fields
        },
        UPDATE_CUSTOM_FIELD(state, data) {
            state.fields[data.id] = data
        },
        UPDATE_QUERY_CONTACT(state, data) {
            state.queryContact = {
                contact: data,
                time: Date.now()
            }
        },
        CHANGE_TYPE(state, data) {
            state.changeType = {
                opportunity: data,
                time: Date.now()
            }
        },
        PREVIEW_ACCOUNT(state, data) {
            state.previewAccount = data
        },
        CLEAR_MESSAGE(state) {
            state.message = '<p></p>'
        },
        DISPLAY_MESSAGE(state, data) {
            state.message = data
        },
        SAVE_EMAIL_TEMPLATE(state, data) {
            state.emailTemplate.data = data
            state.emailTemplate.visible = true
        },
        CLOSE_EMAIL_TEMPLATE(state) {
            state.emailTemplate.data = {}
            state.emailTemplate.visible = false
        },
        CLOSE_PREVIEW_EMAIL(state) {
            state.previewEmail.data = {}
            state.previewEmail.visible = false
        },
        PREVIEW_EMAIL(state, data) {
            state.previewEmail.data = data
            state.previewEmail.visible = true
        },
        ADD_EXTERNAL_CONTACT(state, data) {
            state.externalContacts[data.id] = data
        },
        UPDATE_CONTACT_NOTES(state, data) {
            if (state.allContacts.hasOwnProperty(data.id)){
                let newObj = JSON.parse(JSON.stringify(state.allContacts))
				if(!newObj[data.id]){
					newObj[data.id] = {
						notes: data.notes
					}
				} else {
					newObj[data.id].notes = data.notes
				}
                state.allContacts = newObj
            }
            else {
                let newObj = JSON.parse(JSON.stringify(state.externalContacts))
				if(!newObj[data.id]){
					newObj[data.id] = {
						notes: data.notes
					}
				} else {
					newObj[data.id].notes = data.notes
				}
                state.externalContacts = newObj
            }
        },
        UPDATE_CONTACT_NOTE(state, data) {
            if (state.allContacts.hasOwnProperty(data.id)) {
                let allContacts = JSON.parse(JSON.stringify(state.allContacts))
                let index = allContacts[data.id].notes.findIndex(x => x.id == data.notes.id)
                allContacts[data.id].notes[index] = data.notes
                let notes = allContacts[data.id].notes

                state.allContacts[data.id].notes = notes
            } else {
                let externalContacts = JSON.parse(JSON.stringify(state.externalContacts))
                let index = externalContacts[data.id].notes.findIndex(x => x.id == data.notes.id)
                externalContacts[data.id].notes[index] = data.notes
                let notes = externalContacts[data.id].notes

                state.externalContacts[data.id].notes = notes
            }
        },
        UPDATE_OPPORTUNITY_NOTES(state, data) {
            let index = state.opportunities.findIndex(x => x.id == data.id)
            if (index != -1) state.opportunities[index].notes = data.notes
        },
        UPDATE_OPPORTUNITY_NOTE(state, data) {
            let opportunities = JSON.parse(JSON.stringify(state.opportunities))
            let index = opportunities.findIndex(x => x.id == data.id)
            let index2 = opportunities[index].notes.findIndex(x => x.id == data.notes.id)
            opportunities[index].notes[index2] = data.notes
            let notes = opportunities[index].notes

            state.opportunities[index].notes = notes
        },
        DELETE_CONTACT_NOTE(state, data) {
            if (state.allContacts.hasOwnProperty(data.id)) {
                let index = state.allContacts[data.id].notes.findIndex(x => x.id == data.note.id)
                if (index != -1) {
                    state.allContacts[data.id].notes.splice(index, 1)
                }
            } else {
                let index = state.externalContacts[data.id].notes.findIndex(x => x.id == data.note.id)
                if (index != -1) {
                    state.externalContacts[data.id].notes.splice(index, 1)
                }
            }
        },
        DELETE_OPPORTUNITY_NOTE(state, data) {
            let index = state.opportunities.findIndex(x => x.id == data.id)
            let index2 = state.opportunities[index].notes.findIndex(x => x.id == data.note.id)
            state.opportunities[index].notes.splice(index2, 1)
        },
        EDIT_NOTE(state, data) {
            state.editNote = data
        },
        CLOSE_EDIT_NOTE(state) {
            state.editNote = {
                visible: false,
                note: {
                    subject: '',
                    content: '',
                    objId: '',
                },
                type: '',
            }
        },
        DELETE_COMMENT(state, data) {
            let index = state.editTask.task.comments.findIndex(x => x.id == data.id)
            if (index != -1) {
                state.editTask.task.comments.splice(index, 1)
            }
        },
        UPDATE_COMMENT(state, data) {
            let index = state.editTask.task.comments.findIndex(x => x.id == data.id)
            if (index != -1) {
                state.editTask.task.comments[index] = data
            }
        },
        DELETE_STATUS(state, data) {
            deleteProp(state, ['statuses', data.id])
        },
        DELETE_TAG(state, data) {
            if (state.tags.hasOwnProperty(data.id)) {
                let obj = Object.assign({}, state.tags)
                delete obj[data.id]
                return state.tags = obj
            }
        },
        ADD_NEW_TAG(state, data) {
            let obj = Object.assign({}, state.tags)
            obj[data.id] = data
            return state.tags = obj
        },
        EDIT_TASK(state, data) {
            state.editTask = {
                task: data,
                visible: true
            }
        },
        CLOSE_EDIT_TASK(state) {
            return state.editTask = {
                task: {},
                visible: false,
            }
        },
        EDIT_APPOINTMENT(state, data) {
            return state.editAppointment = {
                appointment: data,
                visible: true,
            }
        },
		EDIT_EVENT(state, data) {
            return state.editEvent = {
                event: data
            }
        },
		CLOSE_EDIT_EVENT(state) {
            return state.editEvent = {
                event: {}
            }
        },
        ADD_APPOINTMENT(state, data) {
            state.appointments.push(data)
        },
		ADD_EVENT(state, data) {
            state.events.push(data)
        },
		UPDATE_EVENT(state, data) {
			let events = JSON.parse(JSON.stringify(state.events))
			let index = events.findIndex(x => x.id === data.id)
            events[index] = data
			state.events = events
		},
		DELETE_EVENT(state, data) {
			let events = JSON.parse(JSON.stringify(state.events))
            let index = events.findIndex(x => x.id == data.id)
            if (index != -1) {
                events.splice(index, 1)
            }
			state.events = events
        },
        UPDATE_APPOINTMENT(state, data) {
            let index = state.appointments.findIndex(x => x.id === data.id)
            state.appointments[index] = data
        },
        DELETE_APPOINTMENT(state, data) {
			let appointments = JSON.parse(JSON.stringify(state.appointments))
            let index = appointments.findIndex(x => x.id == data.id)
            if (index != -1) {
                appointments.splice(index, 1)
            }
			state.appointments = appointments
        },
		BULK_DELETE_APPOINTMENT(state, ids) {
			let appointments = JSON.parse(JSON.stringify(state.appointments))
			appointments = appointments.filter(x => !ids.includes(x.id))
			state.appointments = appointments
        },
        CLOSE_APPOINTMENT(state) {
            return state.editAppointment = {
                appointment: {},
                visible: false,
            }
        },
        UPDATE_TASK(state, data) {
            let index = state.tasks.findIndex(x => x.id === data.id)
            state.tasks[index] = data
            if (data.id == state.editTask.task.id) {
                state.editTask.task = data
            }
			let opportunities = state.opportunities
			if (data.hasOwnProperty('opportunity')){
				let foundIndex = opportunities.findIndex(x => data.opportunity && x.id == data.opportunity.id)
				if (foundIndex != -1){
					let opportunity = opportunities[foundIndex]
					let taskIndex = opportunity.tasks.findIndex(x => x.id == data.id)
					if (taskIndex != -1){
						opportunity.tasks[taskIndex] = data
					}
					opportunities[foundIndex] = opportunity
					state.opportunities = opportunities
				}
			}

        },
        UPDATE_ALL_TASKS(state, data) {
            delete data.appointments
            delete data.tasks
            state.tasks.forEach(x => {
                if (x.contact) {
                    if (x.contact.id == data.id) {
                        x.contact = data
                    }
                } else if(x.opportunity) {
                    if (x.opportunity.id == data.id) {
                        x.opportunity = data
                    }
                }

            })
        },
        UPDATE_ALL_OPPORTUNITIES(state, data) {
            delete data.appointments
            delete data.tasks
            state.opportunities.forEach(x => {
                if (x.contact.id == data.id) {
                    x.contact = data
                }
            })
        },
        DELETE_TASK(state, data) {
            let index = state.tasks.findIndex(x => x.id == data.id)
            if (index != -1) {
                state.tasks.splice(index, 1)
            }
        },
        DELETE_OPPORTUNITY(state, data) {
            let index = state.opportunities.findIndex(x => x.id == data.id)
            if (index != -1) {
                state.opportunities.splice(index, 1)
            }
        },
        UPDATE_TAGS(state, data) {
            state.tags[data.id] = data
        },
        ADD_NEW_TASK(state, data) {
            state.tasks.push(data)
        },
        SELECT_TASK(state, data) {
            state.completedTask = data
        },
        SET_SETTINGS(state, data) {
            let temp = data

            if (data.userApp === null) {
                temp.userApp = {
                    options: {
                        seenIntro: false,
                        meetingSettings:{
                            availableTimeSlots:[],
                            durationSlots:[],
                            meetingScheduleRollingWeekSlot:null,
                            minimumNoticeTimeSlot:null,
                            bufferTimeSlot:null,
                            startTimeIncrementSlot:null,
                            preMeetingReminderSlot:null,
                            meetingConfirmationToCustomer:true,
                            preMeetingReminderToCustomer:false,
							meetingScheduledType: "rolling",
							meetingScheduleCustomDateRanges: [],
							preferredMeetingType: [],
							location: '',
							description:''
                        }
                    }
                }
            }
			if (data.app === null) {
				temp.app = {
					options: {
						stages: {
							list: [
								{ name: 'Paid Reservation', color: 'var(--purple)', id: 'paid_reservation', probability: 20 },
								{ name: 'Completed Reservation', color: 'var(--cyan)', id: 'completed_reservation', probability: 30 },
								{ name: 'In Transaction', color: 'var(--orange)', id: 'transaction' },
								{ name: 'Won', color: 'var(--teal)', id: 'won' },
								{ name: 'Lost', color: 'var(--danger)', id: 'lost' },
							]
						},
						templateList: [],
						dealSources: [],
						lostReasons: [],
						leadSource: [
							{ name: 'No Source', id: 'nosource' },
							{ name: 'Email', id: 'email' },
							{ name: 'Cold Call', id: 'coldcall' },
							{ name: 'Advertising', id: 'advertising' }
						],
						taskType: [
							{ name: 'To-do', id: 'todo' },
							{ name: 'Call', id: 'call' },
							{ name: 'Follow up', id: 'followup' }
						]
					}
				}
			}

			if (!data.app.options.stages) {
				temp.app.options.stages = {
					list: [
						{ name: 'Paid Reservation', color: 'var(--purple)', id: 'paid_reservation', probability: 20 },
						{ name: 'Completed Reservation', color: 'var(--cyan)', id: 'completed_reservation', probability: 30 },
						{ name: 'In Transaction', color: 'var(--orange)', id: 'transaction' },
						{ name: 'Won', color: 'var(--teal)', id: 'won' },
						{ name: 'Lost', color: 'var(--danger)', id: 'lost' },
					]
				}
			}

			const isReservationActive = (state?.instance?.package?.options?.access?.reservation || false) && (temp?.instance?.options?.activeFeatures?.reservation ?? true);

			// Remove paid_reservation and completed_reservation stage from list if package is not premium and reservation is not active
			if (!isReservationActive && temp.app.options.stages?.list) {
				temp.app.options.stages.list = temp.app.options.stages.list.filter(stage => stage && !['paid_reservation', 'completed_reservation'].includes(stage.id));
			}

            let foundTransaction = data.app.options.stages.list.findIndex(x => x.id == 'transaction')
            if (data.app != null && foundTransaction == -1) {
                let wonIndex = temp.app.options.stages.list.findIndex(x => x.id == 'won')
                temp.app.options.stages.list.splice(wonIndex,0, {name:'In Transaction', color:'var(--orange)', id:'transaction'})
                $api.post('/settings/:instance/contacts', temp.app)
            }
            if (!temp.userApp.options.hasOwnProperty('meetingSettings')){
                temp.userApp.options.meetingSettings = {
                    availableTimeSlots:[],
                    durationSlots:[],
                    meetingScheduleRollingWeekSlot:null,
                    minimumNoticeTimeSlot:null,
                    bufferTimeSlot:null,
                    startTimeIncrementSlot:null,
                    preMeetingReminderSlot:null,
                    meetingConfirmationToCustomer:true,
                    preMeetingReminderToCustomer:false,
					meetingScheduledType: "rolling",
					meetingScheduleCustomDateRanges: [],
					preferredMeetingType: [],
					location: '',
					description: ''
                }
            }

			if (!temp.userApp.options.hasOwnProperty('account')){
				temp.userApp.options.account = {
					name:'',
					jobTitle:'',
					phone:{
						type:'',
						value:''
					},
					email:'',
					company:'',
					logo:'',
					officePhone:'',
					socials:[],
					fax:'',
					website:'',
					address:'',
					template:'',
					appointment:false,
					appointmentBgColor:'',
					appointmentColor:'',
					appointmentText:'',
					fieldColor:'',
					fontFamily:''
				}
			}

            state.allSettings = temp
        },
        UPDATE_ALLSETTINGS(state, data) {
            state.allSettings.app = data
        },
        UPDATE_USER_ALLSETTINGS(state, data) {
            state.allSettings.userApp = data
        },
        UPDATE_MEETING_SETTINGS(state, data) {
            state.allSettings.userApp.options.meetingSettings = data
        },
        ADD_OPPORTUNITY(state, data) {
            state.opportunities.push(data)
        },
        OPEN_OPPORTUNITY_DETAILS(state, data) {
            return state.opportunityDetails = {
                opportunity: data,
                show: true
            }
        },
        CLOSE_OPPORTUNITY_DETAILS(state) {
            return state.opportunityDetails = {
                opportunity: {},
                show: false
            }
        },
        CLOSE_EXPAND_DETAILS(state) {
            return state.expandDetails = {
                contact: {},
                visible: false
            }
        },
        OPEN_CONTACT_DETAILS(state, data) {
            return state.contactDetails = {
                contact: data,
                show: true
            }
        },
        CLOSE_CONTACT_DETAILS(state) {
            return state.contactDetails = {
                contact: {},
                show: false
            }
        },
        OPEN_CONTACT_MAIL: (state, ct) => {
            if (typeof ct === 'object') ct = ct.id
            state.mailModal = {
                show: true,
                contact: ct
            }
        },
        REMOVE_SEARCH_PARAM: (state, { searchKey }) => {
            delete state.searchOBJ[searchKey]
            state.searchTimeStamp = Date.now()
        },
        SET_NEW_SEARCH_TIME: (state) => {
            if (state.currentPage !== 1) state.currentPage = 1
            else state.searchTimeStamp = Date.now()
        },
        LOAD_PAGE: (state, status = !state.loadingPage) => state.loadingPage = status,
        SET_CONTACTS_PAGE: (state, pg) => state.currentPage = pg,
        TOGGLE_SEARCH: state => state.showSearch = !state.showSearch,
        SET_PROP: (state, { where, what, del = false }) => {
            if (del) return deleteProp(state, where)

            if (what.status === null) what.status = 'bh_lead'
            else if (typeof what.status === 'object') what.status = what.status.id

            if (what.tags && what.tags.length) {
                what.tags = what.tags.map(x => {
                    if (!state.tags.hasOwnProperty(x.id) && typeof x === 'object'){
						console.log('ADDING NEW TAG')
						setProp(state, ['tags', x.id], x)
					}
                    return typeof x === 'object' ? x.id : x
                })
            }


            state.newStoreTime = Date.now()
            setProp(state, where, what)
        },
        APPLY_RULES: (state, rules) => {
            setProp(state, ['rules'], rules)
        },
        SITEPLAN_RULES: (state) => {
            if (!Object.keys(state.rules).length) state.siteplanRulesModal = true
            else state.siteplanRulesDrawer = true
        },
        OPEN_CONTACT_TASKS: (state, contact) => {
            state.taskControl = {
                drawer: true,
                modal: false,
                contact,
            }
        },
        SITEPLAN_IMAGE_SETTINGS: (state) => {
            state.siteplanSettings = true
        },
        CLOSE_SETTINGS: (state) => {
            state.siteplanSettings = false
            state.siteplanRulesDrawer = false
            state.siteplanRulesModal = false
        },
        SET_TOTAL_RESULT: (state, data) => state.totalContacts = data,
        SET_NEW_CONTACTS_PAGE: (state, contacts) => {

            let allContacts = {}
            contacts.forEach(x => {
                let apts = {},
                    tsks = {}
                let { appointments = [], tasks = [], status = 'lead', ...ct } = x
                if (status === null) status = 'lead'
                ct.status = status
                appointments.forEach(apt => {
                    if (!apts[ct.id]) apts[ct.id] = {}
                    apts[ct.id][apt.id] = apt
                })
                tasks.forEach(task => {
                    if (!tsks[ct.id]) tsks[ct.id] = {}
                    tsks[ct.id][task.id] = task
                })

                allContacts[x.id] = ct
                tasks = {...state.tasks, ...tsks }
                appointments = {...state.appointments, ...apts }

            })

            state.allContacts = allContacts
            state.newStoreTime = Date.now()
        },
        DELETE_SPOT: (state, id) => {
            let index = state.editorSettings.settings.spots.findIndex(x => String(x.id) === String(id))
            state.editorSettings.settings.spots.splice(index, 1)
            state.editorSettings.self.redraw()
        },
        SET_EDITOR_SETTINGS: (state, obj) => {
            state.editorSettings = obj
        },
        SHAPE_SELECTED: (state, id) => {
            if (state.currentShape !== id)
                state.currentShape = String(id)
        },
        DESELECT_SHAPE: (state) => {
            state.currentShape = ''
        },
        SELECT_LOT_SHAPE: (state, id) => {
            window.editor.selectSpot(id)
            window.editor.redraw()
        },
        CLOSE_DRAWERS: state => {
            state.appointmentControl = {
                drawer: false,
                contact: {}
            }
            state.taskControl = {
                drawer: false,
                contact: {}
            }

        },
        SHOW_CREATE_STATUS: state => state.statusModal = { type: 'add', show: true },
        CLOSE_MODAL: (state) => {
            state.statusModal = { type: '', show: false }
            state.mailModal = { show: false, contact: '' }
        },
        DELETE_LOT: (state, data) => {
            deleteProp(state, ['lots', data.id])
        },
        SHOW_MODAL: (state, { index, settings, editor, type = '' }) => {
            if (type === 'delete') {

                let { id } = settings.spots[index]
                let lot = Object.values(state.lots).find(x => x.shapeId == id)

                state.modal = {
                    type: 'delete',
                    show: true,
                    title: 'Deleting Lot',
                    text: 'Are you sure you want to delete this Spot?',
                    identifier: '',
                    index,
                    lot,
                    settings,
                    editor
                }
            }
        },
        SHOW_ADD_CONTACT: (state) => {
            state.contactDrawer = {
                show: true,
                type: 'add'
            }
        },
        SHOW_EDIT_CONTACT: (state, ct) => {
            state.editingContact = ct.id
            state.contactDrawer = {
                show: true,
                type: 'edit'
            }
        },
        CLOSE_DRAWER: (state) => {
            state.editingContact = ''
            state.contactDrawer = {
                show: false,
                type: ''
            }
        },
		SET_FILTER_QUERY(state,data){
			state.queryParams = data
		},
        SET_APPDATA(state, { customFields = [], leadStatuses = [], forms = [], tags = [], contacts = [], pagination: { total, pageSize }, users = [], tasks = [], appointments = [], events = [], opportunities = [], transactions = [], vendors = {}, integrations = {}, nylasAccount = {}, unsubscribedContactCount = 0, maxLeadScore = 0 }) {
            customFields.forEach(x => state.fields[x.id] = x)
            leadStatuses.forEach(x => state.statuses[x.id] = x)
			forms.forEach(x => state.forms[x.id] = x)
            tags.forEach(x => state.tags[x.id] = x)
            contacts.forEach(x => {
                let apts = {},
                    tsks = {}
                let { appointments = [], tasks = [], status = 'lead', ...ct } = x
                if (status === null) status = 'lead'
                ct.status = status
                appointments.forEach(apt => {
                    if (!apts[ct.id]) apts[ct.id] = {}
                    apts[ct.id][apt.id] = apt
                })
                tasks.forEach(task => {
                    if (!tsks[ct.id]) tsks[ct.id] = {}
                    tsks[ct.id][task.id] = task
                })

                state.allContacts[x.id] = ct
                state.allContacts[x.id].appointments = x.appointments
                state.pageSize = parseInt(pageSize)
                state.totalContacts = total
            })
            state.users = users.filter(x => x!=null && x.id)
            state.tasks = tasks
            state.appointments = appointments
			state.events = events
            state.opportunities = opportunities
            state.transactions = transactions
            state.vendors = vendors
            state.integrations = integrations
            state.unsubscribedContactCount = unsubscribedContactCount
			state.appDataLoaded = true
			state.maxLeadScore = maxLeadScore
			state.nylasAccount = nylasAccount || {}
        },
        GET_STARTED: state => {
            state.getStarted = true
        },
		SET_MARKETING_WORKFLOW(state, data = []) {
			let obj = {};
			data.forEach(w => {
				obj[w.id] = { ...w }
			})
			state.marketingWorkflowList = obj
		},
		SET_CONTACT_TIMELINE(state, data) {
			state.contactTimeline[data.id] = data.timeline
		},
		ADD_CONTACT_TIMELINE(state, data) {
			state.contactTimeline[data.id].push(...data.timeline)
		},
		SET_SEARCHED_CONTACT_LIST: (state, data) => {
			state.searchedContactList = data
		}
    },
    actions: {
        DO_SEARCH: ({ commit, state, rootState }) => {

            let currPage = state.currentPage
            commit('LOAD_PAGE', true)
            let start = (currPage - 1) * state.pageSize
            let query = ''
			console.log('STATE FILTERDRAWER', state.filterDrawer)
            if (state.filterDrawer.query.trim().length != 0 || state.sortQuery.trim().length != 0) {
				console.log('FILTER EXISTS')
                let filterQuery = state.filterDrawer.query
                let sortQuery = state.sortQuery
                query = sortQuery.trim().length != 0 && filterQuery.trim().length != 0 ? sortQuery + '&' + filterQuery : sortQuery + filterQuery
            }
            else {
                query = buildQuery(state.searchOBJ)
            }
			console.log('SETTING FILTER QUERY', query)
			commit('SET_FILTER_QUERY', query)
            $api.get(`/contacts/${rootState.instance.id}/search?_start=${start}&_limit=${state.pageSize}&${query}`).then(({ data }) => {
                let { contacts, pagination } = data
                commit('SET_TOTAL_RESULT', pagination.total)
                if (contacts.length == 0) {
                    commit('EDIT_SORT_QUERY', '')
                }
                commit('SET_NEW_CONTACTS_PAGE', contacts)
                commit('LOAD_PAGE', false)
            })
        },
        DO_TAGS: ({commit, state}) => {
            $api.get(`/tags/:instance`).then(({data}) => {
                data.filter(x => x.type == 'contacts')
                data.forEach(tag => {
                    if (!state.tags[tag.id]){
                        commit('ADD_NEW_TAG', tag)
                    }
                })
            })
        },
        DO_SOURCE: ({commit, state}) => {
            if (state.allSettings && state.allSettings.app && state.allSettings.app.options && state.allSettings.app.options.leadSource && state.allSettings.app.options.leadSource.length) {
                $api.get(`/settings/:instance/contacts`).then(({data}) => {
                    if(data.options && data.options.leadSource) {
                        commit('EDIT_LEAD_SOURCE', data.options.leadSource)
                    }
                })
            }
        },
        CONFIRM_MODAL: ({ commit, state, rootState }) => {
            let { modal } = state
            if (modal.type === 'delete') {


                let url = `/lots/${rootState.instance.id}/${state.appData.id}/${modal.lot.id}`
                $api.delete(url).then(({ data }) => {
                    commit('DELETE_LOT', data)
                    let { settings, editor, index } = modal

                    if (settings.editor.selected_shape == settings.spots[index].id) {
                        editor.deselectSpot();
                    }
                    settings.spots.splice(index, 1);
                    editor.redraw();
                    editor.addAction();
                    commit('CLOSE_MODAL')
                })

            }
        },
        UPDATE_SPOT: ({ state, rootState }, data) => {
            if (!data) return
            let found = Object.values(state.lots).find(x => x.shapeId == data.id)
            if (!found) return null
            let url = `/siteplans/${rootState.instance.id}/${state.appData.id}/${data.id}`
            $api.put(url, data)
        },
        CLOSE_DRAWER: ({ commit }, { type, data }) => {
            if (type !== 'click') commit('ADD_UPDATE_LOT', { type, data })
            commit('CLOSE_DRAWER', data)
        },
        CLOSE_CONTACT_DRAWER: ({ commit }, { type, data }) => {
            if (type !== 'click') commit('SET_PROP', { where: ['allContacts', data.id], what: data, del: type === 'delete' })
            commit('CLOSE_DRAWER')
        },
        ADD_NEW_LOT: async({ commit }, data) => {
            commit('ADD_NEW_LOT', data)
        },
        SET_APPDATA: async({ commit, rootState, dispatch }, data) => {
            if (data) {
                commit('SET_APPDATA', data)
				commit('SET_INSTANCE', rootState.instance)
				dispatch('FETCH_MARKETING_WORKFLOW_LIST')
            }
        },
		FETCH_MARKETING_WORKFLOW_LIST: async ({ commit }) => {
			try {
				const { data } = await $api.get(`/marketing-workflows/:instance?_limit=-1`)
				commit('SET_MARKETING_WORKFLOW', data)
			} catch (error) {
				console.log('Error fetching marketing workflow list', error)
			}
		},
		SEARCH_CONTACTS: async({ commit }, input) => {
			try {
				const { data } = await $api.get(`/contacts/:instance/autocomplete`, { params: { q: input } });
				const contactList = data.map(({ name, email, id }) => ({
					label: name ? `${name} (${email})` : email,
					value: id
				}));
				commit('SET_SEARCHED_CONTACT_LIST', contactList);
			} catch (error) {
				throw error
			}
		}
    },
    getters: {
        appData: state => state.siteplan,
        editingContact: state => state.allContacts[state.editingContact],
        query: state => buildQuery(state.searchOBJ),
        opportunityStages: state => {
            let settings = state.allSettings
            if (!settings.app) return []

            let appSettings = settings.app.options

            return appSettings.stages || []

        },
        count: state => {
            let obj = { notes: 0 }
            if (state.allContacts[state.editContact] && state.allContacts[state.editContact].notes) {
                obj.notes = state.allContacts[state.editContact].notes.length
            } else if (state.externalContacts[state.editContact] && state.externalContacts[state.editContact].notes) {
                obj.notes = state.externalContacts[state.editContact].notes.length
            }
            return obj
        },
    },
}
