<template>
    <a-drawer
        :zIndex="1000"
        :width="'500px'"
		:wrapClassName="'hide-scroll'"
        @close="onClose"
        :visible="opportunityDetails.show"
        :drawerStyle="{ height: '100%', overflow: 'auto' }"
        >
        <bhLoading :show="loading" />
        <div v-if="opportunityDetails.opportunity">
            <div @click="expandDetails(opportunity)" style="width: 56px; height: 56px; position: absolute; right: 56px; top: 4px; font-size: 15px; cursor: pointer; text-align: center; z-index: 1001;">
                <a-icon type="arrows-alt" style="line-height: 56px;" />
            </div>

            <!-- Header -->
            <!-- <vue-custom-scrollbar class="drawerScroll" style="max-height:95vh; min-height:95vh; width: 100%;"> -->
                <div v-if="opportunity.hasOwnProperty('name')" class="w-full" style="text-align: center;">
                    <a-avatar style="background-color: #D5F2EA; padding: 5px 0;" shape="circle" :size="100"><svg xmlns="http://www.w3.org/2000/svg" width="36.479" height="21.887" viewBox="0 0 36.479 21.887" fill="#00c48c"> <path id="Icon_awesome-money-bill-alt" data-name="Icon awesome-money-bill-alt" class="cls-1" d="M20.063,17.267h-.912V12.252A.456.456,0,0,0,18.7,11.8h-.774a1.366,1.366,0,0,0-.759.23l-.874.583a.456.456,0,0,0-.127.632l.506.759a.456.456,0,0,0,.632.127l.027-.018v3.16h-.912a.456.456,0,0,0-.456.456v.912a.456.456,0,0,0,.456.456h3.648a.456.456,0,0,0,.456-.456v-.912A.456.456,0,0,0,20.063,17.267ZM34.655,4.5H1.824A1.824,1.824,0,0,0,0,6.324V24.563a1.824,1.824,0,0,0,1.824,1.824H34.655a1.824,1.824,0,0,0,1.824-1.824V6.324A1.824,1.824,0,0,0,34.655,4.5ZM2.736,23.651V20a3.648,3.648,0,0,1,3.648,3.648Zm0-12.767V7.236H6.384A3.648,3.648,0,0,1,2.736,10.884Zm15.5,10.944c-3.022,0-5.472-2.858-5.472-6.384s2.45-6.384,5.472-6.384,5.472,2.858,5.472,6.384S21.26,21.827,18.239,21.827Zm15.5,1.824H30.095A3.648,3.648,0,0,1,33.743,20Zm0-12.767a3.648,3.648,0,0,1-3.648-3.648h3.648Z" transform="translate(0 -4.5)"/> </svg></a-avatar>
                    <h5 class="mt-4 dF" style="justify-content: center;">
                        {{opportunity.name}}
                        <a-tooltip class="ml-2" v-if="opportunity.product && checkAvailability(opportunity)" overlayClassName="change-tooltip-color">
                            <template slot="title">
                                The product for this opportunity is no longer available.
                            </template>
                            <a-icon type="warning" style="color: var(--danger); font-size: 18px;" />
                        </a-tooltip>
                    </h5>
                    <div class="dF" style="justify-content: center;">
                        <div class="mr-2" style="font-size: 16px;">${{$formatNumber(opportunity.dealValue)}}</div>
                        |
                        <div class="ml-2" style="font-size: 16px; color: #9EA0A5;">{{getStage(opportunity.stage)}} {{opportunity.probability || '0'}}%</div>
                    </div>
                    <h5 @click="showContact(opportunity.contact.id)" class="mt-3" v-if="opportunity.contact" style="margin-bottom: 0;" :style="$route.path.includes('/leads') ? '' : 'cursor:pointer'">{{opportunity.contact.firstName && opportunity.contact.lastName && opportunity.contact.firstName.trim() && opportunity.contact.lastName.trim() ? opportunity.contact.firstName + ' ' + opportunity.contact.lastName : opportunity.contact.email}}</h5>
                    <h5 class="mt-3" v-else style="margin-bottom: 0;">No Contact</h5>
                    <div v-if="opportunity.contact" style="font-size:18px; color: var(--orange);">{{displayPhone(opportunity.contact.phone)}}</div>
                    <div v-else style="font-size:18px; color: var(--orange);">No Phone</div>
					<div style="display: flex; justify-content: center;" v-if="opportunity.worksheet && opportunity.worksheet.id" class="mt-2">
						<a :href="`https://worksheets.bildhive.${$tld}/edit/${opportunity.worksheet.id}`" target="_blank">
							<a-tag style="cursor: pointer;">
								Worksheet - {{ opportunity.worksheet.readableId }} <a-icon type="man" class="ml-1" />
							</a-tag>
						</a>
					</div>

                    <!-- Action buttons -->
                    <div class="w-1/2 mt-3 dF" style="margin-left: auto; margin-right: auto; justify-content: space-between; align-items: center;">
                        <div class="edit-delete">
                            <svg @click="editOpportunity(opportunity.id)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                        </div>
                        <div class="edit-delete">
                            <svg @click="sendMail(opportunity.contact)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                        </div>
                        <div class="edit-delete">
                            <svg @click="deleteOpportunity(opportunity)" style="color:var(--danger)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 mr-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                        </div>
                    </div>
                </div>


                <!-- Body -->
                <a-tabs class="contact-tabs mt-3" default-active-key="1">

                    <!-- Tab One -->
                    <a-tab-pane key="1" tab="Activity">
                        <div class="tab-content">
                            <a-row :gutter="16" style="text-align: center;">
                                <a-col :span="12">
                                    <div style="font-size: 20px; font-weight: bold;">{{opportunity.updatedAt ? getInactive(opportunity.updatedAt) : '0'}}</div>
                                    <div>Inactive Days</div>
                                </a-col>
                                <a-col :span="12">
                                    <div v-if="opportunity.updatedAt" style="font-size: 20px; font-weight: bold;">{{convertDate(opportunity.updatedAt)}}</div>
                                    <div v-else style="font-size: 20px; font-weight: bold;">No Date</div>
                                    <div>Last Updated Date</div>
                                </a-col>
                            </a-row>
                            <a-collapse class="collapse-display collapse-icon mt-5">
                                <template #expandIcon="props">
                                    <a-icon type="down" :rotate="props.isActive ? -180 : 0" />
                                </template>
                                <a-collapse-panel >
                                    <div class="py-2" slot="header" style="border:none; border-bottom:1px solid #EEEEEE; margin-left: -40px; margin-right: -16px;">
                                        <div class="dF aC jSB" style="margin-left: 30px;"><div style="font-size:16px; color:black">{{`Notes (${opportunity.notes && opportunity.notes.length ? opportunity.notes.length : '0'})`}}</div>
                                            <div @click="addNew('Note')" style="color:var(--orange)" class="dF aC"><a-icon class="mr-2" type="plus" />Add</div>
                                        </div>
                                    </div>
                                    <div v-if="opportunity.notes && opportunity.notes.length">
                                        <div :class="noteI == 0 ? '':'mt-4'" class="dF jSB aT" v-for="(note, noteI) in opportunity.notes" :key="note.id" :value="note.id">
                                            <div class="dF">
                                                <div class="smallIcon mr-3" style="background-color: #FFDEAD;">
                                                    <svg width="12" height="12" viewBox="0 0 20 20" fill="#F79425"><path id="Icon_material-comment" data-name="Icon material-comment" d="M22.99,5A2,2,0,0,0,21,3H5A2.006,2.006,0,0,0,3,5V17a2.006,2.006,0,0,0,2,2H19l4,4ZM19,15H7V13H19Zm0-3H7V10H19Zm0-3H7V7H19Z" transform="translate(-3 -3)"/> </svg>
                                                </div>
                                                <div>
                                                    <div style="color:var(--orange); font-size:15px">{{`Subject: ${note.subject}`}}</div>
                                                    <div style="color: #9EA0A5; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; width: 245px;">{{note.content}}</div>
                                                    <div style="color: #9EA0A5;">{{moment(note.updatedAt).fromNow()}}</div>
                                                </div>
                                            </div>
                                            <div class="dF">
                                                <svg @click="edit('note', note.id)" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-2 edit-action-button"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                                <svg @click="deleteItem('note', note.id)" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 mr-2 delete-action-button"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>This opportunity has no notes...</div>
                                </a-collapse-panel>
                                <a-collapse-panel >
                                    <div class="py-2" slot="header" style="border:none; border-bottom:1px solid #EEEEEE; margin-left: -40px; margin-right: -16px;">
                                        <div class="dF aC jSB" style="margin-left: 30px;"><div style="font-size:16px; color:black">{{`Tasks (${tasks.length})`}}</div>
                                            <div @click="addNew('Task')" style="color:var(--orange)" class="dF aC"><a-icon class="mr-2" type="plus" />Add</div>
                                        </div>
                                    </div>
                                    <div v-if="tasks.length">
                                        <div :class="taskI == 0 ? '':'mt-4'" v-for="(task, taskI) in tasks" :key="task.id" :value="task.id">
                                            <template v-if="task.relatedType === 'opportunity'">
                                                <div class='dF jSB' v-if="task.opportunity.id === opportunity.id">
                                                    <div class="dF">
                                                        <a-checkbox  :checked="task.completed" @change="toggleTaskCompletion(task, !task.completed)" style="width:30px; height:30px" class="checkbox-circle big-checkbox-circle mr-3" />
                                                        <div>
                                                            <div style="color:var(--orange); font-size:15px">{{task.title}}</div>
                                                            <div class="taskParagraph" style="color: #000; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; width: 165px;" v-html="task.description"></div>
                                                            <div v-if="task.dueDate !== 0" style="color:#9EA0A5;">{{'Due Date: ' + $formatDate(task.dueDate)}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="dF jE mb-3">
                                                        <template v-for="(user,userI) in task.assignTo">
                                                            <div v-if="userI < 3" :key="userI" class="owner-icon" :style="userI !== 0? 'margin-left: -5px' : ''">
                                                                <a-avatar v-if="user.avatar" :src="user.avatar" shape="circle" :size="20"/>
                                                                <a-avatar v-else style="font-size: 16px;" shape="circle" :size="30">{{user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()}}</a-avatar>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                    <div v-else>This opportunity has no tasks...</div>
                                </a-collapse-panel>
                                <a-collapse-panel >
                                    <div class="py-2" slot="header" style="border:none; border-bottom:1px solid #EEEEEE; margin-left: -40px; margin-right: -16px;">
                                        <div class="dF aC jSB" style="margin-left: 30px;"><div style="font-size:16px; color:black">{{`Appointments (${appointments.length})`}}</div>
                                            <div @click="addNew('Appointment')" style="color:var(--orange)" class="dF aC"><a-icon class="mr-2" type="plus" />Add</div>
                                        </div>
                                    </div>
                                    <div v-if="appointments.length">
                                        <div :class="appointmentI == 0 ? '':'mt-4'" v-for="(appointment, appointmentI) in appointments" :key="appointment.id" :value="appointment.id">
                                            <template v-if="appointment.relatedType === 'opportunity' && appointment.opportunity">
                                                <div class="dF jSB" v-if="appointment.opportunity.id === opportunity.id">
                                                    <div class="dF">
                                                        <div class="smallIcon mr-3" style="background-color: #FFDEAD;">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="#F79425" stroke="#FFF" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                                        </div>
                                                        <div>
                                                            <div style="color:var(--orange); font-size:15px">{{appointment.title}}</div>
                                                            <div style="color: #9EA0A5;">{{convertDate(appointment.date) + ' | ' + moment(appointment.startTime).format('LT') + ' to ' + moment(appointment.endTime).format('LT')}}</div>
                                                            <div class="dF">
                                                                <div>{{opportunity.name && opportunity.name != '' ? `${opportunity.name},` : ''}}</div>&nbsp;
                                                                <div class="dF" v-for="(user,userI) in appointment.assignTo" :key="user+userI">
                                                                    <div v-if="user.id === currentUser.user.id">You</div>
                                                                    <div v-else>{{user.firstName + ' ' + user.lastName}}</div>

                                                                    <div v-if="userI !== appointment.assignTo.length-1">,&nbsp;</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="dF">
                                                        <svg @click="edit('appointment', appointment.id)" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-2 edit-action-button"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                                        <svg @click="deleteItem('appointment', appointment.id)" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 mr-2 delete-action-button"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                    <div v-else>This opportunity has no appointments...</div>
                                </a-collapse-panel>
                                <a-collapse-panel >
                                    <div class="py-2" slot="header" style="border:none; border-bottom:1px solid #EEEEEE; margin-left: -40px; margin-right: -16px;">
                                        <div class="dF aC jSB" style="margin-left: 30px;"><div style="font-size:16px; color:black">{{`Form Submissions (${forms.length})`}}</div></div>
                                    </div>
                                    <template v-if="forms.length">
                                        <div :class="formI == 0 ? '' : 'mt-4'" class="dF jSB" v-for="(form, formI) in forms" :key="form.id" :value="form.id">
                                            <div class="dF">
                                                <div class="smallIcon mr-3" style="background-color: #FFDEAD;">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="#F79425" stroke="#F79425" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-align-justify"><line x1="21" y1="10" x2="3" y2="10"></line><line x1="21" y1="6" x2="3" y2="6"></line><line x1="21" y1="14" x2="3" y2="14"></line><line x1="21" y1="18" x2="3" y2="18"></line></svg>
                                                </div>
                                                <div>
                                                    <div style="color:black; font-size:15px">{{form.form && form.form.name ? form.form.name : 'N/A'}}</div>
                                                    <div style="color:#9EA0A5">{{getDateString(form.createdAt)}}</div>
                                                    <div style="color:var(--orange)" v-if="form.contact">{{form.contact.firstName && form.contact.lastName && form.contact.firstName != '' && form.contact.lastName != '' ? `Submitted by ${form.contact.firstName} ${form.contact.lastName}` :''}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>This opportunity has no forms...</template>
                                </a-collapse-panel>
                                <a-collapse-panel >
                                    <div class="py-2" slot="header" style="border:none; border-bottom:1px solid #EEEEEE; margin-left: -40px; margin-right: -16px;">
                                        <div class="dF aC jSB" style="margin-left: 30px;"><div style="font-size:16px; color:black">{{`Attachments (${attachments.length})`}}</div></div>
                                    </div>
                                    <template v-if="attachments.length">
                                        <div :class="attachmentI == 0 ? '' : 'mt-4'" class="dF jSB" v-for="(attachment, attachmentI) in attachments" :key="attachmentI" :value="attachmentI">
                                            <div class="dF">
                                                <div class="smallIcon mr-3 dF aC jC" style="background-color: #FFDEAD;">
                                                    <i style="font-size:16px; color:var(--orange)" class="fa fa-file" />
                                                </div>
                                                <div>
                                                    <div style="color:var(--orange); font-size:15px">{{attachment.label}}</div>
                                                </div>
                                            </div>
                                            <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                                <template slot="title">Download Attachment</template>
                                                <a :href="attachment.value" target="_blank"><i style="font-size:16px;cursor:pointer" class="fe fe-download" /></a>
                                            </a-tooltip>
                                        </div>
                                    </template>
                                    <template v-else>This opportunity has no attachments...</template>
                                </a-collapse-panel>
                                <a-collapse-panel>
                                    <div class="py-2" slot="header" style="border:none; border-bottom:1px solid #EEEEEE; margin-left: -40px; margin-right: -16px;">
                                        <div class="dF aC jSB" style="margin-left: 30px;"><div style="font-size:16px; color:black">{{`Timeline`}}</div>
                                        </div>
                                    </div>
                                    <div v-for="(date,dateI) in createDates" :key="date+dateI">
                                        <h6 class="mb-4">{{textDate(date.date)}}</h6>
                                        <a-timeline>
                                            <div class="dF" v-for="(d,i) in date.sorted" :key="d+i">
                                                <div class="mr-3" style="margin-top: -5px; color: #9EA0A5;">
                                                    {{textTime(d)}}
                                                </div>
                                                <a-timeline-item>{{d.logNote}}</a-timeline-item>
                                            </div>
                                        </a-timeline>
                                    </div>
                                </a-collapse-panel>
                            </a-collapse>
                        </div>
                    </a-tab-pane>


                    <!-- Tab Two -->
                    <a-tab-pane key="2" tab="Details">
                        <div class="tab-content">
                            <a-form-model ref="ruleForm" :model="opportunity" :rules="rules">
                                <a-row>
                                    <a-col :span="12">
                                        <a-form-model-item label="Name" prop="name">
                                            <div style="color:#000">{{opportunity.name}}</div>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="24" v-if="opportunity.contact">
                                        <a-form-model-item label="Contact" prop="contact">
                                            <div style="color:var(--orange)">{{opportunity.contact.firstName + ' ' + opportunity.contact.lastName}}</div>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="24" v-if="opportunity.company">
                                        <a-form-model-item label="Company" prop="company">
                                            <div style="color:#000">{{opportunity.company}}</div>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="24" v-if="opportunity.contact && opportunity.contact.phone">
                                        <a-form-model-item label="Work Phone" prop="phone">
                                            <div style="color:#000">{{displayPhone(opportunity.contact.phone)}}</div>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="24" v-if="opportunity.contact" >
                                        <a-form-model-item label="Work Email" prop="email">
                                            <div style="color:var(--orange)">{{opportunity.contact.email}}</div>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="24">
                                        <a-form-model-item label="Estimated Opportunity Close Date" prop="closeDate">
                                            <div style="color:#000">{{convertDate(opportunity.closeDate)}}</div>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="24" v-if="opportunity.owners && opportunity.owners.length">
                                        <a-form-model-item label="Owner" prop="owners">
                                            <div class="dF">
                                                <template v-for="(user,userI) in opportunity.owners">
                                                    <div :key="user+userI" style="color:#000">{{user.firstName + ' ' + user.lastName}}</div>
                                                    <template v-if="userI !== opportunity.owners.length-1">{{', '}}</template>
                                                </template>
                                            </div>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="24" v-if="opportunity.stage">
                                        <a-form-model-item label="Stage" prop="stage">
                                            <div style="color:#000; line-height: 25px;">{{printStage(opportunity.stage)}}</div>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="24" v-if="opportunity.dealSource">
                                        <a-form-model-item label="Deal Source" prop="dealSource">
                                            <div style="color:#000">{{dealSourceName(opportunity.dealSource)}}</div>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="24" v-if="opportunity.createdAt">
                                        <a-form-model-item label="Date Created" prop="createdAt">
                                            <div style="color:#000">{{convertDate(opportunity.createdAt)}}</div>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="24" v-if="opportunity.dealValue">
                                        <a-form-model-item label="Value" prop="dealValue">
                                            <div style="color:#000">${{$formatNumber(opportunity.dealValue)}}</div>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="24" v-if="opportunity.visibility">
                                        <a-form-model-item label="Visibility" prop="visibility">
                                            <div style="color:#000">{{opportunity.visibility[0].toUpperCase() + opportunity.visibility.substring(1)}}</div>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="24">
                                        <a-form-model-item label="Description" prop="description">
                                            <div v-if="opportunity.interested" style="font-weight: bold;">{{opportunity.interested}}</div>
                                            <div v-else style="color: #D0C9D6;">No description</div>
                                        </a-form-model-item>
                                    </a-col>
                                </a-row>
                            </a-form-model>
                        </div>
                    </a-tab-pane>
                </a-tabs>
            <!-- </vue-custom-scrollbar> -->
        </div>

    </a-drawer>
</template>

<script>

  import bhLoading from 'bh-mod/components/common/Loading'
  import vueCustomScrollbar from 'vue-custom-scrollbar'
  import moment from 'moment'

  export default {
    props:{
        time:{
            default:0,
            type:Number,
        }
    },
    components:{bhLoading,vueCustomScrollbar},
    data() {
      return {
        forms:[],
        opportunity:{},
        notes:[],
        loading:false,
        taskNum:0,
        updateTime:0,
        appointmentNum:0,
        attachments:[],
        opportunityTimeline:[],
        transactionTimeline:[],

        rules: {
            name: [
                { required: true, trigger: 'blur' },
            ],
            email: [
                { required: true, trigger: 'blur' },
            ],
            closeDate: [
                { required: true, trigger: 'blur' },
            ],
            stage: [
                { required: true, trigger: 'blur' },
            ],
            contact: [
                { required: true, trigger: 'blur' },
            ],
            dealValue: [
                { required: true, trigger: 'blur' },
            ],
        },
        showNotes:true,
        showTasks:true,
        showAppointments:true,
        showSubmissions:true,
        showTimeline:true,
      };
    },
    watch:{
        showDetails(val){
            if (val){
                this.opportunity = this.opportunityDetails.opportunity

                this.loading = true
                this.$api.get(`/timelines/:instance/opportunities/${this.opportunity.id}`).then(({data}) => {
                    this.opportunityTimeline = data
                    this.loading = false
                }).catch(err => {
					this.loading = false
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})

                if (this.opportunity.hasOwnProperty('transaction') && this.opportunity.transaction && this.opportunity.transaction != '') {
                    this.loading = true
                    this.$api.get(`/timelines/:instance/transactions/${this.opportunity.transaction}`).then(({data}) => {
                        this.transactionTimeline = data
                        this.loading = false
                    }).catch(err => {
						this.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
                }

                if (this.opportunity.contact && this.opportunity.contact.id) {
                    this.loading = true
                    this.$api.get(`/form-submits/:instance?contact=${this.opportunity.contact.id}`).then(({data}) => {
                        this.forms = data
                        this.forms.forEach(form => {
                            if (form.data && form.data.fields) {
                                let files = form.data.fields.filter(x => {
                                    if (x.field && x.field.type && x.field.type == 'file' && x.value && x.value.includes('https')) return x
                                })
                                this.attachments = this.attachments.concat(files)
                            }
                        })
                        this.loading = false
                    }).catch(err => {
						this.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
                }
                if (this.opportunity.notes && this.opportunity.notes.length && (typeof this.opportunity.notes[0] === 'string' || this.opportunity.notes[0] instanceof String)){
                    this.loading = true
                    this.$api.get(`/opportunities/:instance/${this.opportunity.id}/notes`).then( ({data}) => {
                        data.sort((a,b) => {
                            return (this.moment(b.updatedAt).format('X')*1000) - (this.moment(a.updatedAt).format('X')*1000)
                        })
                        this.opportunity.notes = data
                        this.$store.commit('UPDATE_OPPORTUNITY_NOTES', this.opportunity)
                        this.loading = false
                        this.notes = data
                    }).catch(err => {
						this.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
                }
            }
        },
    },
    computed:{
		dateFormat(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
        origProducts(){
            return this.$store.state.products
        },
        refresh() {
            return this.time
        },
        events() {
            let events = []
            if (this.opportunity && this.opportunity.id) {
                if (this.opportunity.updatedAt && this.opportunity.createdAt && parseInt(this.moment(this.opportunity.updatedAt).format('X')*1000) == parseInt(this.moment(this.opportunity.createdAt).format('X')*1000)) {
                    let obj = {}
                    obj.date = this.opportunity.createdAt
                    obj.logNote = 'This opportunity was added'
                    events.push(obj)
                } else if (this.opportunity.updatedAt && this.opportunity.createdAt && parseInt(this.moment(this.opportunity.updatedAt).format('X')*1000) != parseInt(this.moment(this.opportunity.createdAt).format('X')*1000)){
                    let created = {}
                    created.date = this.opportunity.createdAt
                    created.logNote = 'This opportunity was added'
                    let updated = {}
                    updated.date = this.opportunity.updatedAt
                    updated.logNote = 'This opportunity was updated'
                    events.push(created)
                    events.push(updated)
                }
                this.tasks.forEach(task => {
                    if (task.opportunity && (task.opportunity.id == this.opportunity.id || task.opportunity == this.opportunity.id)) {
                        let obj = {}
                        obj.date = task.createdAt
                        obj.logNote = 'A new task was added for this opportunity'
                        events.push(obj)
                    }
                })
                if (this.opportunity.notes && this.opportunity.notes.length) {
                    this.opportunity.notes.forEach(note => {
                        let obj = {}
                        obj.date = note.createdAt
                        obj.logNote = 'A new note was added for this opportunity'
                        events.push(obj)
                    })
                }
                if (this.opportunityTimeline.length) {
                    this.opportunityTimeline.forEach(timeline => {
                        let obj = {}
                        obj.date = new Date(timeline.data.date).toISOString()
                        obj.logNote = timeline.data.logNote
                        events.push(obj)
                    })
                }
                if (this.transactionTimeline.length) {
                    this.transactionTimeline.forEach(timeline => {
                        let obj = {}
                        obj.date = timeline.createdAt
                        obj.logNote = timeline.data.action
                        events.push(obj)
                    })
                }
            }
            return events
        },
        createDates(){
            let dates = {}
            this.events.forEach( x => {
                if (!dates.hasOwnProperty(`${x.date.substring(5,7)}` + '/' + `${x.date.substring(8,10)}`)){
                    let obj = {
                        date:`${x.date.substring(5,7)}` + '/' + `${x.date.substring(8,10)}` + '/' + `${x.date.substring(0,4)}`,
                        sorted:this.sortDates(`${x.date.substring(5,7)}` + '/' + `${x.date.substring(8,10)}` + '/' + `${x.date.substring(0,4)}`)
                    }
                    dates[obj.date] = obj
                }
            })
            let sorted = Object.values(dates).sort( (a,b) => {
                let {date:date1} = a
                let {date:date2} = b

                date1 = new Date(date1).getTime()
                date2 = new Date(date2).getTime()

                return date1 < date2 ? -1 : 1
            })

            return sorted
        },
        currentUser(){
            return this.$store.state.user
        },
        showDetails(){
            return this.$store.state.contacts.opportunityDetails.show
        },
        opportunityDetails(){
            let time = this.refresh
            let opportunity = this.$store.state.contacts.opportunityDetails
            if (opportunity && opportunity.opportunity && opportunity.opportunity.notes && typeof opportunity.opportunity.notes != 'string'){
                opportunity.opportunity.notes.sort((a,b) => {
                    return (this.moment(b.updatedAt).format('X')*1000) - (this.moment(a.updatedAt).format('X')*1000)
                })
                return opportunity
            } else {
                return opportunity
            }
        },
        tasks(){
            let time = this.updateTime
            let tasks = JSON.parse(JSON.stringify(this.$store.state.contacts.tasks))
            if (tasks && tasks.length) tasks = tasks.filter( x => x.opportunity && x.relatedType == 'opportunity' && x.opportunity.id == this.opportunity.id)
            this.taskNum = tasks.length

            if (tasks && tasks.length){
                tasks.sort((a,b) => {
                    return a.dueDate - b.dueDate
                })
            }
            return tasks
        },
        appointments(){
            let time = this.refresh
            let appointments = JSON.parse(JSON.stringify(this.$store.state.contacts.appointments))
            if (appointments && appointments.length) appointments = appointments.filter( x => x.opportunity && x.relatedType == 'opportunity' && x.opportunity.id == this.opportunity.id)
            this.appointmentNum = appointments.length

            if (appointments && appointments.length){
                appointments.sort((a,b) => {
                    return a.date - b.date
                })
            }
            return appointments
        },
        users(){
            return this.$store.state.contacts.users
        },
        instance(){
          return this.$store.state.instance
        },
        appData(){
          return this.$store.state.appData
        },
        settings(){
            return this.$store.state.contacts.allSettings.app || {}
        },
		dealSources() {
            if (this.settings && this.settings.options && this.settings.options.dealSources && this.settings.options.dealSources.length > 0) {
                return this.settings.options.dealSources
            } else {
                return [
                    {name:'No Source', id:'nosource'},
                    {name:'Email', id:'email'},
                    {name:'Cold Call', id:'coldcall'},
                    {name:'Advertising', id:'advertising'}
                ]
            }
        },
		nylasAccount() {
			return this.$store.state.contacts.nylasAccount || {}
		}
    },
    methods: {
		dealSourceName(source){
			let find = this.dealSources.find(x => x.id == source)
			if (find) return find.name
			else return 'No Source'
		},
        checkAvailability(prod){
            let prodId = prod.product
            if (this.instance.productType == 'lowrise'){
                let findProduct = this.origProducts.find(x => x.id == prodId)
                if (findProduct){
                    return !['available', 'approved_for_reservation', 'reserved'].includes(findProduct.status);
                } else {
                    return false
                }
            } else if (this.instance.productType == 'highrise'){
                let floorId = prod.floor
                let findFloor = this.origProducts.find(x => x.id == floorId)
                if (findFloor){
                    let findProduct = findFloor.units.find(x => x.id == prodId)
                    if (findProduct){
                        return !['available', 'approved_for_reservation', 'reserved'].includes(findProduct.salesStatus)
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
        },
        getDateString(string) {
            if (string != '' && string != undefined) {
                let year = string.substring(0,4)
                let month = string.substring(5,7)
                let day = string.substring(8,10)
				if (this.dateFormat == 'YYYY/MM/DD') return `${year}/${month}/${day}`
				else if (this.dateFormat == 'DD/MM/YYYY') return `${day}/${month}/${year}`
				else {
					return `${month}/${day}/${year}`
				}
            } else {return ''}
        },
        getInactive(updatedAt) {
            let today = moment(new Date())
            let update = moment(updatedAt)
            return Math.round(today.diff(update, 'days', true))
        },
		toggleTaskCompletion(task, completed) {
			this.$store.commit('EDIT_TASK', {...task, completed: completed || !!task.completed })
        },
        edit(type, id) {
            this.$store.commit('OPEN_EDIT_DRAWER', {type:type, id:id})
        },
        deleteItem(type, id) {
            if (type == 'note') {
                if (this.$p < 40){
                    return this.$message.error('You do not have permission to delete Note')
                }
                let self = this
                this.$confirm({
                    title: "Delete Note",
                    content: h => <div>Do you want to delete this Note?</div>,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk() {
                        self.loading = true
                        self.$api.delete(`/comments/:instance/${id}`).then( ({data}) => {
                            self.loading = false
							let index = self.opportunity.notes.findIndex(x => x.id == data.id)
                    		if (index != -1) self.opportunity.notes.splice(index, 1)
                            self.$store.commit('DELETE_NOTE', data)
                        }).catch(err => {
							self.loading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
                    },
                    onCancel() {
                        console.log('Cancel')
                    }
                })
            } else if (type == 'appointment') {
                if (this.$p < 40){
                    return this.$message.error('You do not have permission to delete Appointment')
                }
                let self = this
                this.$confirm({
                    title: "Delete Appointment",
                    content: h => <div>Do you want to delete this appointment?</div>,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk() {
                        self.loading = true
                        self.$api.delete(`/appointments/:instance/${id}`).then( ({data}) => {
                            self.$store.commit('DELETE_APPOINTMENT', data)
                            self.loading = false
                        }).catch(err => {
							self.loading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
                    },
                    onCancel() {
                        console.log('Cancel')
                    }
                })
            }
        },
        showContact(id){
            if (id != '' && !this.$route.path.includes('/leads')) {
                this.$router.push(`/leads/${id}`)
                this.$store.commit('CLOSE_OPPORTUNITY_DETAILS')
            }
        },
        getStage(stageId) {
            if (this.settings.options && this.settings.options.stages && this.settings.options.stages.list) {
                let find = this.settings.options.stages.list.find(x => x.id == stageId)
                if (find) return find.name
                else return ''
            } else return ''
        },
        deleteOpportunity(opp) {
            if (this.$p < 40){
                return this.$message.error('You do not have permission to delete Opportunities')
            }
            let self = this
            this.$confirm({
                title: 'Delete Opportunity',
                content: h => <div>Do you want to delete <b>{opp.name}</b>? All information related to this opportunity will be lost.</div>,
                okText: 'DELETE',
                okType: 'danger',
                cancelText: 'CANCEL',
                centered: true,
                onOk(){
                    self.loading = true
                    self.$api.delete(`/opportunities/:instance/${opp.id}`).then( ({data}) => {
                        self.loading = false
                        self.$store.commit('UPDATE_OPPORTUNITIES', data)
                        self.$store.commit('CLOSE_OPPORTUNITY_DETAILS')
                    }).catch(err => {
						self.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel(){
                    console.log('CANCELED')
                }
            })
        },
        editOpportunity(id) {
            this.$router.push(`/opportunities/sales/${id}`)
            this.$store.commit('CLOSE_OPPORTUNITY_DETAILS')
        },
        addNew(type) {
            if(this.opportunity && this.opportunity.id && this.opportunity.id != '') {
                this.$store.commit('ADD_NEW', type)
                this.$store.commit('ADD_OPPORTUNITY_ACTION', this.opportunity.id)
            }
        },
        moment,
        newNote(){
            this.$store.commit('ADD_NEW', 'Note')
            setTimeout(() => {
                this.$store.commit('CHANGE_TYPE', this.opportunity)
            }, 50)
        },
        newAppointment(){
            this.$store.commit('ADD_NEW', 'Appointment')
            setTimeout(() => {
                this.$store.commit('CHANGE_TYPE', this.opportunity)
            }, 50)
        },
        sendMail(obj){
            if (obj.id && obj.id != '') {
				if (this.nylasAccount && this.nylasAccount.email){
					this.$store.commit('ADD_LEAD_ACTION', obj.id)
					this.$store.commit('ADD_NEW', 'Email')
				} else {
					this.$notification['error']({
						message: 'No Email Connection',
						description: 'You have not connected your mail account. To connect, go to Settings and select Email & Calendar Settings > Integrations.',
						duration: 5
					});
				}
            }
        },
        printStage(stage){
            let list = this.settings.options.stages.list
            let index = list.findIndex(x => x.id == stage)
            if (index != -1) {
                return this.settings.options.stages.list[index].name
            }else {
                return ''
            }
        },
        convertDate(num){
			let x = new Date(num)
			//Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
			let year = x.getFullYear()
			let month = x.getMonth()+1
			let day = x.getDate()
			if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
			else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
			else {
				return month+'/'+day+'/'+year
			}
		},
        textTime(date){
            let d = new Date(date.date)
            let minutes = d.getMinutes().toString()
            if (minutes.length < 2){
                minutes = '0' + minutes
            }
            return d.getHours() + ':' + minutes
        },
        textDate(date){
			const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            let newDate = new Date(date)
			let month = monthNames[Number(newDate.getMonth())]
			return month + ' ' + newDate.getDate() + ', ' + newDate.getFullYear()
        },
        sortDates(date){
            let dates = []
            this.events.forEach( x => {
                if (date == (`${x.date.substring(5,7)}` + '/' + `${x.date.substring(8,10)}` + '/' + `${x.date.substring(0,4)}`)){
                    dates.push(x)
                }
            })
            dates.sort((a, b) => {
                return new Date(a.date) - new Date(b.date)
            })
            return dates
        },
        expandDetails(obj){
            this.$router.push(`/opportunities/sales/${obj.id}`)
            this.$store.commit('CLOSE_OPPORTUNITY_DETAILS')
            setTimeout(() => {
                this.$store.commit('UPDATE_QUERY_CONTACT', obj.contact)
            }, 50)
        },
        displayPhone(num){
			if(!num) return '';
            let phone = num.toString().trim("").replace(/[^a-zA-Z0-9 ]/g, '')
            let first = phone.substring(0,3)
            let middle = phone.substring(3,6)
            let last = phone.substring(6,10)

            return '('+first+') '+middle+' '+last
        },
        onClose(){
            this.$store.commit('CLOSE_OPPORTUNITY_DETAILS')
            this.showNotes = true,
            this.showTasks = true,
            this.showAppointments = true,
            this.showSubmissions = true,
            this.showTimeline = true,
            this.opportunity = {},
            this.taskNum = 0,
            this.appointmentNum = 0
        }
    },
  };
</script>
<style>
.collapse-display.ant-collapse{
    border:none !important;
}
.collapse-display.ant-collapse > .ant-collapse-item{
    border-bottom:none !important;
}
.collapse-display .ant-collapse-header{
    padding:none !important;
}
.collapse-display.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding:0 !important;
}
.collapse-display .ant-collapse-content{
    border:none !important;
}
.collapse-icon .ant-collapse-arrow{
    left:0px !important;
}
</style>

<style lang="scss">
	.hide-scroll .ant-drawer-wrapper-body::-webkit-scrollbar {
		display: none;
	}
    .ant-timeline-item-last .ant-timeline-item .ant-timeline-item-tail {
        display: none;
    }
    .drawerScroll {
        .ps__thumb-x {
            display: none;
        }
        .ps__rail-y {
            display: none;
        }
    }
    .popoverStyle .ant-popover-inner-content {
        padding: 0;
        background-color: white;
        border-radius: 20px;
    }
    .contact-tabs .ant-tabs-nav-container {
        text-align: center;
    }
    .contact-tabs .ant-tabs-bar {
        margin-left: -24px;
        margin-right: -24px;
    }
    .contact-tabs .ant-tabs-nav {
        font-size: 18px;
        .ant-tabs-tab {
            padding-left: 18px;
            padding-right: 18px;
        }
    }
    .owner-icon {
        border-radius: 30px;
        width: 30px;
        height: 30px;
        background-color: #F4F3F6;
    }
</style>
<style lang="scss" scoped>

    .contact-tabs {
        margin-left: -24px;
        margin-right: -24px;
        .tab-content {
            padding: 24px;
        }
    }
    .edit-delete {
        padding: 9px 0;
		cursor: pointer;
		color: #9EA0A5;
		transition:color .1s ease-in;
	}
	.edit-delete:hover {
		color: orange;
		transition:color .1s ease-in;
	}
    .more-option-icon{
        width: 30px;
        height: 30px;
        border-radius: 30px;
        text-align: center;
        background-color: transparent;
    }
    .more-option-icon:hover{
        color: var(--orange) !important;
        cursor: pointer;
        background-color: var(--light-gray);
    }
    .popoverContent{
        height: 35px;
        width: 150px;
        line-height: 35px;
        padding-left: 10px;
    }
    .popoverContent:hover{
        background-color: var(--off-white-light);
        cursor: pointer;
    }

    .smallIcon {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        text-align: center;
        padding: 7px 0;
    }
</style>
<style scoped>
.edit-action-button:hover{
    color:var(--orange);
}
.edit-action-button{
    color:#9EA0A5;
    cursor: pointer;
}
.delete-action-button:hover{
    color:var(--danger);
}
.delete-action-button{
    color:#9EA0A5;
    cursor: pointer;
}
</style>
<style>
.checkbox-circle .ant-checkbox-inner{
    border-radius: 50%;
}
.checkbox-circle .ant-checkbox-checked::after {
    border:none;
}
</style>
<style>
.big-checkbox-circle .ant-checkbox-inner{
    width:30px;
    height:30px;
}
.big-checkbox-circle .ant-checkbox-inner::after{
    left:33%;
}
</style>
