<template>
    <div style="height: 100%">
        <LeftNavBar :title="selectedAppInitial" :items="navItems" @opened="navOpened"
            :selectedValue="$store.state.sidebarSelection" />
    </div>
</template>

<script>
import LeftNavBar from "bh-mod/components/common/LeftNavBar";

export default {
    components: { LeftNavBar },
    name: "menu-left",
    data() {
        return {};
    },
    computed: {
        selectedAppInitial() {
            return (
                (this.$store.state.theApp &&
                    this.$store.state.theApp.media &&
                    this.$store.state.theApp.media.initial) ||
                "CRM"
            );
        },
        navItems() {
            const baseItems = [
                { label: "My Work Day", value: "My Work Day", iconUrl: require("@/assets/sideIcons/overview.svg") },
                { label: "Leads", value: "Leads", iconUrl: require("@/assets/sideIcons/contacts.svg") },
                { label: "Opportunities", value: "Opportunities", iconUrl: require("@/assets/sideIcons/opportunities.svg") },
                { label: "Email", value: "Email", iconUrl: require("@/assets/sideIcons/email.svg") },
                { label: "Calendar", value: "Calendar", iconUrl: require("@/assets/sideIcons/calendar.svg") },
                { label: "Tasks", value: "Tasks", iconUrl: require("@/assets/sideIcons/tasks.svg") },
                { label: "Appointments", value: "Appointments", iconUrl: require("@/assets/sideIcons/appointment.svg") },
                { label: "Events", value: "Events", iconUrl: require("@/assets/sideIcons/events.svg") },
                { divider: true },
                { label: "Settings", value: "Settings", iconUrl: require("@/assets/sideIcons/settings.svg") },
            ];

            const additionalItems = {
                Opportunities: [
                    { label: "Sales Pipeline", value: "Sales Pipeline", iconUrl: require("@/assets/sideIcons/sales.svg") },
                    { label: "Archived", value: "Archived", iconUrl: require("@/assets/sideIcons/archived.svg") },
                ],
                Email: [
                    { label: "Inbox", value: "Inbox", iconUrl: require("@/assets/sideIcons/inbox.svg") },
                    { label: "Sent", value: "Sent", iconUrl: require("@/assets/sideIcons/sent.svg") },
                ],
            };

            const additionalItemsKeys = Object.keys(additionalItems);

            additionalItemsKeys.forEach(key => {
                if (this.sidebarSelection === key || additionalItems[key].some(item => item.value === this.sidebarSelection)) {
                    const index = baseItems.findIndex(item => item.value === key);
                    if (index !== -1) {
                        additionalItems[key].forEach((item, i) => {
                            baseItems.splice(index + 1 + i, 0, item);
                        });
                    }
                }
            });

            return baseItems;
        },
        expandDetails() {
            return this.$store.state.contacts.expandDetails.visible;
        },
        expandOpportunity() {
            return this.$store.state.contacts.expandOpportunity.visible;
        },
        sidebarSelection() {
            return this.$store.state.sidebarSelection;
        },
    },
    methods: {
        navOpened(e) {
            const routes = {
                "My Work Day": "/",
                "Leads": "/leads",
                "Opportunities": "/opportunities/sales",
                "Sales Pipeline": "/opportunities/sales",
                "Archived": "/opportunities/archived",
                "Tasks": "/tasks",
                "Completed": "/tasks/completed",
                "Email": "/email/inbox",
                "Inbox": "/email/inbox",
                "Sent": "/email/sent",
                "Calendar": "/calendar",
                "Appointments": "/appointments",
                "Events": "/events",
                "Settings": "/settings"
            };

            const path = routes[e.value];
            if (path) {
                if (this.$route.path !== path) {
                    this.$router.push(path);
                }
            }
        },
    },
};
</script>
