<template>
    <div
        class="w-full dF fC f1 px-4 pb-4 hide-scrollbar"
        style="background-color: #f7f5f9; overflow: scroll"
    >
		<BHLoading :show="loading" />
        <div class="dF jSB">
            <h4 class="mb-3 mt-3">{{ sidebarSelection }}</h4>
        </div>

        <SettingsTab @menuclick="showMenu" :activeTab="activeTab">
            <div v-if="activeTab === 0" slot="content">
                <a-card>
                    <AccountTab @connectOutlook="connectOutlook" />
                </a-card>
                <a-card
                    v-if="
                        vendors &&
                        (vendors.gmail.active ||
                            vendors.outlook.active ||
                            vendors.office365.active)
                    "
                    class="mt-4"
                >
                    <IntegrationTab />
                </a-card>
            </div>
            <div
                v-else-if="activeTab === 1"
                slot="content"
                class="email-signature"
            >
				<a-row :gutter="16">
					<a-col :span="$mq == 'sm' ? 24 : 14">
	                    <a-card>
	                        <a-tabs class="opportunitiesTab" default-active-key="1">
	                            <a-tab-pane key="1" tab="General">
	                                <DetailsTab @goto="changeTab" />
	                            </a-tab-pane>
	                            <a-tab-pane key="2" tab="Logo">
	                                <AddFile
	                                    :instructions="'Add your logo to email signature'"
	                                    :image="
	                                        $store.state.contacts.previewAccount &&
	                                        $store.state.contacts.previewAccount
	                                            .logo
	                                            ? $store.state.contacts
	                                                  .previewAccount.logo
	                                            : ''
	                                    "
	                                    @updateImage="updateLogo"
	                                />
	                            </a-tab-pane>
	                            <a-tab-pane key="3" tab="Social">
	                                <SocialTab />
	                            </a-tab-pane>
	                            <a-tab-pane key="4" tab="Design">
	                                <TemplateTab />
	                            </a-tab-pane>
	                        </a-tabs>
							<div class="mt-4 dF jE">
								<a-button @click="cancel" class="mr-3 cancel-button" size="large">CANCEL</a-button>
								<a-button @click="saveTemplate" size="large" type="primary">SAVE</a-button>
							</div>
	                    </a-card>
					</a-col>
					<a-col :span="$mq == 'sm' ? 24 : 10">
						<div class="preview-email">
		                    <AccountPreview />
		                </div>
					</a-col>
				</a-row>
            </div>
            <div v-else-if="activeTab === 2" slot="content">
                <a-card>
                    <h5 class="ml-3 mb-4">Templates</h5>
                    <EmailTemplate />
                </a-card>
            </div>
            <div v-else-if="activeTab === 3" slot="content">
                <a-card>
                    <a-tabs class="opportunitiesTab" v-model="manageDataTab" @change="changeTab">
                        <a-tab-pane key="1" tab="Import from CSV">
                            <ImportData :key="activeTab" @changeTab="(e) => manageDataTab = e" />
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="Export Data">
                            <ExportDataTable
                                :data="exportData"
                                @printFile="printFile"
                                @downloadFile="downloadFile"
                            />
                        </a-tab-pane>
						<a-tab-pane key="4" tab="Bulk Delete">
							<div v-if="checking && stats.processing && stats.progress < 100">
				                <p class="mt-3">Bulk Delete Leads</p>
				                <a-progress :percent="stats.progress" :show-text="true" />
				            </div>
				            <div v-else-if="checking">
				                <h3>Checking processing...</h3>
				            </div>
				            <div v-else>
								<p class="mt-3 text-center">Select which leads you would like to permanently delete from the lead list.</p>
								<div class="dF aC jC" style="gap:30px">
									<a-button type="primary" @click="bulkDelete('unsubscribed')">
										Unsubscribed
									</a-button>
									<a-button type="primary" @click="visible = true">
										Bounced
									</a-button>
								</div>
							</div>
						</a-tab-pane>
                        <a-tab-pane key="3" tab="History">
                            <History :data="historyData" :totalCount="historyTotal" @changeHistoryPage="changeHistoryPage" @updateSortQuery="updateHistorySort" />
                        </a-tab-pane>
					</a-tabs>
                </a-card>
            </div>
            <div v-else-if="activeTab === 4" slot="content">
                <a-card>
                    <TagsTab />
                </a-card>
            </div>
            <div v-else-if="activeTab === 5" slot="content">
                <a-card>
                    <OpportunitiesTab
                        :sources="sourcesList"
                        :reasons="reasonsList"
                    />
                </a-card>
            </div>
            <div v-else-if="activeTab === 6" slot="content">
                <a-card>
                    <TaskTab />
                </a-card>
            </div>
            <div v-else-if="activeTab === 8" slot="content">
                <a-card>
                    <MeetingsTab />
                </a-card>
            </div>
        </SettingsTab>
		<a-modal v-model="visible" title="Bulk Delete Bounced" okText="Delete" okType="danger" @ok="bouncedBulkDelete">
			<a-form-model
                ref="bounced"
                :model="bounced"
            >
                <a-row :gutter="16">
                    <a-col :span="24">
						<a-form-model-item
                            label="Bounced Reasons"
                            required
                            :rules="req('Please select the bounce reasons you want to delete')"
                            prop="reasons"
                        >
                            <a-select
								mode="multiple"
                                v-model="bounced.reasons"
								size="large"
                            >
                                <a-select-option
                                    v-for="(reason, index) in bouncedReasons"
                                    :key="reason.value + index"
                                    :value="reason.value"
                                    >{{ reason.name }}</a-select-option
                                >
                            </a-select>
                        </a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
		</a-modal>
    </div>
</template>

<script>
import SettingsTab from "@/components/contacts/SettingsTab";
import TagsTab from "@/components/contacts/TagsTab";
import ExportDataTable from "@/components/contacts/ExportDataTable";
import History from "@/components/contacts/History";
import DetailsTab from "@/components/contacts/DetailsTab";
import OpportunitiesTab from "@/components/contacts/OpportunitiesTab";
import AccountTab from "@/components/contacts/AccountTab";
import AccountPreview from "@/components/contacts/AccountPreview";
import TemplateTab from "@/components/contacts/TemplateTab";
import SocialTab from "@/components/contacts/SocialTab";
import EmailTemplate from "@/components/contacts/EmailTemplate";
import ImportData from "@/components/contacts/ImportData";
import AddFile from "@/components/contacts/AddFile";
import TaskTab from "@/components/contacts/TaskTab";
import MeetingsTab from "@/components/contacts/MeetingsTab";
import IntegrationTab from "@/components/contacts/IntegrationTab";
import BHLoading from 'bh-mod/components/common/Loading'

export default {
    components: {
        SettingsTab,
        TagsTab,
        ExportDataTable,
        DetailsTab,
        OpportunitiesTab,
        AccountTab,
        AccountPreview,
        TemplateTab,
        SocialTab,
        EmailTemplate,
        ImportData,
        AddFile,
		TaskTab,
        MeetingsTab,
        IntegrationTab,
        History,
		BHLoading
    },
    data() {
        return {
            historyData:[],
			historyTotal:0,
			historySort:'',
			historyPage:1,
            showTagModal: false,
            newTag: {
                name: "",
                description: "",
            },
            activeTab: 0,
			manageDataTab: "1",
            selectedTemplate: "",
			loading:false,
			unsubscribedCount: 0,
			bouncedCount: 0,
			visible: false,
			bounced: {
				reasons:[]
			},
			bouncedReasons: [
				{value: 'all', name: 'All'},
				{value: 'suppress-complaint', name: 'User marked previous message as spam.'},
				{value: 'espblock', name: 'Service provider blocked the message.'},
				{value: 'generic', name: 'Mailbox is not configured correctly.'},
				{value: 'old', name: 'Mailbox is inactive.'},
				{value: 'suppress-bounce', name: 'Recipient email previously bounced.'},
				{value: 'bounce', name: 'Domain name does not exist.'},
				{value: 'blacklisted', name: 'Recipient domain has been blacklisted.'},
			],
			checking:true,
            deleteIntervalTime:null,
            deleteProgress:null,
        };
    },
    watch: {
		checking:{
            immediate:true,
            handler(val){
				clearInterval(this.deleteIntervalTime)
                if (val){
                    this.deleteProgressCheckInterval()
                } else {
                    this.deleteProgress = null
                }
            }
        }
	},
    computed: {
        importLead() {
            return this.$store.state.contacts.importLead;
        },
        exportData() {
            return [
                { name: "Contacts", id: 1 },
                // {name:'Opportunities', id:2}
            ];
        },
        sourcesList() {
            return [
                { name: "Email", id: 1 },
                { name: "Cold Call", id: 2 },
                { name: "Advertising", id: 3 },
            ];
        },
        reasonsList() {
            return [
                { name: "Price", id: 1 },
                { name: "Competition", id: 2 },
            ];
        },
        sidebarSelection() {
            return this.$store.state.sidebarSelection;
        },
        instance() {
            return this.$store.state.instance;
        },
        vendors() {
            return this.$store.state.contacts.vendors || {};
        },
		stats(){
            let stats = this.deleteProgress;

            if (!stats || typeof stats !== 'string' || stats.split(':').length !== 2 || isNaN(stats.split(':')[0]) || isNaN(stats.split(':')[1])){
                return {
                    processing:false
                }
            }

            let [count,total] = stats.split(':');
            count = parseInt(count);
            total = parseInt(total);
            return {
                processing:true,
                count,total,
                progress:Math.round((count / total) * 100)
            }
        },

		instances() {
			return this.$store.state.instances;
		},
    },
    methods: {
        req: (msg) => ({ required: true, message: msg }),
		updateHistorySort(query){
			console.log('Updating history sort', query);
			this.historySort = '&' + query;
			this.$store.commit("LOAD", true);
			this.$api.get(`/contact-import-export-logs/:instance?_limit=10&_start=${(this.historyPage-1)*10}${this.historySort}`).then(({data}) => {
				console.log('data', data)
				this.historyData = data.data
				this.historyTotal = data.totalCount
				this.$store.commit("LOAD", false);
			}).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
		},
		changeHistoryPage(page){
			console.log('Changing History Page', page)
			this.historyPage = page;
			this.$store.commit("LOAD", true);
			this.$api.get(`/contact-import-export-logs/:instance?_limit=10&_start=${(this.historyPage-1)*10}${this.historySort}`).then(({data}) => {
				console.log('data', data)
				this.historyData = data.data
				this.historyTotal = data.totalCount
				this.$store.commit("LOAD", false);
			}).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
		},
		cancel() {
            // this.account = JSON.parse(JSON.stringify(this.$store.state.contacts.allSettings.userApp.options.account))
            if (this.$store.state.contacts.allSettings.userApp && this.$store.state.contacts.allSettings.userApp.options && this.$store.state.contacts.allSettings.userApp.options.account) {
                let account = this.$store.state.contacts.allSettings.userApp.options.account
				let currentAccount = this.$store.state.contacts.previewAccount
                Object.keys(currentAccount).forEach(key => {
                    if (account[key]) {
                        currentAccount[key] = account[key]
                    } else {
                        currentAccount[key] = ''
                    }
                })
            }
        },
		saveTemplate() {
            console.log('save template')
			let account = JSON.parse(
                JSON.stringify(this.$store.state.contacts.previewAccount)
            );
            if (!account.name || account.name == '' || !account.email || account.email == '') {
                return this.$message.error('Please enter the name and email!')
            }
            let settings = JSON.parse(JSON.stringify(this.$store.state.contacts.allSettings.userApp))
            settings.options.account = account
            this.loading = true
            this.$api.post('/settings/:instance/contacts/user', settings).then(({data}) => {
                console.log('DATAA COMING BACK', data)
                this.loading = false
                this.$notification['success']({
                    message: 'Email Signature Changed',
                    description: 'Your email signature has been updated successfully.',
                    duration: 4
                });
                this.$store.commit('UPDATE_USER_ALLSETTINGS',data)
            }).catch(err => {
				this.loading = false
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        changeTab(e) {
			console.log('changeTab', e)
            if (e == 3) {
                this.$store.commit("LOAD", true);
                this.$api.get(`/contact-import-export-logs/:instance?_limit=10&_start=0`).then(({data}) => {
                    console.log('data', data)
                    this.historyData = data.data
					this.historyTotal = data.totalCount
					this.historyPage = 1
					this.historySort = ''
                    this.$store.commit("LOAD", false);
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            }
			if (e == 8){
				this.activeTab = e
			}
        },
        updateLogo(image) {
            let account = JSON.parse(
                JSON.stringify(this.$store.state.contacts.previewAccount)
            );
            if (image.url && image.url != "") {
                account.logo = image.url;
                this.$store.commit("PREVIEW_ACCOUNT", account);
            } else {
                account.logo = "";
                this.$store.commit("PREVIEW_ACCOUNT", account);
            }
        },
        updateImage(image) {
            let account = JSON.parse(
                JSON.stringify(this.$store.state.contacts.previewAccount)
            );
            if (image.url && image.url != "") {
                account.avatar = image.url;
                this.$store.commit("PREVIEW_ACCOUNT", account);
            } else {
                account.avatar = "";
                this.$store.commit("PREVIEW_ACCOUNT", account);
            }
        },
        connectOutlook() {
            console.log("connect outlook");
        },
        printFile(item) {
            console.log("printFile", item);
        },
        downloadFile(item) {
            if (this.$p < 40) {
                return this.$message.error(
                    "You do not have permission for the export feature"
                );
            }
            if (item.name === "Contacts") {
                this.$store.commit("LOAD", true);
                let suffix = "dev";
                if (
                    location.host.includes("bildhive.dev") ||
                    window.location.host.includes("localhost")
                ) {
                    suffix = "dev";
                } else {
                    suffix = "com";
                }

                fetch(
                    `https://hook.bildhive.${suffix}/contacts/${this.instance.id}/export`,
                    {
                        headers: {
                            "Content-Type": "text/csv",
                            Authorization: `Bearer ${this.getCookie("__bhjt")}`,
							"x-subdomain": "contacts"
                        },
                        responseType: "blob",
                    }
                )
                    .then((response) => {
                        return response.blob();
                    })
                    .then((blob) => {
                        var a = window.document.createElement("a");
                        a.href = window.URL.createObjectURL(blob, {
                            type: "text/csv",
                        });
                        a.download = `${this.instance.name}-${Date.now()}.csv`;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        return this.$notification['success']({
                            message: 'Export Complete',
                            description: `Your contacts have been exported successfully.\n${new Date()}`,
                            duration: 4
                        });
                    })
                    .catch((err) => {
                        console.error("err", err);
                        this.$message.error(
                            "An error occurred please try again"
                        );
                    })
                    .finally(() => {
                        this.$store.commit("LOAD", false);
                    });
            } else if (item.name === "Opportunities") {
                this.$message.error("This feature is not live yet.");
            }
        },
        req: (msg) => ({ required: true, message: msg }),
        showMenu(item) {
            this.activeTab = item;
        },
        getCookie(cname) {
            const name = cname + "=";
            const decodedCookie = decodeURIComponent(document.cookie);
            const ca = decodedCookie.split(";");
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == " ") {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },

		// onReasonChange(value) {
		// 	if(value.includes('all') && (value.length > 1 || value[0] !== 'all')){
		// 		this.bounced.reasons = ['all'];
		// 		this.$message.info("As you have selected 'All' it will deselect all the others automatically.")
		// 	}
		// },

		bouncedBulkDelete() {
			let self = this;
			this.$refs.bounced.validate((valid) => {
                    if (valid) {
						self.loading = true
						self.$api.post(`/contacts/:instance/bulk-delete/bounced`, {
							reasons: self.bounced.reasons.includes('all') ? [] : self.bounced.reasons
						}).then(() => {
							self.checking = true;
							self.loading = false
							self.$message.success(`Bounced lead with selected reasons deleted successfully.`)
							self.bounced.reasons = [];
							self.visible = false;
						}).catch((error) => {
							self.loading = false
							console.error(`Error while deleting bounced lead. Please try again.`, error)
							self.$message.error(`Error while deleting bounced lead. Please try again.`)
						})
					}
			})
		},

		bulkDelete(type = 'unsubscribed') {
			if (this.$p < 40){
                return this.$message.error('You do not have permission to delete Leads')
            }
            let self = this;
			const count = this[type+'Count']
			const leadType = type.charAt(0).toUpperCase() + type.slice(1);

			if(count){
				this.$confirm({
					title: `Delete ${count} ${leadType} Lead(s)`,
					content: h => <div class="mt-3">Do you want to delete all the {leadType} Lead(s)?</div>,
					okText: 'DELETE',
					okType: 'danger',
					cancelText: 'CANCEL',
					centered: true,
					onOk(){
						self.loading = true
						self.$api.post(`/contacts/:instance/bulk-delete/${type}`).then(() => {
							self.checking = true;
							self.loading = false
							self.$message.success(`All the ${leadType} lead deleted successfully.`)
						}).catch((error) => {
							self.loading = false
							console.error(`Error while deleting ${leadType} lead. Please try again.`, error)
							self.$message.error(`Error while deleting ${leadType} lead. Please try again.`)
						})
					}
				})
			} else {
				this.$info({
					content: h => <h5>Currently there are no {leadType} Lead(s).</h5>,
					centered: true,
				})
			}

		},

		bulkDeleteProgress(type){
            return new Promise((resolve) => {
                this.$api.get(`/contacts/:instance/bulk-delete-progress/${type}`)
                    .then( ({data}) => {
                        if ([1, -1].includes(data)) {
                            this.deleteProgress = null;
                            return resolve(false)
                        }
                        if (typeof data !== 'string' || data.split(':').length !== 2 || isNaN(data.split(':')[0]) || isNaN(data.split(':')[1])){
                            return resolve(false)
                        }
                        this.deleteProgress = data;
                        return resolve(true)
                    })
                    .catch( () => {
                        resolve(false)
                    })
            })
        },

		async deleteProgressCheckInterval(type = 'bounced'){
            let result = await this.bulkDeleteProgress(type)
            if (!result) {
				this.$store.dispatch('FETCH_INSTANCE_DETAILS')
                this.checking = false;
            }
            this.deleteIntervalTime = setInterval( async () => {
                result = await this.bulkDeleteProgress(type)
                if (!result){
					this.$store.dispatch('FETCH_INSTANCE_DETAILS')
                    this.checking = false;
                }
            }, 2000);
        },
    },

    created() {
        if (this.importLead) this.activeTab = 3;
        this.$store.commit("SIDEBAR_SELECTION", { value: "Settings" });

		if (this.$route.query["leads"]) {
			this.activeTab = 4;
		}

		if (this.$route.query["template"]) {
			this.activeTab = 2;
		}

		this.$api.get(`/contacts/:instance/filter-count?unsub=1`).then(({data}) => {
			this.unsubscribedCount = data || 0
		}).catch(err => {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				this.$message.error(this.$err(err))
			}
		})

		this.$api.get(`/contacts/:instance/filter-count?bounced=1`).then(({data}) => {
			this.bouncedCount = data || 0
		}).catch(err => {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				this.$message.error(this.$err(err))
			}
		})

		if (!this.instances || !this.instances.length) {
			this.$store.dispatch('FETCH_INSTANCES');
		}
    },
	beforeDestroy() {
        this.deleteProgress = null
		clearInterval(this.deleteIntervalTime)
	},
    destroyed() {
        this.activeTab = 0;
        this.$store.commit("DELETE_IMPORT_LEAD");
    },
};
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss" scoped>
.email-signature {
    @media screen and (min-width: 1700px) {
        display: flex;
    }
}
.contacts-page {
    @media screen and (min-width: 567px) {
        margin-top: 5%;
    }
}
.aB {
    align-items: baseline;
}
</style>

<style lang="scss">
.test .ant-form-item {
    margin-bottom: 5px;
}

.opportunitiesTab .ant-tabs-nav {
    font-size: 16px;
    .ant-tabs-tab {
        padding-left: 25px;
        padding-right: 25px;
    }
}

.opportunitiesTab .ant-tabs-bar {
    margin: 0 0 25px 0;
}
.opportunitiesTab .ant-tabs-top-bar {
    border-bottom: none !important;
}
</style>
